import { toast } from 'react-toastify';
type toastType = 'success' | 'error' | 'info' | 'warn';

export const customToast = (type: toastType, message: string) => {
  switch (type) {
    case 'success':
      toast.success(message,{
        // icon:<DoneIcon/>
      });
      break;
    case 'error':
      toast.error(message);
      break;
    case 'warn':
      toast.warn(message);
      break;
    default:
      toast.info(message);
      break;
  }
};
