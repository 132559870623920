import { QueryClient } from '@tanstack/react-query';
import { QueryCache } from '@tanstack/react-query';
import { customToast } from '../../utils/customToast';

//모든 useQuery, useMutation 에 다음과 같은 옵션이 적용된다.
const queryClient = new QueryClient({
  //useQuery 사용할 때 에러 발생시 동작하는것
  queryCache: new QueryCache({
    onError: (error) => {},
  }),
  defaultOptions: {
    queries: {
      retry: 1,
      suspense: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },

    mutations: {},
  },
});

//특정 쿼리키에 대해 다음과 같이 설정될 수 있다.
queryClient.setQueryDefaults(['todos'], { retry: 5 }); //근데 todos안쓸거라 의미없음, 아마 keywords에 사용할듯?

export default queryClient;
