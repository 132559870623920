import { Box, Button, Typography, Stack, Tooltip, Chip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { useEffect, useMemo, useState } from 'react';
import BidTable from './bidTable/BidTable';
import GroupScheduler from './GroupScheduler';
import {
  useGetPowerGroupQuery,
  usePowerGroupBiddingActiveStateMutation,
} from '../../service/group/groupHooks';
import empty_folder from '../../assets/image/empty_folder.png';
import { customToast } from '../../utils/customToast';
import {
  useGetKeywordListByGroupId,
  useSyncKeywordListByGroupId,
} from '../../service/keyword/keywordHooks';
import LoadingDialog from '../loading/LoadingDialog';
import GroupSetting from './campainAndGroupList/GroupSetting';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from '../../service/queryKey';
import { useLocation } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { getStatusReason } from '../../assets/text/errorMassage';
import SyncIcon from '@mui/icons-material/Sync';
import TuneIcon from '@mui/icons-material/Tune';

//그룹입찰 테이블 최대 넓이
const TABLE_MAX_WIDTH = '1400px';
const TABLE_MIN_WIDTH = '1000px';

const BidDashBoard: React.FC = () => {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  const groupId = useMemo(() => {
    return pathname.split('/')[2];
  }, [pathname]);
  const { data: groupInfo } = useGetPowerGroupQuery(groupId);
  const [open, setOpen] = useState<boolean>(false);
  //그룹 입찰 시작 정지
  const { mutateAsync: updateGroupActive } =
    usePowerGroupBiddingActiveStateMutation();
  //키워드 리스트
  const { data: keywordList, isLoading: isLoading2 } =
    useGetKeywordListByGroupId(groupId);

  const { mutateAsync: syncKeywordList, isPending } =
    useSyncKeywordListByGroupId(groupId);

  //들어올때 최신데이터 받아오기
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.keywords] });
  }, [groupId]);

  const handleOpenChange = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.keywords] });
    setOpen(!open);
  };

  //그룹 입찰 시작
  const handleGroupBidStart = async () => {
    if (!!groupInfo && !!groupInfo.deviceCode) {
      await updateGroupActive({ grpId: groupId, isCheck: 'Y' });
    } else {
      customToast('error', '그룹설정이 필요합니다.');
    }
  };

  //그룹 입찰 중지
  const handleGroupBidStop = async () => {
    await updateGroupActive({ grpId: groupId, isCheck: 'N' });
  };

  //키워드 동기화 전 or 키워드가 없을때
  if (!!keywordList && keywordList.size === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack alignItems="center" gap={2}>
          <img
            width="100px"
            height="100px"
            style={{ opacity: 0.5 }}
            src={empty_folder}
            alt="빈 공간"
          />
          <Button
            variant="outlined"
            onClick={() => {
              syncKeywordList();
            }}
            startIcon={<SyncIcon />}
            sx={{
              border: '1px solid grey',
              borderRadius: '20px',
              fontSize: '16px',
              color: 'grey',
            }}
          >
            키워드 동기화
          </Button>
        </Stack>
      </Box>
    );
  }

  //그룹정보 및 키워드 정보가 있을 때
  if (groupInfo) {
    return (
      <>
        <Box
          sx={{
            minWidth: TABLE_MIN_WIDTH,
            maxHeight: '100vh',
            width: '100%',
            overflowY: 'auto',
            margin: '0 auto',
          }}
        >
          {/* 그룹에러 표시 메시지  */}
          {groupInfo!.groupStatus !== 'ELIGIBLE' && (
            <Box
              sx={{
                width: '100%',
                px: 6,
              }}
            >
              <Stack
                direction="row"
                alignItems={'center'}
                gap={2}
                sx={{
                  maxWidth: TABLE_MAX_WIDTH,
                  minWidth: TABLE_MIN_WIDTH,
                  backgroundColor: 'rgba(255, 0, 0, 0.1)',
                  borderLeft: '20px solid red',
                  borderRadius: '0 0 20 20',
                  p: 1,
                  pl: 4,
                  margin: '0 auto',

                  mb: 2,
                }}
              >
                <ErrorOutlineIcon color="error" fontSize="large" />
                <Stack>
                  <Typography variant="subtitle1" color="error">
                    {groupInfo.groupStatus}
                  </Typography>
                  <Typography variant="body1" color="error">
                    {getStatusReason(groupInfo.groupStatusReason)}
                    (네이버 검색광고 시스템을 확인해주세요.)
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          )}
          <LoadingDialog open={isLoading2} />
          <Box
            sx={{
              p: 6,
              pt: 1,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflow: 'auto',
            }}
          >
            <Box sx={{ width: '100%', maxWidth: TABLE_MAX_WIDTH, my: 4 }}>
              {/* 캠페인 > 그룹 */}
              <Typography variant="subtitle2" color="primary" sx={{ mb: 4 }}>
                {groupInfo.campaignName} {'>'} {groupInfo.groupName}
              </Typography>

              <Stack
                direction="row"
                justifyContent={'space-between'}
                alignItems="center"
                sx={{ mb: 2, flexWrap: 'wrap' }}
              >
                {/* 기기, 그룹이름, 지역 */}
                <Box>
                  {groupInfo && (
                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                      <Chip
                        label={
                          groupInfo.deviceName ? groupInfo.deviceName : 'N/A'
                        }
                        sx={{ backgroundColor: 'white' }}
                        variant="outlined"
                      />
                      <Typography
                        id="tableTitle"
                        component="div"
                        sx={{ fontSize: '1.6rem', fontWeight: 'bold' }}
                      >
                        {groupInfo.groupName}
                      </Typography>
                      <Stack>
                        {/* {groupInfo.campaignName} */}
                        <Typography sx={{ color: 'grey' }}>
                          {groupInfo.region[0]} {groupInfo.region[1]}{' '}
                          {groupInfo.region[2]}{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                </Box>
                {/* 재생, 정지, 동기화 버튼 */}
                <Stack
                  sx={{ mb: 2 }}
                  direction={'row'}
                  gap={1}
                  justifyContent={'flex-end'}
                  alignItems="center"
                >
                  {/* 입찰 시간 설정  */}
                  <GroupScheduler
                    groupId={groupId}
                    schedule={groupInfo.schedule}
                  />
                  <Tooltip
                    title={'[입찰주기, 지역, 광고매체]를 설정할 수 있습니다.'}
                    arrow
                    placement="top"
                  >
                    <Button
                      color="primary"
                      sx={{ boxShadow: 0, backgroundColor: 'white' }}
                      variant="outlined"
                      startIcon={<TuneIcon />}
                      onClick={handleOpenChange}
                    >
                      그룹 설정
                    </Button>
                  </Tooltip>
                  <Button
                    color="primary"
                    sx={{ boxShadow: 0 }}
                    variant="contained"
                    disabled={groupInfo?.isBiddingActive === 'Y' ? true : false}
                    startIcon={<PlayArrowIcon />}
                    onClick={handleGroupBidStart}
                  >
                    그룹 입찰
                  </Button>
                  <Button
                    color="error"
                    sx={{ boxShadow: 0 }}
                    variant="contained"
                    disabled={groupInfo?.isBiddingActive === 'N' ? true : false}
                    startIcon={<StopIcon />}
                    onClick={handleGroupBidStop}
                  >
                    그룹 정지
                  </Button>
                </Stack>
              </Stack>
              {/* 키워드 표 */}
              <BidTable data={groupInfo} keywordList={keywordList} />

              {/* 그룹설정 팝업 */}
              <GroupSetting
                data={groupInfo}
                open={open}
                onClose={handleOpenChange}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  return null;
};

export default BidDashBoard;
