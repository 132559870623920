import axios from 'axios';
import { URL, customAxios } from '../../config/axios/axios-config';
import { UpdateScheduleType, updateKeywordStateByKeywordIdProps } from './type';

export const keywordAPI = {
  //그룹아이디로 키워드 정보 가져오기
  async getNaverkeywordsByGroupId(id: string, nccAdgroupId: string) {
    const API = '/naver/keywords';
    const params = {
      id,
      nccAdgroupId,
    };

    return await axios.get(API, {
      baseURL: URL,
      params,
      withCredentials: true,
    });
  },
  // 키워드 리스트 가져오기
  async getKeywordListByGroupId(groupId: string) {
    const API = `/power/keyword/${groupId}`;
    return await customAxios.get(API);
  },
  //키워드 리스트 네이버랑 동기화 하기
  async syncKeywordListByGroupId(groupId: string) {
    const API = `/power/keyword/sync`;
    const body = {
      grp_id: groupId,
    };
    return await customAxios.post(API, body);
  },
  //키워드 입찰 실행여부
  async updateKeywordActiveByKeywordId(keywordId: string, isCheck: string) {
    const API = `/power/keyword/bidding`;
    const body = {
      keyword_id: keywordId,
      is_check: isCheck,
    };
    return await customAxios.put(API, body);
  },
  //키워드 정보 수정
  async updateKeywordStateByKeywordId(
    data: updateKeywordStateByKeywordIdProps,
  ) {
    const API = '/power/keyword';
    const body = {
      keyword_id: data.keywordId,
      target_rank: data.target_ranking,
      max_bid_price: data.maximum_bid_price,
      add_subtract_price: data.adjustment_price,
      is_check: data.isBiddingActive,
      current_price: data.current_bid_price,
    };
    return await customAxios.put(API, body);
  },

  //키워드 세부시간 수정 및 적용
  async updateKeywordTimeByKeywordId(data: UpdateScheduleType) {
    const API = '/power/keyword/schedule';
    const body = {
      keyword_id: data.keywordId,
      schedule: data.schedule,
      schedule_yn: data.scheduleYN,
    };
    return await customAxios.put(API, body);
  },
  // 키워드 하나 값 가져오기
  async getKeywordByKeywordId(keywordId: string) {
    const API = `/power/keyword/spec/${keywordId}`;
    return await customAxios.get(API);
  },

  //키워드 그래프 mongo db에서 가져오기
  async getKeywordGraphByKeywordId(groupId: string, keywordId: string) {
    const API = `/stats/keyword`;
    const params = {
      grp_id: groupId,
      keyword_id: keywordId,
    };
    return await customAxios.get(API, { params });
  },

  async updateSelectedKeywordStateByKeywordId(keywordIdList:string[], isCheck:string){
    const API = '/power/keywords/bidding';
    const body = keywordIdList.map(item => ({keyword_id:item, is_check:isCheck}))
    return customAxios.put(API, body);
  },
};

