import { customAxios } from '../../config/axios/axios-config';
import { getCustomerListType, updateCustomerInfoType } from './type';

export const adminAPI = {
  //사용자 리스트 불러오기
  async getCustomerList(params: getCustomerListType) {
    return await customAxios.get('/admin/customer-list', { params });
  },
  //사용자 정보 변경
  async updateCustomerInfo(id: string, data: updateCustomerInfoType) {
    return await customAxios.put(`/admin/${id}`, data);
  },
  //사용자 정보 삭제
  async deleteCustomerInfo(id: string) {
    return await customAxios.delete(`/admin/${id}`);
  },
  async getSocketList(){
    return await customAxios.get('/admin/socket/user');
  }
};
