import Login from '../../pages/Login';
import Test from '../../test/Test';
import Layout from '../../pages/Layout';
import Mypage from '../../pages/Mypage';
import UserInfoEdit from '../../pages/UserInfoEdit';
import { createBrowserRouter } from 'react-router-dom';
import SignUp from '../../pages/SignUp';
import ErrorBoundary from '../../components/error/ErrorBoundary';
import MenuAndDashBoard from '../../pages/MenuAndDashBoard';
import Admin from '../../pages/Admin';
import Callback from '../../pages/Callback';
import BidDashBoard from '../../components/bidDashBoard/BidDashBoard';

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/callback',
    element: <Callback />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/signup',
    element: <SignUp />,
    errorElement: <ErrorBoundary />,
  },
  // top and side navigation wrapper
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: 'user-info-edit',
        element: <UserInfoEdit />,
      },
      {
        path: 'mypage',
        element: <Mypage />,
      },
      {
        path: 'power-link',
        element: <MenuAndDashBoard />,
        children: [
          {
            path: ':groupId',
            element: <BidDashBoard />,
          },
        ],
      },
      {
        path: 'power-content',
        element: <MenuAndDashBoard />,
      },
      {
        path: 'test',
        element: <Test />,
      },
      {
        path: 'admin',
        element: <Admin />,
      },
    ],
  },

  {
    path: 'test',
    element: <Test />,
  },
]);
