import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { groupAPI } from './groupAxios';
import { QUERY_KEY } from '../queryKey';
import { customToast } from '../../utils/customToast';
import { GroupListType, GroupType, ScheduleType } from './type';
import { UserInfoState } from '../../store';
import { useRecoilValue } from 'recoil';

//(new)캠페인 아이디로 그룹리스트 불러오기
export const useGetPowerGroupListQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEY.groups, 'list'],
    queryFn: async () => await groupAPI.getPowerGroupList(),
    // enabled: true, // false:처음부터 데이터 안가져옴 , true:default
    select: (data) => {
      // console.log({groupList:data.data})
      let updateTime = '';
      const groupList: { [key: string]: Map<string, GroupType> } = {}; //

      data.data.forEach((item: any) => {
        updateTime = item.GROUP_SYNC_TIME;
        const data = {
          campaignName: item.CMP_NAME,
          campaignId: item.CMP_ID,
          groupName: item.GRP_NAME, //그룹이름
          groupId: item.GRP_ID, //그룹아이디
          isBiddingActive: item.IS_CHECK, //실행여부
          deviceCode: item.DEVICE ? item.DEVICE : '', //기기
          deviceName: item.DEVICE_NM ? item.DEVICE_NM : '', //기기
          groupStatus: item.GROUP_STATUS, // 이게 eligible이여야 네이버에서 입찰을 봐줌
          groupStatusReason: item.GROUP_STATUS_REASON, // eligible이 아닐때 사유
          intervals: item.INTERVALS, // 주기
          region: [
            item.REGION1 ? item.REGION1 : '',
            item.REGION2 ? item.REGION2 : '',
            item.REGION3 ? item.REGION3 : '',
          ],
          recentTime: item.RECENT_TIME, //최근 동기화 시간
          groupSyncTime: item.GROUP_SYNC_TIME,
          keywordSyncTime: item.KEYWORD_SYNC_TIME,
          schedule: item.SCHEDULE,
        };

        if (!!groupList[item.CMP_NAME]) {
          groupList[item.CMP_NAME].set(item.GRP_ID, data);
        } else {
          groupList[item.CMP_NAME] = new Map<string, GroupType>();
          groupList[item.CMP_NAME].set(item.GRP_ID, data);
        }
      });
      const campaignList = Object.keys(groupList).sort(); // 이름순으로 정렬
      const result: GroupListType = { updateTime, campaignList, groupList };

      return result;
    },
    staleTime: 1000 * 60, // 1분
    retry: false,
  });
};

//그룹 하나 불러오기
export const useGetPowerGroupQuery = (groupId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.groups, groupId],
    queryFn: async () => await groupAPI.getPowerGroup(groupId),
    select: (data) => {
      const item = data.data;
      const result: GroupType = {
        campaignName: item.CMP_NAME,
        campaignId: item.CMP_ID,
        groupName: item.GRP_NAME, //그룹이름
        groupId: item.GRP_ID, //그룹아이디
        isBiddingActive: item.IS_CHECK, //실행여부
        deviceCode: item.DEVICE ? item.DEVICE : '', //기기
        deviceName: item.DEVICE_NM ? item.DEVICE_NM : '', //기기
        groupStatus: item.GROUP_STATUS, // 이게 eligible이여야 네이버에서 입찰을 봐줌
        groupStatusReason: item.GROUP_STATUS_REASON, // eligible이 아닐때 사유
        intervals: item.INTERVALS, // 주기
        region: [
          item.REGION1 ? item.REGION1 : '',
          item.REGION2 ? item.REGION2 : '',
          item.REGION3 ? item.REGION3 : '',
        ],
        recentTime: item.RECENT_TIME, //최근 동기화 시간
        groupSyncTime: item.GROUP_SYNC_TIME,
        keywordSyncTime: item.KEYWORD_SYNC_TIME,
        schedule: item.SCHEDULE,
      };
      return result;
    },
    retry: false,
  });
};

// 캠페인 그룹 리스트 동기화
export const useSyncPowerGroupListMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => groupAPI.syncPowerGroupList(),
    onSuccess: (data) => {
      // customToast('success', data.data.message);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.groups],
      });
    },
    onError: () => {
      customToast(
        'error',
        '네이버 api key를 먼저 등록해 주세요 잠시 후 다시 시도해 주세요.',
      );
    },
  });
};

// 그룹 입찰 상태 변경
export const usePowerGroupBiddingActiveStateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, { grpId: string; isCheck: string }>({
    mutationFn: ({ grpId, isCheck }) =>
      groupAPI.powerGroupBiddingActiveState(grpId, isCheck),
    onSuccess: (data) => {
      //입찰 상태 변경을 성공적으로 수행한다면 groupList의 상태도 변경되어야 이전값을 변경하기 위해 캐싱을 지워야함
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.keywords],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.groups],
      });
    },
    onError: (err: any) => {
      customToast('error', err.response.data.message);
    },
  });
};

//그룹 입창 세팅(순위, 가격 등) 상태 변경
export const useUpdatePowerGroupStateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<
    any,
    Error,
    {
      grpId: string;
      device: string;
      intervals: string;
      region1: string;
      region2: string;
      region3: string;
    }
  >({
    mutationFn: ({ grpId, device, intervals, region1, region2, region3 }) =>
      groupAPI.updatePowerGroupState(
        grpId,
        device,
        Number(intervals),
        region1,
        region2,
        region3,
      ),
    onSuccess: (data) => {
      // customToast('success', '그룹설정 변경 완료');
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.groups],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.keywords],
      });
    },
    onError: () => {
      customToast(
        'error',
        '그룹 설정 변경중 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
      );
    },
  });
};

// 그룹 스케쥴조정
export const useUpdateGroupSchedule = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, ScheduleType>({
    mutationFn: (data: ScheduleType) => groupAPI.updateSchedule(data),
    onSuccess: (data) => {
      customToast('success', '스케줄을 변경했습니다.');
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.groups],
      });
    },
    onError: () => {
      customToast(
        'error',
        '스케쥴 변경중 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
      );
    },
  });
};
