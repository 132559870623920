import {
  Box,
  Stack,
  Button,
  Typography,
  TextField,
  DialogActions,
  DialogContent,
  Checkbox,
  DialogTitle,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormLabel,
  Card,
} from '@mui/material';
import { ChangeEvent, useMemo } from 'react';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { verifyAPI } from '../service/verify/verifyAxios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { customToast } from '../utils/customToast';
import { useSignUpMutation } from '../service/user/userHooks';
import { agreementText } from '../assets/text/agreementText';
import { signUpDateType } from '../service/user/type';

const labelWidth = {
  width: '150px',
};

//인증번호 유효시간
const SignUp: React.FC = () => {
  const navigate = useNavigate();

  const [verificationNumber, setVerificationNumber] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPhoneNumberVerify, setIsPhoneNumberVerify] = useState(false);
  const [verifyForm, setVerifyForm] = useState(false);
  const [countdown, setCountdwon] = useState(300);
  const [personalInfoConsent, setPersonalInfoConsent] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isUserIdAvailable, setIsUserIdAvailable] = useState(false);
  const [userInfo, setUserInfo] = useState<signUpDateType>({
    id: '',
    password: '',
    name: '',
    phoneNumber: '',
    businessNumber: '',
    email: '',
  });
  const { mutate: signUp } = useSignUpMutation(userInfo);

  const { id, password, name, phoneNumber, businessNumber, email } = useMemo(
    () => userInfo,
    [userInfo],
  );

  //input값 올바른지 확인하는 정규식 모음
  const handleCheckValidation = {
    nameFormat() {
      return /^[^\d!@#$%^&*(),.?":{}|<>0-9]*$/.test(name);
    },
    passwordIncludeAlpha() {
      return /[a-zA-Z]/.test(password);
    },
    passwordIncludeNumber() {
      return /\d/.test(password);
    },
    passwordIncludeSpecialCharacter() {
      return /[!@#$%^*+=-]/.test(password);
    },
    passwordSatisfiedLength() {
      return password.length >= 8 && password.length <= 20;
    },
    passwordEqualConfirm() {
      return password === confirmPassword;
    },
    emailFormat() {
      return /\S+@\S+\.\S+/.test(email);
    },
    businessNumberFormat() {
      return /^\d{3}-\d{2}-\d{5}$/.test(businessNumber);
    },
    idFormat() {
      return /^[a-zA-Z0-9\-_]{6,12}$/.test(id);
    },
    passowordFormat() {
      return /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/.test(
        password,
      );
    },
  };

  const handleUserInfoInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (id === 'id') setIsUserIdAvailable(false);
    if (id === 'phoneNumber') setIsPhoneNumberVerify(false);

    setUserInfo((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  };

  //비밀번호 보기
  const handleShowPassword = () => {
    setShowPassword((pre) => !pre);
  };

  //개인정보 수집 동의 입력
  const handlePersonalInfoConsentInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPersonalInfoConsent(event.target.checked);
  };

  //개인정보 수집 동의 입력
  const handleTermsAcceptedInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTermsAccepted(event.target.checked);
  };

  //인증번호 입력
  const handleVerificationNumberInput = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setVerificationNumber(event.target.value);
  };

  //비밀번호 확인 입력
  const handleConfirmPasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  //로그인 페이지로 이동
  const handleBack = () => {
    navigate('/login');
  };

  const handleCheckUserIdAvailable = () => {
    verifyAPI
      .checkUserIdAvailable(id)
      .then(() => {
        customToast('success', '사용가능한 아이디 입니다.');
        setIsUserIdAvailable(true);
      })
      .catch(() => {
        customToast(
          'error',
          '중복된 아이디 입니다. 다른아이디를 입력해 주세요.',
        );
        setIsUserIdAvailable(false);
      });
  };

  //countdown을 통해 남은시간 보여주기
  const showTimer = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    return `${minutes}분 ${seconds}초`;
  };

  //5분 타이머
  const startTimer = () => {
    let timerInterval: any;
    clearInterval(timerInterval);
    timerInterval = setInterval(() => {
      setCountdwon((prev) => {
        if (prev <= 0) {
          clearInterval(timerInterval);
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
  };

  //인증번호 전송 로직
  const handleSendVerificationNumber = () => {
    if (phoneNumber.length !== 11) {
      customToast('error', '잘못된 전화번호입니다. 다시 시도해주세요');
    } else {
      verifyAPI
        .SendVerificationNumber(phoneNumber)
        .then((data) => {
          if (data.data === '중복') {
            customToast('warn', '중복된 사용자입니다. 잠시만 기다려 주십시오');
          } else {
            customToast('success', '인증번호를 전송했습니다.');
            setVerifyForm(true); //form 보여주기
            setCountdwon(300); // 시간 초기화
            startTimer();
            setVerificationNumber('');
          }
        })
        .catch((err) => {
         // console.log(err.response.data);
          customToast(
            'error',
            '전화번호가 잘못되었습니다. 전화번호를 확인한 다음 다시 시도해주세요',
          );
        });
    }
  };

  //인증번호 확인로직
  const handleCheckVerificationNumber = () => {
    verifyAPI
      .checkVerificationNumber(phoneNumber, verificationNumber)
      .then(() => {
        customToast('success', '전화번호가 인증되었습니다.');
        setIsPhoneNumberVerify(true);
        setVerifyForm(false);
      })
      .catch(() => {
        customToast('success', '인증번호를 다시한번 확인해 주세요');
      });
  };

  //회원가입 등록 함수
  const handleFormSubmit = () => {
    if (!name || !handleCheckValidation.nameFormat()) {
      customToast('error', '이름을 확인해주세요.');
    } else if (!phoneNumber || !isPhoneNumberVerify) {
      customToast('error', '전화번호를 인증해주세요.');
    } else if (!id || !handleCheckValidation.idFormat()) {
      customToast('error', '아이디를 확인해주세요.');
    } else if (!isUserIdAvailable) {
      customToast('error', '아이디 중복을 확인해 주세요.');
    } else if (
      !businessNumber ||
      !handleCheckValidation.businessNumberFormat()
    ) {
      customToast('error', '사업자등록번호를 확인해주세요.');
    } else if (!email || !handleCheckValidation.emailFormat()) {
      customToast('error', "이메일을 확인해주세요'");
    } else if (!password || !handleCheckValidation.passowordFormat()) {
      customToast('error', '비밀번호를 확인해주세요');
    } else if (
      !confirmPassword ||
      !handleCheckValidation.passwordEqualConfirm()
    ) {
      customToast('error', '비밀번호확인을 확인해주세요');
    } else if (!termsAccepted || !personalInfoConsent) {
      customToast('error', '각 약관동의 항목에 동의해 주세요');
    } else {
      signUp();
    }
  };

  return (
    <Box sx={{ pt: 4 }}>
      <Stack
        direction="column"
        gap={2}
        alignItems={'center'}
        sx={{ width: '700px', margin: '0 auto' }}
      >
        {/* <Box sx={{ minWidth: '640px' }}> */}
        <Box sx={{ width: '100%' }}>
          <Typography variant="caption" color="primary">
            기본정보 및 기업정보를 모두 입력해 주세요.
          </Typography>
        </Box>
        {/* 기본정보 */}
        <Card
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="h5">기본정보</Typography>
          <Stack sx={{ p: 4 }} gap={2}>
            {/* 이름 */}
            <Box>
              <Stack direction={'row'} alignItems="center">
                <FormLabel htmlFor="name" sx={labelWidth}>
                  이름
                </FormLabel>

                <TextField
                  error={name ? !handleCheckValidation.nameFormat() : false}
                  value={name}
                  onChange={handleUserInfoInputChange}
                  id="name"
                  type="text"
                  placeholder={'홍길동'}
                  sx={{ width: '400px' }}
                />
              </Stack>
              {name && !handleCheckValidation.nameFormat() && (
                <Typography
                  sx={MlStyle}
                  color="error"
                  variant="subtitle2"
                >
                  특수문자,숫자는 이름에 사용할 수 없습니다.
                </Typography>
              )}
            </Box>

            {/* 전화번호 */}
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormLabel htmlFor="phoneNumber" sx={labelWidth}>
                  전화번호
                </FormLabel>

                <TextField
                  // fullWidth
                  type="text"
                  id="phoneNumber"
                  placeholder={'01012341234'}
                  sx={{ width: '242px' }}
                  value={phoneNumber}
                  onChange={handleUserInfoInputChange}
                />
                {isPhoneNumberVerify ? (
                  <Button
                    disabled
                    variant="outlined"
                    sx={{ height: '52px', ml: 1, width: '150px' }}
                  >
                    확인완료
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{ height: '52px', ml: 1, width: '150px' }}
                    onClick={handleSendVerificationNumber}
                  >
                    인증번호 전송
                  </Button>
                )}
              </Box>
              {isPhoneNumberVerify && (
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={MlStyle}
                >
                  전화번호가 인증되었습니다 ✔
                </Typography>
              )}
            </Box>
            {/* 인증번호 */}

            {verifyForm && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <FormLabel htmlFor="verificationNumber" sx={labelWidth}>
                    인증번호
                  </FormLabel>
                  <TextField
                    id="verificationNumber"
                    type="text"
                    placeholder={'인증번호 6자리 입력'}
                    sx={{ width: '295px' }}
                    value={verificationNumber}
                    onChange={handleVerificationNumberInput}
                  />

                  <Button
                    variant="outlined"
                    sx={{ height: '52px', ml: 1, width: '97px' }}
                    onClick={handleCheckVerificationNumber}
                  >
                    확인
                  </Button>
                </Box>
                <Typography
                  variant="caption"
                  color="error"
                  sx={MlStyle}
                >
                  {countdown !== 0
                    ? showTimer()
                    : '인증번호가 만료되었습니다. 인증번호를 다시 전송해주세요'}
                </Typography>
              </Box>
            )}
            {/* 아이디 */}
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormLabel htmlFor="id" sx={labelWidth}>
                  아이디
                </FormLabel>

                <TextField
                  id="id"
                  error={id ? !handleCheckValidation.idFormat() : false}
                  type="text"
                  sx={{ width: '295px' }}
                  value={id}
                  onChange={handleUserInfoInputChange}
                />
                {isUserIdAvailable ? (
                  <Button
                    disabled
                    variant="outlined"
                    sx={{ height: '52px', ml: 1, width: '97px' }}
                  >
                    확인완료
                  </Button>
                ) : (
                  <Button
                    disabled={!handleCheckValidation.idFormat()}
                    variant="outlined"
                    sx={{ height: '52px', ml: 1, width: '97px' }}
                    onClick={handleCheckUserIdAvailable}
                  >
                    중복체크
                  </Button>
                )}
              </Box>
              {id && !handleCheckValidation.idFormat() && (
                <Typography
                  sx={MlStyle}
                  color="error"
                  variant="subtitle2"
                >
                  아이디는 영문,숫자,특수문자(- _) 가포함된 6~12 자리
                  문자입니다.
                </Typography>
              )}
            </Box>
            {/* 비밀번호 */}
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormLabel htmlFor="password" sx={labelWidth}>
                  비밀번호
                </FormLabel>

                <OutlinedInput
                  id="password"
                  sx={{ width: '400px' }}
                  value={password}
                  onChange={handleUserInfoInputChange}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              {!!password && (
                <Stack direction={'row'} gap={2} sx={MlStyle}>
                  <Typography
                    variant="subtitle2"
                    color={
                      password
                        ? handleCheckValidation.passwordIncludeAlpha()
                          ? 'primary'
                          : 'error'
                        : 'grey'
                    }
                  >
                    영문 ✔
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={
                      password
                        ? handleCheckValidation.passwordIncludeNumber()
                          ? 'primary'
                          : 'error'
                        : 'grey'
                    }
                  >
                    숫자 ✔
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={
                      password
                        ? handleCheckValidation.passwordIncludeSpecialCharacter()
                          ? 'primary'
                          : 'error'
                        : 'grey'
                    }
                  >
                    특수문자 ✔
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={
                      password
                        ? handleCheckValidation.passwordSatisfiedLength()
                          ? 'primary'
                          : 'error'
                        : 'grey'
                    }
                  >
                    비밀번호 길이 8이상 20이하 ✔
                  </Typography>
                </Stack>
              )}
            </Box>
            {/* 비밀번호 확인 */}
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormLabel htmlFor="confirmPassword" sx={labelWidth}>
                  비밀번호 확인
                </FormLabel>

                <OutlinedInput
                  id="confirmPassword"
                  error={
                    confirmPassword
                      ? !handleCheckValidation.passwordEqualConfirm()
                      : false
                  }
                  sx={{ width: '400px' }}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordInput}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <Box sx={MlStyle}>
                {confirmPassword &&
                  !handleCheckValidation.passwordEqualConfirm() && (
                    <Typography variant="subtitle2" color="error">
                      비밀번호가 일치하지 않습니다.{' '}
                    </Typography>
                  )}
              </Box>
            </Box>
            {/* 유저정보가 있을 때는 보여지면 안됨  */}
          </Stack>
        </Card>
        {/* 기업정보 */}
        <Card
          sx={{
            width: '100%',
            minWidth: '640px',
          }}
        >
          <Typography variant="h5">기업정보</Typography>
          <Stack sx={{ p: 4 }} gap={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormLabel htmlFor="businessNumber" sx={labelWidth}>
                사업자 등록 번호
              </FormLabel>
              <TextField
                id="businessNumber"
                placeholder="000-00-00000"
                error={
                  businessNumber
                    ? !handleCheckValidation.businessNumberFormat()
                    : false
                }
                type="text"
                sx={{ width: '400px' }}
                value={businessNumber}
                onChange={handleUserInfoInputChange}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormLabel htmlFor="email" sx={labelWidth}>
                이메일
              </FormLabel>
              <TextField
                id="email"
                error={email ? !handleCheckValidation.emailFormat() : false}
                placeholder="example@nexample.com"
                value={email}
                onChange={handleUserInfoInputChange}
                type="email"
                sx={{ width: '400px' }}
              />
            </Box>
          </Stack>
        </Card>
        {/* 약관동의 페이지 */}
        <Card
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="h5">약관동의 </Typography>
          <Stack sx={{ p: 2, width: '615px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  onChange={handlePersonalInfoConsentInput}
                  value={personalInfoConsent}
                />
                <Typography color="grey" sx={{ ml: 1 }}>
                  개인정보 수집/이용 동의
                </Typography>
              </Box>
              <AgreementDialog
                title={agreementText[0].title}
                content={agreementText[0].content}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  onChange={handleTermsAcceptedInput}
                  value={termsAccepted}
                />
                <Typography color="grey" sx={{ ml: 1 }}>
                  이용약관
                </Typography>
              </Box>
              <AgreementDialog
                title={agreementText[1].title}
                content={agreementText[1].content}
              />
            </Box>
          </Stack>
        </Card>

        {/* 등록 취소 버튼 */}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            mb: '200px',
          }}
          gap={1}
        >
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleBack}
            sx={{ width: '110px', backgroundColor: 'background.paper' }}
          >
            뒤로가기
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ width: '110px' }}
            onClick={handleFormSubmit}
          >
            등록
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default SignUp;

interface AgreementDialogProps {
  title: string;
  content: string;
}

const AgreementDialog: React.FC<AgreementDialogProps> = ({
  title,
  content,
}) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <>
      <Button onClick={handleClickOpen('paper')} sx={{ color: 'grey' }}>
        보기
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">약관동의</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <Typography variant="h6">{title}</Typography>
          <pre style={{ fontSize: '12px', whiteSpace: 'pre-wrap' }}>
            {content}
          </pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MlStyle = {
  ml:"150px"
}
