import { Box, Button, Tooltip, Typography } from '@mui/material';
import CampainItem from './CampaignItem';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  useGetPowerGroupListQuery,
  useSyncPowerGroupListMutation,
} from '../../../service/group/groupHooks';
import { useEffect } from 'react';
import LoadingDialog from '../../loading/LoadingDialog';

export type groupType = {
  group_name: string;
  is_active: boolean;
  state: 'mobile' | 'pc' | 'none';
};

const CampaignAndGroupList: React.FC = () => {
  const { data, isLoading } = useGetPowerGroupListQuery(); // DB에서 캠페인 그룹 가져오기
  const { mutate: ayncPowerCampainAndGroupList, isPending } =
    useSyncPowerGroupListMutation(); //DB에 캠페인그룹 네이버랑 싱크 맞추기

  // useEffect(() => {
  //   const polling = setInterval(refetch, 1000 * 60);
  //   return ()=>{
  //     clearInterval(polling);
  //   }
  // }, []);


  return (
    <Box component="div" sx={{ p: 2, height: '100vh', overflow: 'auto' }}>
      {/* <LoadingDialog open={isPending || isLoading} /> */}
      {/* 동기화 버튼 */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip title={"검색광고 시스템의 캠페인 및 그룹 동기화"} arrow placement='top'>

        <Button
          startIcon={<AutorenewIcon />}
          variant="outlined"
          fullWidth
          sx={{ mb: 1 }}
          onClick={() => {
            ayncPowerCampainAndGroupList();
          }}
          >
          {' '}
          캠페인 & 그룹 동기화{' '}
        </Button>
          </Tooltip>
      </Box>

      <Typography
        variant="caption"
        textAlign={'right'}
        display={'block'}
        sx={{ mb: 4 }}
      >
        최근 업데이트 : {data?.updateTime ? data?.updateTime : '[없음]'}
      </Typography>

      {/* 캠페인 그룹 리스트 */}
      {data?.campaignList &&
        data.campaignList.map((gampaignName: string, i: number) => (
          <CampainItem
            groupList={data.groupList[gampaignName]}
            key={i}
            campainTitle={gampaignName}
          />
        ))}
    </Box>
  );
};

export default CampaignAndGroupList;
