import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Box,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { ShowColumnState } from '../../../store';
import {  Order, bidData, headCells } from './BidTable';
import { visuallyHidden } from '@mui/utils';

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof bidData,
  ) => void; //정렬

  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void; //전체 선택 함수
  order: Order;
  orderBy: string;
  rowCount: number;
}

// 해더 컴포넌트(header) -------------------------------------------------------------------------------------
function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const showColumn = useRecoilValue<boolean[]>(ShowColumnState);

  //어떤 걸 sort할지 결정하는 함수
  const createSortHandler =
    (property: keyof bidData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    // 배경 색 변경
    <TableHead sx={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }}>
      <TableRow>
        {true && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount} //하나라도 체크됐다면 체크함수
              onChange={onSelectAllClick} //모든 키워드 선택
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}

        {/* 체크박스 이외의 컬럼 나열 */}
        {headCells.map((headCell, i) => {
          if (showColumn[i]) {
            return (
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  ...(headCell.id === "keyword" && {pl:1 }),
                }}
                key={headCell.id}
                align={headCell.numeric ? 'center' : 'left'} //좌우 정렬
                padding={headCell.disablePadding ? 'none' : 'normal'} // padding을 없애 간편보기 실행
                sortDirection={orderBy === headCell.id ? order : false} //정렬됐는지 , 됐다면 방향은 어디인지
              >
                {/* 어떤 값이 어떤 상태로 정렬중인지 라벨로 판단함  */}
                {headCell.label === '설정' || orderBy !== headCell.id ? (
                  <Box
                    onClick={createSortHandler(headCell.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {headCell.label}
                  </Box>
                ) : (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                    {headCell.label}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          } else {
            return null;
          }
        })}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
