import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  OutlinedInput,
} from '@mui/material';
import { useState, useEffect, ChangeEvent } from 'react';
import { useUpdateKeywordSchedule } from '../../../service/keyword/keywordHooks';

interface BidTimeDetailSettingProps {
  keywordId: string;
  schedule: string | null;
  scheduleYN: 'Y' | 'N';
}

const BidTimeDetailSetting: React.FC<BidTimeDetailSettingProps> = ({
  schedule,
  scheduleYN,
  keywordId,
}) => {
  const [tempScheduleYN, setTempScheduleTN] = useState<'Y' | 'N'>(scheduleYN);
  const [tempSchedule, setTempSchedule] = useState<string[][]>(
    Array(24).fill(['0', '0', '0']),
  );

  const { mutateAsync } = useUpdateKeywordSchedule();
  useEffect(() => {
    if (!!schedule) {
      const segment = schedule.split('*');
      const reuslt: string[][] = [];
      segment.forEach((item, i) => {
        const temp = item.split('-');
        reuslt.push(
          temp.map((item) => {
            if (item === 'blank' || !item) {
              return '0';
            } else {
              return item;
            }
          }),
        );
      });

      setTempSchedule(reuslt);
    }
  }, [schedule]);

  // 스케쥴을 다시 string으로 변경하는 함수
  const scheduleString = () => {
    return tempSchedule
      .map((item) =>
        item
          .map((item) => {
            if (item === '0') {
              return 'blank';
            } else {
              return item;
            }
          })
          .join('-'),
      )
      .map((item) => item)
      .join('*');
  };

  // 제출
  const handleSubmit = async () => {
    await mutateAsync({
      keywordId: keywordId,
      schedule: scheduleString(),
      scheduleYN: tempScheduleYN === 'Y' ? 'N' : 'Y',
    });
  };

  const handleChangeSchedule = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const [row, col] = id.split(',').map((item) => parseInt(item));

    // 입력값이 숫자인지 확인
    if (!isNaN(Number(value))) {
      setTempSchedule((pre) => {
        const temp = [...pre]; // 외부 배열을 복사하여 새로운 배열 생성
        temp[row] = [...temp[row]]; // 내부 배열을 복사하여 새로운 배열 생성
        temp[row][col] = value; // 새로운 값을 할당하여 상태 업데이트
        return temp; // 새로운 배열 반환
      });
    }
  };

  const handleScheduleYN = async () => {
    try {
      await handleSubmit();
      setTempScheduleTN((pre) => (pre === 'Y' ? 'N' : 'Y'));
    } catch {}
  };

  return (
    <Box sx={{ mt: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControlLabel
          control={
            <Switch
              checked={tempScheduleYN === 'Y'}
              onChange={handleScheduleYN}
            />
          }
          label="세부시간 적용"
        />
      </Box>
      <Typography variant="subtitle2" color="text.secondary" sx={{ py: 1 }}>
        시간단위로 입찰 시간을 조정할 수 있습니다. 0인 값은 기본 입찰값을
        따릅니다.{' '}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ widht: '100%' }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ fontWeight: 'bold' }}>
              <TableCell>시간</TableCell>
              <TableCell align="center">목표 순위</TableCell>
              <TableCell align="center">최대입찰금액</TableCell>
              <TableCell align="center">가감액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tempSchedule.map((item, i) => (
              <TableRow
                hover
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {`${i.toString().padStart(2, '0')}:00 ~ ${(i + 1)
                    .toString()
                    .padStart(2, '0')}:00`}
                </TableCell>
                <TableCell align="right">
                  <OutlinedInput
                    disabled={tempScheduleYN !== 'Y'}
                    sx={{ width: '80px' }}
                    size="small"
                    id={`${i},0`}
                    value={item[0]}
                    onChange={handleChangeSchedule}
                    onBlur={handleSubmit}
                  />
                </TableCell>
                <TableCell align="right">
                  <OutlinedInput
                    disabled={tempScheduleYN !== 'Y'}
                    size="small"
                    sx={{ width: '100px' }}
                    id={`${i},1`}
                    value={item[1]}
                    onChange={handleChangeSchedule}
                    onBlur={handleSubmit}
                  />
                </TableCell>
                <TableCell align="right">
                  <OutlinedInput
                    disabled={tempScheduleYN !== 'Y'}
                    size="small"
                    sx={{ width: '100px' }}
                    id={`${i},2`}
                    value={item[2]}
                    onChange={handleChangeSchedule}
                    onBlur={handleSubmit}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BidTimeDetailSetting;
