import axios from 'axios';

let flag = 0;

export const URL =
  process.env.REACT_APP_ENV === 'DEV'
    ? 'https://localhost:8000'
    : 'https://biddingeasy.co.kr/api';

// export const URL = 'https://192.168.0.28:8000';

axios.defaults.withCredentials = true;

export const customAxios = axios.create({
  baseURL: URL,
  withCredentials: true, // withCredentials를 true로 설정하여 CORS 요청 시 쿠키를 포함합니다
});

customAxios.interceptors.request.use(
  //axios 요청 직전에 발생하는 함수
  function (config) {
    return config;
  },
  //axios 요청 에러나면 발생하는 함수
  function (error) {
    return Promise.reject(error);
  },
);

customAxios.interceptors.response.use(
  //axios 올바른 response가오면 발동하는 함수
  function (response) {
    return response;
  },

  //에러 메시지가 response되면 발동되는 함수
  async function (error) {
    console.log('[axios-config] error massage >>', error.response);
    if (
      flag < 5 &&
      error.response &&
      error.response.data.error === 'jwt expired (ACCESS_TOKEN)'
    ) {
      await getAccessToken();
      flag++;
      return customAxios(error.config);
    } else if (
      error.response &&
      error.response.data.error === 'jwt expired (REFRESH_TOKEN)'
    ) {
      //리프레쉬 토큰마져 사라지면 답이 없기 때문에 remember me 도 false처리함
      //리멤버미 and islogin false 로변경후 login 페이지로 보내기
      localStorage.setItem('REMEMBER_ME', 'false');
      sessionStorage.setItem('IS_LOGIN', 'false');
      flag = 0;
      window.location.replace(`https://biddingeasy.co.kr/login`);
    }
    return Promise.reject(error);
  },
);

//accessToken받아오는 함수
export async function getAccessToken() {
  // console.log('accessToken과 refreshToken을 재발급 받습니다.');
  await axios.post(`${URL}/refresh`);
  // await axios.post(`${URL}/refresh`).then(() => {
  //   axios.get(`${URL}/`).then(() => {
  //     console.log('재발급 받은 accessToken 유효성 검사 통과');
  //   });
  // });
}
