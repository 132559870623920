import { Stack } from '@mui/material';
import ResizableSidebar from '../components/bidDashBoard/campainAndGroupList/ResizableSidebar';
import CampaignAndGroupList from '../components/bidDashBoard/campainAndGroupList/CampaignAndGroupList';
import BidDashBoard from '../components/bidDashBoard/BidDashBoard';
import { Outlet } from 'react-router-dom';
const MenuAndDashBoard: React.FC = () => {
  return (
    <>
      <Stack direction="row">
        <ResizableSidebar>
          <CampaignAndGroupList />
        </ResizableSidebar>
        {/* searchBar, bidTable */}
        <Outlet />
      </Stack>
    </>
  );
};

export default MenuAndDashBoard;
