import { Stack, Box, Typography, Divider, Chip, Card } from '@mui/material';
import { useMemo, useState } from 'react';
import CustomerInfoDetail from './CustomerInfoDetail';
import { CustomerInfoType } from '../../../service/admin/type';

interface CustomerInfoProps {
  customerInfo: CustomerInfoType;
}

const CustomerItem: React.FC<CustomerInfoProps> = ({ customerInfo }) => {
  const [openCustomerInfoDetail, setOpenCustomerInfoDetail] =
    useState<boolean>(false);

  // 상세보기 열기
  const handleClickOpen = () => {
    setOpenCustomerInfoDetail(true);
  };

  // 상세보기 닫기
  const handleClose = () => {
    setOpenCustomerInfoDetail(false);
  };
  const {
    CUSTOMER_NAME,
    ALL_BID_COUNT,
    BID_COUNT,
    KEYWORD_COUNT,
    CHECK_KEYWORD_COUNT,
    PRICE_NAME,
    ID,
    RECENT_TIME,
    PAY_YN,
  } = customerInfo;

  //잔여일 계산기
  const handleShowExpirationDate = () => {
    const expiration_date: Date = new Date(customerInfo.EXPIRATION_DATE);
    const current_date: Date = new Date();
    const timeDiff: number = expiration_date.getTime() - current_date.getTime();
    const day = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return day <= 0 ? 0 : day;
  };

  const keywordUsage = useMemo(() => {
    return Math.floor((KEYWORD_COUNT / CHECK_KEYWORD_COUNT) * 100);
  }, []);

  const daliybidCount = useMemo(() => {
    return Math.floor((BID_COUNT / ALL_BID_COUNT) * 100);
  }, []);

  return (
    <Card
      sx={{
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 2,
        },
      }}
    >
      <Stack onClick={handleClickOpen} gap={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Typography variant="subtitle2">{ID}</Typography>
            <Typography variant="body1" fontWeight={'bold'}>
              {CUSTOMER_NAME}
            </Typography>
          </Box>
          <Chip
            label={PAY_YN === 'N' ? '사용정지' : PRICE_NAME}
            variant="outlined"
            color={PAY_YN === 'N' ? "error" : "primary"}
          />
        </Box>

        <Divider />
        <Stack sx={{ justifyContent: 'space-between' }} direction={'row'}>
          <Typography variant="subtitle1">잔여일 : </Typography>
          <Typography variant="subtitle1">
            {handleShowExpirationDate()} 일{' '}
          </Typography>
        </Stack>
        {/* 키워드 그래프 */}
        <Stack>
          <Stack direction={'row'} justifyContent="space-between">
            <Typography variant="body1">키워드</Typography>
            <Typography variant="body1">{keywordUsage}%</Typography>
          </Stack>
          <Box
            component="div"
            sx={{
              width: '100%',
              height: '10px',
              background: `linear-gradient(to right, #4F98FF 0 ${keywordUsage}%, #DEDEDE ${keywordUsage}% 100%)`,
              borderRadius: '10px',
            }}
          ></Box>
        </Stack>

        {/* 입찰수 그래프 */}
        <Stack>
          <Stack direction={'row'} justifyContent="space-between">
            <Typography variant="body1">입찰수</Typography>
            <Typography variant="body1">{daliybidCount}%</Typography>
          </Stack>
          <Box
            component="div"
            sx={{
              width: '100%',
              height: '10px',
              background: `linear-gradient(to right, #5e4fff 0 ${daliybidCount}%, #DEDEDE ${daliybidCount}% 100%)`,
              borderRadius: '10px',
            }}
          ></Box>
        </Stack>
        <Typography variant="caption" color={'gray'}>
          최근 접속일자 : {RECENT_TIME ? RECENT_TIME : '없음'}
        </Typography>
      </Stack>
      <CustomerInfoDetail
        open={openCustomerInfoDetail}
        onClose={handleClose}
        customerInfo={customerInfo}
      />
    </Card>
  );
};

export default CustomerItem;
