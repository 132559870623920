import {
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormLabel,
  OutlinedInput,
  IconButton,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useMemo } from 'react';
import { useUpdateNaverApi } from '../../service/user/userHooks';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from '../../store';
import { customToast } from '../../utils/customToast';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { NaverApiType } from '../../service/user/type';

const FormLabelStyle = { textAlign: 'right', width: '150px', mr: 2 };
const FormControlStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};





export const RegistNaverApiButton: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [showText, setShowText] = useState(false);
  const userInfo = useRecoilValue(UserInfoState);
  const [apiInfo, setApiInfo] = useState<NaverApiType>({
    accessKey: userInfo.ACCESS_KEY,
    secretKey: userInfo.SECRET_KEY,
    customerId: userInfo.CUSTOMER_ID,
  });


  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };


  const {mutate:registApi} = useUpdateNaverApi();

  const handleShowText = () => {
    setShowText(!showText);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    setApiInfo((prev) => {
      return { ...prev, [id]: value };
    });
  };

  const { accessKey, secretKey, customerId } = useMemo(
    () => apiInfo,
    [apiInfo],
  );

  const handleSubmit = () => {
    if (accessKey === '') {
      customToast('error', '엑세스 라이센스를 입력해 주세요.');
    } else if (secretKey === '') {
      customToast('error', '비밀키를 입력해 주세요.');
    } else if (customerId === '') {
      customToast('error', 'customer id 를 입력해 주세요');
    } else {
      handleDialog();
      registApi(apiInfo);
    }
  };

  return (
    <>
      <Button
      fullWidth
        variant="outlined"
        onClick={handleDialog}
        sx={{fontWeight:"bold"}}
      >
        API 키 등록 및 변경
      </Button>
      <Dialog open={openDialog} onClose={handleDialog} maxWidth={'lg'}>
        <Stack sx={{ p: 3, width: '700px' }} gap={2}>
          <Box sx={{ width: '100%', textAlign: 'right' }}>
            <IconButton onClick={handleDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle color="primary" fontWeight={'bold'} sx={{ pt: 0 }}>
            API 연결{' '}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              정확한 네이버 API Key를 입력해주세요
            </DialogContentText>
          </DialogContent>
          <FormControl sx={FormControlStyle}>
            <FormLabel sx={FormLabelStyle} htmlFor="customerId">
              CUSTOMER ID
            </FormLabel>
            <OutlinedInput
              id="customerId"
              size="small"
              type={showText ? 'text' : 'password'}
              value={customerId}
              fullWidth
              onChange={handleInputChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowText}
                    edge="end"
                  >
                    {showText ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={FormControlStyle}>
            <FormLabel sx={FormLabelStyle} htmlFor="accessKey">
              엑세스 라이센스
            </FormLabel>
            <OutlinedInput
              id="accessKey"
              size="small"
              type={showText ? 'text' : 'password'}
              value={accessKey}
              fullWidth
              onChange={handleInputChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowText}
                    edge="end"
                  >
                    {showText ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={FormControlStyle}>
            <FormLabel sx={FormLabelStyle} htmlFor="secretKey">
              비밀키
            </FormLabel>
            <OutlinedInput
              id="secretKey"
              size="small"
              type={showText ? 'text' : 'password'}
              value={secretKey}
              fullWidth
              onChange={handleInputChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowText}
                    edge="end"
                  >
                    {showText ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box sx={{ width: '100%', textAlign: 'right' }}>
            <Button
              variant="contained"
              sx={{ width: '150px' }}
              onClick={handleSubmit}
            >
              등록
            </Button>
          </Box>
        </Stack>
      </Dialog>
    </>
  );
};


export default RegistNaverApiButton;