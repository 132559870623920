import {
  Box,
  Stack,
  MenuItem,
  Select,
  OutlinedInput,
  SelectChangeEvent,
  Button,
  Grid,
  Typography,
  Pagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { sortOrderList, sortTypeList } from '../config';
import CustomerInfo from './CustomerItem';
import { useGetCustomerListQuery } from '../../../service/admin/adminHooks';
import { CustomerInfoType } from '../../../service/admin/type';
const CustomerList: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<string>('START_TIME');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortType, setSortType] = useState<string>('ASC'); //asc : 오름차순, desc : 내림차순
  const rowsPerPage = 12;

  const handleCurrentPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };

  //사용자 리스트 불러오기
  const { data, refetch } = useGetCustomerListQuery({
    rowsPerPage,
    sortOrder,
    searchQuery,
    currentPage,
    sortType,
  });

  //오름차순 내림차순
  const handleChangeOrderType = (e: SelectChangeEvent) => {
    setSortType(e.target.value as string);
  };

  //정렬기준 선택
  const handleChangeSortOrder = (e: SelectChangeEvent) => {
    setSortOrder(e.target.value as string);
  };

  //검색어 찾기 (이름 기반.. )
  const handleFindSearchQuery = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchQuery(new FormData(e.currentTarget).get('searchQuery') as string);
  };

  return (
    <>
      <Box component="div" sx={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Typography variant="h5">유저 목록 </Typography>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            mt: 4,
            width: '100%',
          }}
        >
          <Stack direction={'row'} gap={1}>
            <Select
              onChange={handleChangeSortOrder}
              sx={{ width: '150px', backgroundColor: 'background.paper' }}
              value={sortOrder}
              size="small"
            >
              {sortOrderList.map((item, i) => (
                <MenuItem key={i} value={item.sortOrder}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              size="small"
              onChange={handleChangeOrderType}
              value={sortType}
              sx={{ backgroundColor: 'background.paper' }}
            >
              {sortTypeList.map((item, i) => (
                <MenuItem value={item.sortType} key={i}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack direction={'row'} gap={1}>
            <Box component="form" onSubmit={handleFindSearchQuery}>
              <OutlinedInput
                endAdornment={<SearchIcon />}
                placeholder="검색어를 입력해주세요"
                name="searchQuery"
                size="small"
                sx={{ backgroundColor: 'background.paper' }}
              />
            </Box>
            <Button
              onClick={() => {
                refetch();
              }}
              variant="outlined"
              sx={{ height: '36.7px', backgroundColor: 'background.paper' }}
            >
              새로고침
            </Button>
          </Stack>
        </Stack>

        {/* 사용자 리스트 */}
        <Grid container spacing={4} sx={{ mt: 2 }}>
          {!!data &&
            !!data.customerList &&
            data.customerList.map((item: CustomerInfoType) => (
              <Grid item lg={3} md={4} xs={6} key={item.ID}>
                <CustomerInfo customerInfo={item} />
              </Grid>
            ))}
        </Grid>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: 10,
          }}
        >
          <Pagination
            onChange={handleCurrentPageChange}
            page={currentPage}
            count={data?.totalCount ? data.totalCount : 1}
          />
        </Box>
      </Box>
    </>
  );
};

export default CustomerList;
