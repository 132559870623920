import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useUpdateGroupSchedule } from '../../service/group/groupHooks';

interface GroupSchedulerProps {
  groupId: string;
  schedule: string;
}

const GroupScheduler: React.FC<GroupSchedulerProps> = ({
  groupId,
  schedule,
}) => {
  const [open, setOpen] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [time, setTime] = useState<number[]>([
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ]);

  //schedule이 변경될때마다 다시 설정
  useEffect(() => {
    setTime(schedule.split('').map((item) => +item));
  }, [schedule]);

  const { mutateAsync } = useUpdateGroupSchedule();

  const size = useMemo(() => {
    return time.length;
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleMouseDown = () => {
    setDragging(true);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  //오전시간 전체 선택
  const handleSelectAm = () => {
    let check = true;
    for (let i = 0; i < size / 2; i++) {
      if (time[i] === 0) {
        check = false;
        break;
      }
    }

    setTime((prev) => {
      const newTime = [...prev];
      for (let i = 0; i < size / 2; i++) {
        newTime[i] = check ? 0 : 1;
      }
      return newTime;
    });
  };

  //오후시간 전체 선택
  const handleSelectPm = () => {
    let check = true;
    for (let i = size / 2; i < size; i++) {
      if (time[i] === 0) {
        check = false;
        break;
      }
    }
    setTime((prev) => {
      const newTime = [...prev];
      for (let i = size / 2; i < size + 1; i++) {
        newTime[i] = check ? 0 : 1;
      }
      return newTime;
    });
  };

  //선택하기
  const handleSelect = (index: number) => {
    setTime((prev) => {
      const newTime = [...prev];
      newTime[index] = newTime[index] === 0 ? 1 : 0;
      return newTime;
    });
  };

  //제출
  const handleSubmit = () => {
    mutateAsync({ groupId, schedule: time.join('') });
    setOpen(!open);
  };

  // TimeScheduler

  const showSchedule = () => {
    const schedule = [];

    //시간 표시
    for (let i = 0; i < size / 2 + 1; i++) {
      schedule.push(
        <Grid
          key={100 + i}
          item
          xs={1}
          sx={{ position: 'relative', left: '28px', textAlign: 'center' }}
        >
          {i}
        </Grid>,
      );
    }

    //칸 표시
    for (let i = 0; i < size + 2; i++) {
      if (i === 0) {
        schedule.push(
          <Grid
            item
            xs={1}
            key={i + 50}
            onClick={handleSelectAm}
            sx={{ cursor: 'pointer' }}
          >
            오전
          </Grid>,
        );
      } else if (i === size / 2 + 1) {
        schedule.push(
          <Grid
            item
            xs={1}
            key={i + 50}
            onClick={handleSelectPm}
            sx={{ cursor: 'pointer' }}
          >
            오후
          </Grid>,
        );
      } else {
        let idx = i > size / 2 ? i - 2 : i - 1;
        schedule.push(
          <Grid
            key={i}
            xs={1}
            item
            sx={{
              width: '20px',
              height: '30px',
              border: `1px solid #e2e2e2`,
              backgroundColor: time[idx] === 1 ? '#3f51b5' : 'white',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!dragging) handleSelect(idx);
            }}
            onMouseOver={() => {
              if (dragging) handleSelect(idx);
            }}
          />,
        );
      }
    }
    return schedule;
  };

  return (
    <>
    <Tooltip title={"그룹별 입찰 시간을 설정할 수 있습니다."} arrow placement='top'>

      <Button
        sx={{ width: '150px', boxShadow: 0, backgroundColor: 'white' }}
        variant="outlined"
        startIcon={<AccessTimeIcon />}
        onClick={handleOpen}
        >
        입찰 시간 설정
      </Button>
        </Tooltip>
      <Dialog open={open} onClose={handleOpen} maxWidth="xl">
        <DialogTitle>입찰 시간 설정</DialogTitle>
        <DialogContent>
          <DialogContentText>
            입찰 세부 시간은 1시간 단위로 설정 가능하며, 선택된 시간에만 키워드
            입찰이 동작하니 주의하시기 바랍니다.
          </DialogContentText>
          <Grid
            sx={{ width: '800px', p: 4 }}
            container
            columns={size / 2 + 1}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {showSchedule()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ width: '120px', mr: 2, mb: 2 }}
            onClick={handleSubmit}
            variant="contained"
          >
            완료
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupScheduler;
