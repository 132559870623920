import {
  IconButton,
  Button,
  OutlinedInput,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  Menu,
  Tooltip,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { SearchQueryState, SearchQueryStateType } from '../../store/index';
import { useResetRecoilState } from 'recoil';
import TuneIcon from '@mui/icons-material/Tune';
const SearchBarFilter: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const resetSearchQueryState = useResetRecoilState(SearchQueryState); //전역 searchQuery 초기화 (default 값으로 돌림)
  const [atomSearchQuery, setAtomSearchQuery] =
    useRecoilState<SearchQueryStateType>(SearchQueryState); // 전역으로 관리되는 searchQuery
  const [searchQuery, setSearchQuery] = useState(atomSearchQuery); // 임시로 저장되는 searchQuery
  const open = Boolean(anchorEl);
  const menuClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    resetSearchQueryState(); // 전에 있던 값들 초기화시킴
  }, [resetSearchQueryState]);

  //닫기
  const handleClose = () => {
    setAnchorEl(null);
  };

  //각 필터의 값을 변경시켜주는 함수
  const handleSetFilter = (key: keyof SearchQueryStateType, value: string) => {
    value = value.replace(/\D/g, ''); // 123,123,12  => 12312312
    const newData: SearchQueryStateType = {
      ...searchQuery,
      [key]: !!value ? parseInt(value) : 0, // 빈 값일 때 0으로 바꿔줌
    };
    setSearchQuery(newData);
  };

  return (
    <>
      <Tooltip title="검색 필터" placement="top" arrow>
        <IconButton size="large" onClick={menuClickHandler}>
          <TuneIcon />
        </IconButton>
      </Tooltip>
      {/* 햄버거 메뉴 */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 10,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <form
          style={{ padding: '10px' }}
        >
          <Stack direction={'column'} gap={1}>
            <Typography variant="subtitle2">키워드 상세 검색 필터</Typography>
            <Typography variant="subtitle2" sx={{ pt: 2 }}>
              상태
            </Typography>
            <Stack direction={'row'} gap={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={searchQuery.bidding_start === 1}
                    onChange={(e) => {
                      handleSetFilter(
                        'bidding_start',
                        e.target.checked ? '1' : '0',
                      );
                    }}
                  />
                }
                label="입찰 중"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={searchQuery.bidding_stop === 1}
                    onChange={(e) => {
                      handleSetFilter(
                        'bidding_stop',
                        e.target.checked ? '1' : '0',
                      );
                    }}
                  />
                }
                label="입찰 정지"
              />
            </Stack>
            <Typography variant="subtitle2" sx={{ pt: 2 }}>
              현재 순위
            </Typography>
            <Stack direction={'row'} gap={2} sx={{ pb: 2 }}>
              <OutlinedInput
                size="small"
                value={searchQuery.current_ranking_min.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('current_ranking_min', e.target.value);
                }}
              />
              <Typography variant="h6">~</Typography>
              <OutlinedInput
                size="small"
                value={searchQuery.current_ranking_max.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('current_ranking_max', e.target.value);
                }}
              />
            </Stack>
            <Typography variant="subtitle2" sx={{ pt: 2 }}>
              목표 순위
            </Typography>
            <Stack direction={'row'} gap={2} sx={{ pb: 2 }}>
              <OutlinedInput
                size="small"
                value={searchQuery.target_ranking_min.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('target_ranking_min', e.target.value);
                }}
              />
              <Typography variant="h6">~</Typography>
              <OutlinedInput
                size="small"
                value={searchQuery.target_ranking_max.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('target_ranking_max', e.target.value);
                }}
              />
            </Stack>
            <Typography variant="subtitle2" sx={{ pt: 2 }}>
              최대 입찰 금액
            </Typography>
            <Stack direction={'row'} gap={2} sx={{ pb: 2 }}>
              <OutlinedInput
                size="small"
                value={searchQuery.maximum_bid_price_min.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('maximum_bid_price_min', e.target.value);
                }}
              />
              <Typography variant="h6">~</Typography>
              <OutlinedInput
                size="small"
                value={searchQuery.maximum_bid_price_max.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('maximum_bid_price_max', e.target.value);
                }}
              />
            </Stack>
            <Typography variant="subtitle2" sx={{ pt: 2 }}>
              현재 입찰 금액
            </Typography>
            <Stack direction={'row'} gap={2} sx={{ pb: 2 }}>
              <OutlinedInput
                size="small"
                value={searchQuery.current_bid_price_min.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('current_bid_price_min', e.target.value);
                }}
              />
              <Typography variant="h6">~</Typography>
              <OutlinedInput
                size="small"
                value={searchQuery.current_bid_price_max.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('current_bid_price_max', e.target.value);
                }}
              />
            </Stack>
            <Typography variant="subtitle2" sx={{ pt: 2 }}>
              가감액
            </Typography>
            <Stack direction={'row'} gap={2} sx={{ pb: 2 }}>
              <OutlinedInput
                size="small"
                value={searchQuery.adjustment_price_min.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('adjustment_price_min', e.target.value);
                }}
              />
              <Typography variant="h6">~</Typography>
              <OutlinedInput
                size="small"
                value={searchQuery.adjustment_price_max.toLocaleString()}
                onChange={(e) => {
                  handleSetFilter('adjustment_price_max', e.target.value);
                }}
              />
            </Stack>

            <Stack direction={'row-reverse'} gap={2}>
              <Button
                variant="contained"
                sx={{ width: '100px' }}
                onClick={() => {
                  setAtomSearchQuery(searchQuery); // 적용 누르면 그때 전역 searchQuery에 적용시킴 (bidTable에 적용시킴)
                  handleClose();
                }}
              >
                적용
              </Button>
              <Button
                variant="outlined"
                sx={{ width: '100px' }}
                onClick={() => {
                  resetSearchQueryState();
                  setSearchQuery(atomSearchQuery); //초기화 기반으로 다시 초기화
                  setSearchQuery(atomSearchQuery); //한번하면 비동기 처리 때문에 잘 안됨
                }}
              >
                초기화
              </Button>
            </Stack>
          </Stack>
        </form>
      </Menu>
    </>
  );
};

export default SearchBarFilter;
