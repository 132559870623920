export const GROUP_STATUS_REASON: { [key: string]: string } = {
  ELIGIBLE: '정상',
  PAUSED: '일시정지',
  DELETED: '오류',
  CAMPAIGN_PAUSED: '캠페인 일시정지',
  CAMPAIGN_PENDING: '캠페인 대기',
  CAMPAIGN_ENDED: '캠페인 종료',
  CAMPAIGN_LIMITED_BY_BUDGET: '캠페인 예산 초과',
  BUSINESS_CHANNEL_PAUSED: '비즈채널 일시정지',
  BUSINESS_CHANNEL_DISAPPROVED: '비즈채널 미승인',
  BUSINESS_CHANNEL_UNDER_REVIEW: '비즈채널 검토중',
  PC_BUSINESS_CHANNEL_APPROVED: 'PC 비즈채널 승인',
  PC_BUSINESS_CHANNEL_DISAPPROVED: 'PC 비즈채널 미승인',
  PC_BUSINESS_CHANNEL_UNDER_REVIEW: 'PC 비즈채널 검토중',
  MOBILE_BUSINESS_CHANNEL_APPROVED: '모바일 비즈채널 승인',
  MOBILE_BUSINESS_CHANNEL_DISAPPROVED: '모바일 비즈채널 미승인',
  MOBILE_BUSINESS_CHANNEL_UNDER_REVIEW: '모바일 비즈채널 검토중',
  GROUP_DELETED: '그룹 삭제',
  GROUP_PAUSED: '그룹 일시정지',
  GROUP_LIMITED_BY_BUDGET: '그룹 예산 초과',
};

export const KEYWORD_STATUS_REASON: { [key: string]: string } = {
  KEYWORD_DELETED: '키워드 삭제',
  KEYWORD_PAUSED: '키워드 일시정지',
  KEYWORD_DISAPPROVED: '키워드 미승인',
  KEYWORD_UNDER_REVIEW: '키워드 검토중',
};

export const getStatusReason = (status: string) => {
  if (!!GROUP_STATUS_REASON[status]) {
    return GROUP_STATUS_REASON[status];
  } else if (!!KEYWORD_STATUS_REASON[status]) {
    return KEYWORD_STATUS_REASON[status];
  } else {
    return status;
  }
};
