import {
  Toolbar,
  Typography,
  Stack,
  Chip,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Switch,
  ListItemText,
  Box,
  FormControlLabel,
  Tooltip,
  IconButton,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ShowColumnState } from '../../../store';
import { headCells, HeadCell } from './BidTable';
// import SettingsIcon from '@mui/icons-material/Settings';
import { GroupType } from '../../../service/group/type';
import SyncIcon from '@mui/icons-material/Sync';
import SearchBar from '../SearchBar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { useUpdateSelectedKeywordStateByKeywordId } from '../../../service/keyword/keywordHooks';
interface EnhancedTableToolbarProps {
  handleKeywordRefetch: () => void;
  handleChangeDense: (e: ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  groupInfo: GroupType;
  onOpen: () => void;
  dense: boolean;
  updateTime: string;
  keywordIdList: string[];
}

//상단 필터
function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    // onOpen,
    // groupInfo,
    dense,
    handleChangeDense,
    handleKeywordRefetch,
    updateTime,
    keywordIdList,
  } = props;
  const { mutate } = useUpdateSelectedKeywordStateByKeywordId();

  const handleSelectedKeywordStop = () => {
    mutate({ keywordIdList, isCheck: 'N' });
  };

  const handleSelectedKeywordStart = () => {
    mutate({ keywordIdList, isCheck: 'Y' });
  };

  return (
    <Toolbar
      sx={{
        pl: { xs: 0 },
        pr: { xs: 0 },
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          sx={{ mb: 5 }}
        >
          <Tooltip
            title={
              '[현재순위, 최대노출, 목표순위]를 간편하게 확인할 수 있습니다.'
            }
            arrow
            placement="top"
          >
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="간편보기"
            />
          </Tooltip>
          <Stack direction={'row'} gap={2} alignItems="center">
            {/* 선택항목이 1개 이상이면 보이는 일괄 버튼 */}
            {numSelected > 0 && (
              <>
                <Button
                  onClick={handleSelectedKeywordStart}
                  variant="outlined"
                  startIcon={<PlayArrowIcon />}
                  sx={{ height: '48px' }}
                >
                  선택 일괄입찰
                </Button>
                <Button
                  onClick={handleSelectedKeywordStop}
                  variant="outlined"
                  startIcon={<StopIcon />}
                  sx={{ height: '48px' }}
                >
                  선택 일괄정지
                </Button>
              </>
            )}
            {/* 키워드 검색 창 */}
            <Box sx={{ width: '350px' }}>
              <SearchBar />
            </Box>
            <Tooltip
              title={'최근 업데이트 : ' + updateTime}
              arrow
              placement="top"
            >
              {/* 새로고침 버트 */}
              <IconButton onClick={handleKeywordRefetch}>
                <SyncIcon />
              </IconButton>
            </Tooltip>
            <FilterMenu />
          </Stack>
        </Stack>
        {/* {numSelected > 0 && (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="primary"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        )} */}

        {/* 하나 이상이면 delete 버튼 생김 */}
        {/* {numSelected > 0 ? (
          <Button onClick={onOpen}>
            <Typography sx={{ width: '80px' }}>일괄변경</Typography>
            <SettingsIcon />
          </Button>
        ) : (
          // <FilterMenu />
          <></>
        )} */}
      </Stack>
    </Toolbar>
  );
}

// 필터 메뉴
function FilterMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showColumn, setShowColumn] =
    useRecoilState<boolean[]>(ShowColumnState);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShow = (i: number) => {
    const updateShowColumn = [...showColumn];
    updateShowColumn[i] = !updateShowColumn[i];
    setShowColumn(updateShowColumn);
  };

  return (
    <>
      {/* <Button
        onClick={handleClick}
        variant="text"
        startIcon={<FilterListIcon />}
      >
        <Typography>속성</Typography>
      </Button> */}

      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose} //밖을 클릭하면 없어짐
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {headCells.map((item: HeadCell, i) => {
          if (i > 0 && i < 7) {
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  handleShow(i);
                }}
              >
                <ListItemIcon>
                  <Switch checked={showColumn[i]}></Switch>
                </ListItemIcon>
                <ListItemText> {item.label}</ListItemText>
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </Menu>
    </>
  );
}

export default EnhancedTableToolbar;
