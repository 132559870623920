import { ChangeEvent, useEffect } from 'react';
import {
  Divider,
  Button,
  Dialog,
  DialogActions,
  Typography,
  Box,
  Grid,
  DialogTitle,
  OutlinedInput,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { useState, useMemo } from 'react';
import { useUpdateCustomerInfoMutation } from '../../../service/admin/adminHooks';
import {
  CustomerInfoType,
  updateCustomerInfoType,
} from '../../../service/admin/type';
interface CustomerIntoDetailProps {
  open: boolean;
  onClose: () => void;
  customerInfo: CustomerInfoType;
}

const TitleStyle = {
  fontWeight: 'bold',
  fontSize: '15px',
  mb: 1,
};

const ContentStyle = {
  fontSize: '14px',
  fontWeight: 'light',
  color: 'grey',
};

const InputTitleStyle = {
  fontWeight: 'bold',
  fontSize: '15px',
  mb: 1,
  width: '200px',
};
const CustomerInfoDetail: React.FC<CustomerIntoDetailProps> = ({
  onClose,
  open,
  customerInfo,
}) => {
  const {
    ALL_BID_COUNT,
    BID_COUNT,
    IS_ADMIN,
    PAY_YN,
    BUSINESS_NUMBER,
    CHECK_KEYWORD_COUNT,
    CUSTOMER_NAME,
    EMAIL,
    EXPIRATION_DATE,
    ID,
    IS_GROUP,
    KEYWORD_COUNT,
    MDB,
    PHONE_NUMBER,
    PRICE_LEVEL,
    VIRTURE_ACCOUNT,
    RECENT_TIME,
    START_TIME,
  } = customerInfo;

  const [payYn, setPayYn] = useState<string>(PAY_YN);
  const [priceLevel, setPriceLevel] = useState<string>(PRICE_LEVEL);

  const keywordUsage = useMemo(() => {
    return Math.floor((KEYWORD_COUNT / CHECK_KEYWORD_COUNT) * 100);
  }, [KEYWORD_COUNT, CHECK_KEYWORD_COUNT]);

  const daliybidCount = useMemo(() => {
    return Math.floor((BID_COUNT / ALL_BID_COUNT) * 100);
  }, [BID_COUNT, ALL_BID_COUNT]);

  const [newCustomerInfo, setNewCustomerInfo] =
    useState<updateCustomerInfoType>({
      id: ID,
      customerName: CUSTOMER_NAME,
      phoneNumber: PHONE_NUMBER,
      businessNumber: BUSINESS_NUMBER,
      email: EMAIL,
      isGroup: IS_GROUP,
      mdb: MDB,
      virtureAccount: VIRTURE_ACCOUNT,
      expirationDate: EXPIRATION_DATE,
    });

  const { mutate: updateCustomerInfo } = useUpdateCustomerInfoMutation(ID, {
    ...newCustomerInfo,
    priceLevel,
    payYn,
  });

  useEffect(() => {
    setPriceLevel(PRICE_LEVEL);
    setNewCustomerInfo({
      id: ID,
      customerName: CUSTOMER_NAME,
      phoneNumber: PHONE_NUMBER,
      businessNumber: BUSINESS_NUMBER,
      email: EMAIL,
      isGroup: IS_GROUP,
      mdb: MDB,
      virtureAccount: VIRTURE_ACCOUNT,
      expirationDate: EXPIRATION_DATE,
    });
  }, [customerInfo, open]);

  const {
    businessNumber,
    id,
    customerName,
    phoneNumber,
    email,
    isGroup,
    mdb,
    virtureAccount,
    expirationDate,
  } = useMemo(() => newCustomerInfo, [newCustomerInfo]);

  //input값 올바른지 확인하는 정규식 모음
  const handleCheckValidation = {
    nameFormat() {
      return /^[^\d!@#$%^&*(),.?":{}|<>0-9]*$/.test(customerName!);
    },
    emailFormat() {
      return /\S+@\S+\.\S+/.test(email!);
    },
    businessNumberFormat() {
      return /^\d{3}-\d{2}-\d{5}$/.test(businessNumber!);
    },
    idFormat() {
      return /^[a-zA-Z0-9\-_]{6,12}$/.test(id!);
    },
    phoneNumberFormat() {
      return /^010\d{4}\d{4}$/.test(phoneNumber!);
    },
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    setPriceLevel(e.target.value);
  };
  const handleSelectPayYNChange = (e: SelectChangeEvent) => {
    setPayYn(e.target.value);
  };

  //모든 input에 대한 change 이벤트 처리
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setNewCustomerInfo((prev) => {
      return { ...prev, [id]: value };
    });
  };

  const handleSubmit = () => {
    updateCustomerInfo();
    // onClose()
  };
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ mt: 2 }} id="alert-dialog-title">
          {CUSTOMER_NAME}
        </DialogTitle>
        <Divider sx={{ m: 4 }} />
        <Box component="div" sx={{ p: 4, pt: 0 }}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography sx={TitleStyle}>최초 가입일</Typography>
              <Typography sx={{ ...ContentStyle, fontSize: '16px' }}>
                {START_TIME}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={TitleStyle}>최근 사용일</Typography>
              <Typography sx={{ ...ContentStyle, fontSize: '16px' }}>
                {RECENT_TIME}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={TitleStyle}>키워드</Typography>
              <Box
                component={'div'}
                sx={{
                  width: '80%',
                  height: '20px',
                  background: `linear-gradient(to right, #4F98FF 0 ${keywordUsage}%, #DEDEDE ${keywordUsage}% 100%)`,
                  borderRadius: '10px',
                  mb: 1,
                }}
              />
              <Box
                sx={{
                  width: '80%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                }}
              >
                <Typography sx={ContentStyle}>
                  {KEYWORD_COUNT.toLocaleString()}/
                  {CHECK_KEYWORD_COUNT.toLocaleString()}
                </Typography>
                <Typography sx={ContentStyle}>{keywordUsage}%</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={TitleStyle}>일일 사용량</Typography>
              <Box
                component={'div'}
                sx={{
                  width: '80%',
                  height: '20px',
                  background: `linear-gradient(to right, #4F98FF 0 ${daliybidCount}%, #DEDEDE ${daliybidCount}% 100%)`,
                  borderRadius: '10px',
                  mb: 1,
                }}
              />
              <Box
                sx={{
                  width: '80%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                }}
              >
                <Typography sx={ContentStyle}>
                  {BID_COUNT.toLocaleString()}/{ALL_BID_COUNT.toLocaleString()}
                </Typography>
                <Typography sx={ContentStyle}>{daliybidCount}%</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container sx={{ p: 4 }} spacing={4}>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>요금제</Typography>
              <Select
                id="priceLevel"
                sx={{ width: '60%' }}
                size="small"
                onChange={handleSelectChange}
                value={priceLevel}
              >
                {['0', '1', '2', '3', '4', '5'].map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>만기일</Typography>
              <TextField
                onChange={handleInputChange}
                value={expirationDate}
                size="small"
                id="expirationDate"
                type="date"
                sx={{ height: '36.7px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>이름</Typography>
              <OutlinedInput
                id="customerName"
                fullWidth
                size="small"
                onChange={handleInputChange}
                value={customerName}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>아이디</Typography>
              <OutlinedInput
                id="id"
                error={!handleCheckValidation.idFormat()}
                fullWidth
                size="small"
                onChange={handleInputChange}
                value={id}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>전화번호</Typography>
              <OutlinedInput
                error={!handleCheckValidation.phoneNumberFormat()}
                id="phoneNumber"
                fullWidth
                size="small"
                onChange={handleInputChange}
                value={phoneNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>이메일</Typography>
              <OutlinedInput
                error={!handleCheckValidation.emailFormat()}
                id="email"
                fullWidth
                size="small"
                onChange={handleInputChange}
                value={email}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>사업자번호</Typography>
              <OutlinedInput
                error={!handleCheckValidation.businessNumberFormat()}
                id="businessNumber"
                fullWidth
                size="small"
                onChange={handleInputChange}
                value={businessNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>is_Group</Typography>
              <OutlinedInput
                id="isGroup"
                fullWidth
                size="small"
                onChange={handleInputChange}
                value={isGroup}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>MDB</Typography>
              <OutlinedInput
                id="mdb"
                fullWidth
                size="small"
                onChange={handleInputChange}
                value={mdb}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>VIRTURE_ACCOUNT</Typography>
              <OutlinedInput
                id="virtureAccount"
                fullWidth
                size="small"
                onChange={handleInputChange}
                value={virtureAccount}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InputTitleStyle}>pay_YN</Typography>
              <Select
                id="payYn"
                sx={{ width: '60%' }}
                size="small"
                onChange={handleSelectPayYNChange}
                value={payYn}
              >
                {['Y', 'N'].map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>

        <DialogActions sx={{ mr: 2, mb: 4 }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: '100px' }}
          >
            수정
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomerInfoDetail;
