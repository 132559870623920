import { atom } from "recoil";
import { useRecoilCallback } from "recoil";
//검색어 필터

export type SearchQueryStateType = {
  keyword: string;
  bidding_start: number;
  bidding_stop: number;
  current_ranking_min: number;
  current_ranking_max: number;
  target_ranking_min: number;
  target_ranking_max: number;
  current_bid_price_min: number;
  current_bid_price_max: number;
  maximum_bid_price_min: number;
  maximum_bid_price_max: number;
  adjustment_price_min: number;
  adjustment_price_max: number;
};

export const SearchQueryState = atom<SearchQueryStateType>({
  key: "SearchQueryState",
  default: {
    keyword: "",
    bidding_start: 1,
    bidding_stop: 1,
    current_ranking_min: 0,
    current_ranking_max: 15,
    target_ranking_min: 0,
    target_ranking_max: 15,
    current_bid_price_min: 0,
    current_bid_price_max: 1000000,
    maximum_bid_price_min: 0,
    maximum_bid_price_max: 1000000,
    adjustment_price_min: 0,
    adjustment_price_max: 5000,
  },
});

// 초기값으로 리셋하는 함수
export const ResetSearchQueryState = () =>
  useRecoilCallback(({ reset }) => async () => {
    reset(SearchQueryState);
  });
