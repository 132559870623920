import { Card, Stack, IconButton, InputBase, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchBarFilter from './SearchBarFilter';
import { SearchQueryState } from '../../store/index';
import { useSetRecoilState } from 'recoil';
import { ChangeEvent, useRef, useState } from 'react';

const SearchBar: React.FC = () => {
  const setSearchQueryState = useSetRecoilState(SearchQueryState);
  const [text, setText] = useState<string>('');
  const searchRef = useRef<HTMLInputElement>(null);

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchQueryState((pre) => {
      return { ...pre, keyword: text };
    });
  };

  return (
    <Card
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
        width: '100%',
        p: '0px',
        height: '50px',
        border: '1px solid #e0e0e0',
      }}
    >
      <Box component="form" onSubmit={handleSearch} style={{ width: '100%' }}>
        <Stack direction={'row'} gap={2} sx={{ width: '100%' }}>
          <SearchBarFilter />
          <InputBase
            onChange={handleChangeText}
            placeholder="검색할 키워드를 입력해 주세요"
            id="standard-basic"
            fullWidth
            value={text}
          />
          <IconButton type="submit" sx={{ width: '50px' }}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Box>
    </Card>
  );
};

export default SearchBar;
