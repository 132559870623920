import {  AtomEffect, atom } from 'recoil';
import LOCALSTORAGE_KEY from './localStageKey';

// localStorage 와 동기화 하는 함수
const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (!!savedValue) {
      setSelf(JSON.parse(savedValue));
    }
    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

//값을 초기화 하는 함수

export type UserInfoStateType = {
  ID: string;
  CUSTOMER_NAME: string;
  PRICE_LEVEL: string;
  KEYWORD_COUNT: number;
  BID_COUNT: number;
  START_TIME: string;
  RECENT_TIME: string;
  PHONE_NUMBER: string;
  BUSINESS_NUMBER: string;
  EMAIL: string;
  EXPIRATION_DATE: string;
  IS_ADMIN: boolean;
  CUSTOMER_ID: string;
  ACCESS_KEY: string;
  SECRET_KEY: string;
  PRICE_NAME:string;
};

export const UserInfoState = atom<UserInfoStateType>({
  key: 'UserInfoState',
  default: {
    ID: '',
    CUSTOMER_NAME: '',
    PRICE_LEVEL: '',
    KEYWORD_COUNT: 0,
    BID_COUNT: 0,
    START_TIME: '',
    RECENT_TIME: '',
    PHONE_NUMBER: '',
    BUSINESS_NUMBER: '',
    EMAIL: '',
    EXPIRATION_DATE: '',
    IS_ADMIN: false,
    CUSTOMER_ID: '',
    ACCESS_KEY: '',
    SECRET_KEY: '',
    PRICE_NAME:'',
  },
  effects: [localStorageEffect(LOCALSTORAGE_KEY.USER_INFO)],
});
