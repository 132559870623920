type sortOrderListType = {
  name: string;
  sortOrder: string;
};
export const sortOrderList: sortOrderListType[] = [
  { name: '이름', sortOrder: 'CUSTOMER_NAME' },
  { name: '아이디', sortOrder: 'ID' },
  { name: '요금제', sortOrder: 'PRICE_LEVEL' },
  { name: '입찰수', sortOrder: 'BID_COUNT' },
  { name: '키워드수', sortOrder: 'KEYWORD_COUNT' },
  { name: '만기일', sortOrder: 'EXPIRATION_DATE' },
  { name: '시작시간', sortOrder: 'START_TIME' },
  { name: '최근사용시간', sortOrder: 'RECENT_TIME' },
];

type sortTypeListType = {
  name: string;
  sortType: string;
};

export const sortTypeList: sortTypeListType[] = [
  { name: '내림차순', sortType: 'DESC' },
  { name: '오름차순', sortType: 'ASC' },
];
