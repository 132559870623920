import { useEffect, useState } from 'react';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Divider,
} from '@mui/material';
import { useLogoutMutation } from '../../service/user/userHooks';

const AutoLogoutModal: React.FC = () => {
  const COUNT_TIME = 180; //초
  const AUTO_LOGOUT_TIME = 20; //5분
  // const AUTO_LOGOUT_TIME =  5; //5초

  const [tabFocus, setTabFocus] = useState<boolean>(true); // chrome tab 중에서 biddingeasy를 포커싱 하고 있는지 여부
  const [browserFocus, setBrowserFocus] = useState<boolean>(true); // window APP 중에서 browser를 포커싱 하고 있는지 여부
  const [open, setOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(COUNT_TIME);
  const logout = useLogoutMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
  };

  //사이트의 포커싱을 관리하는 useEffect
  useEffect(() => {
    let timer: any;
    //5분 타이머 동작조건 ( 5분동안 한번이라도 포커싱 된적이 없어야한다. )
    if (!(tabFocus && browserFocus)) {
      timer = setTimeout(() => {
        setOpen(true); // 모달창 열기
      }, AUTO_LOGOUT_TIME * 60 * 1000);
    } else {
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [tabFocus, browserFocus]);

  //카운트다운 시간초를 관리하는 useEffect
  useEffect(() => {
    let countdown: any;
    if (open) {
      clearInterval(countdown); // 중복되면 안되기 때문에 이전 타이머는 초기화
      const startTime = new Date();
      countdown = setInterval(() => {
        const currentTime = new Date(); // 현재시간
        const count = Math.round(
          (currentTime.getTime() - startTime.getTime()) / 1000,
        ); // 현재시간 - 시작시간
        if (count > COUNT_TIME) {
          setCount(COUNT_TIME);
          logout();
        } else {
          setCount(COUNT_TIME - count);
        }
      }, 1000);
    } else {
      clearInterval(countdown);
      setCount(COUNT_TIME);
    }

    return () => {
      clearInterval(countdown); //컴포넌트 사라질 때 claerInterval을 하지 않으면 중첩해서 돌아감
    };
  }, [open]);

  // browser focus
  window.addEventListener('focus', () => {
    setBrowserFocus(true);
  });
  // browser blur
  window.addEventListener('blur', () => {
    setBrowserFocus(false);
  });

  //tab이 포커싱하고 있는지 여부 판단
  document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
      setTabFocus(false);
    } else {
      setTabFocus(true);
    }
  });
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">자동로그아웃 안내</DialogTitle>
        <Divider></Divider>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AvTimerIcon fontSize="large" sx={{ m: 1 }} color="primary" />
          <DialogContentText color="primary" sx={{ mb: 1 }}>
            남은 시간 : {count}초
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: 'center' }}
          >
            고객님의 소중한 데이터를 안전하게 보호하기 위해,
            <br /> 약 {AUTO_LOGOUT_TIME}분동안 사용이력이 없으면 자동으로
            로그아웃됩니다.
            <br /> 추가적인 이용을 원하신다면 취소를 눌러주세요
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>로그아웃</Button>
          <Button onClick={handleClose}>취소</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AutoLogoutModal;
