import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Chip,
  Tooltip,
  Stack,
} from '@mui/material';
import React from 'react';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GroupSetting from './GroupSetting';
import { usePowerGroupBiddingActiveStateMutation } from '../../../service/group/groupHooks';
import { customToast } from '../../../utils/customToast';
import { GroupType } from '../../../service/group/type';
import { useLocation, useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { getStatusReason } from '../../../assets/text/errorMassage';
import TuneIcon from '@mui/icons-material/Tune';

interface GourpItemProps {
  data: GroupType;
}

const GroupItem: React.FC<GourpItemProps> = ({ data }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { mutate: biddingActive } = usePowerGroupBiddingActiveStateMutation();

  //선택된 그룹 정보 넣기
  const handleSelectGroup = () => {
    navigate(data.groupId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogOpen = () => {
    handleClose();
    setOpenDialog(true);
  };

  const handleGroupStart = () => {
    if (data.deviceCode !== '') {
      biddingActive({ grpId: data.groupId, isCheck: 'Y' });
    } else {
      customToast('error', '그룹 설정이 필요합니다.');
    }
    setAnchorEl(null);
  };

  const handleGroupStop = () => {
    biddingActive({ grpId: data.groupId, isCheck: 'N' });
    setAnchorEl(null);
  };
  const theme = useTheme();

  return (
    <>
      <ListItem
        component="div"
        sx={{
          // backgroundColor: 'background.paper',

          color: theme.palette.grey[800],

          ...(pathname.split('/')[2] === data.groupId
            ? {
                backgroundColor: theme.palette.grey[200],
              }
            : { backgroundColor: 'background.papaer' }),
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
          p: 0,
          pl: 3,
        }}
      >
        {/* 그룹 입찰여부 */}
        <SubdirectoryArrowRightIcon
          color="disabled"
          fontSize="small"
          sx={{ mr: 1 }}
        />

        <Chip
          sx={{ minWidth: '40px', width: '40px', height:"30px", fontSize:"11px" }}
          label={data.deviceName ? data.deviceName : 'N/A'}
          variant="outlined"
          size="small"
        />

        {/* 그룹 이름 */}

        <Tooltip
          title={
            data.groupStatus !== 'ELIGIBLE' &&
            getStatusReason(data.groupStatusReason)
          }
          arrow
          placement="top"
        >
          <ListItemText>
            <Stack
              direction="row"
              onClick={handleSelectGroup}
              alignItems={'center'}
              sx={{ p: 1, cursor: 'pointer' }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: data.groupStatus !== 'ELIGIBLE' ? 'grey' : '',
                  ...(pathname.split('/')[2] === data.groupId
                    ? {
                        color: 'primary.main',
                        fontWeight: 'bold',
                      }
                    : {}),
                  // textDecoration:
                  //   data.groupStatus !== 'ELIGIBLE' ? 'line-through' : '',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {data.groupName}
              </Typography>
              {data.groupStatus !== 'ELIGIBLE' && (
                <ErrorOutlineIcon color={'error'} sx={{ ml: 1 }} />
              )}
            </Stack>
          </ListItemText>
        </Tooltip>

        {!!data.deviceName &&
          (data.isBiddingActive === 'Y' ? (
            <>
              <PlayArrowIcon color="primary" />
            </>
          ) : (
            <>
              <StopIcon color="disabled" />
            </>
          ))}

        {/* 햄버거 버튼 */}
        <IconButton onClick={menuClickHandler}>
          <MoreVertIcon />
        </IconButton>

        {/* 햄버거 메뉴 */}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleGroupStart}>
            <ListItemIcon>
              <PlayCircleOutlineIcon fontSize="small" />
            </ListItemIcon>
            입찰 시작
          </MenuItem>
          <MenuItem onClick={handleGroupStop}>
            <ListItemIcon>
              <StopCircleIcon fontSize="small" />
            </ListItemIcon>
            입찰 중지
          </MenuItem>
          <MenuItem onClick={handleDialogOpen}>
            <ListItemIcon>
              <TuneIcon fontSize="small" />
            </ListItemIcon>
            설정
          </MenuItem>
        </Menu>
      </ListItem>
      <GroupSetting data={data} open={openDialog} onClose={handleDialogClose} />
    </>
  );
};

export default GroupItem;
