import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SideNav from '../components/sideNav/SideNav';
import { Outlet } from 'react-router-dom';
import { Box, Stack, useMediaQuery } from '@mui/material';
import TopNav from '../components/topNav/TopNav';
import { useAutoLoginMutation } from '../service/user/userHooks';
import AutoLogoutModal from '../components/autoLogoutModal/AutoLogoutModal';
export const SIDE_NAV_WIDTH = 90;
export const TOP_NAV_HEIGHT = 0;
const Layout: React.FC = () => {
  const location = useLocation();
  const [openNav, setOpenNav] = useState(false);
  const lgUp = useMediaQuery('(min-width:1440px)'); //1200보다 작으면 false 크면 true
  const autoLogin = useAutoLoginMutation();

  //펼처진 nav바 닫는 역할
  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false);
    }
  }, [openNav]);

  useEffect(
    () => {
      autoLogin();
      handlePathnameChange();
    },
    [location], // 위치가 변경되면 drawer를 닫아준다.
  );

  // return (
  //   <>
  //   {/* <TopNav onNavOpen={() => setOpenNav(true)} /> */}
  //     {/* {!lgUp && <TopNav onNavOpen={() => setOpenNav(true)} />} */}
  //     <SideNav onClose={() => setOpenNav(false)} open={openNav} lgUp={true} />
  //     <Box
  //       component={'div'}
  //       sx={{
  //         display: 'flex',
  //         maxWidth: '100%',
  //         pl:`${SIDE_NAV_WIDTH}px`,
  //         pt:`${TOP_NAV_HEIGHT}px`,
  //         width:`calc(100%-${SIDE_NAV_WIDTH}px)`,
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           width: '100%',
  //           height:`calc(100vh - ${TOP_NAV_HEIGHT}px)`
  //         }}
  //       >
  //         <Outlet />
  //       </Box>

  //     </Box>
  //   </>
  // );

  return (
    <>
      <Stack direction="row">
        <SideNav />
        <Box
          sx={{
            pl: `${SIDE_NAV_WIDTH}px`,
            width: '100%',
          }}
        >
          <Outlet />
        </Box>
        {/* 자동 로그아웃 모달창 */}
        <AutoLogoutModal />
      </Stack>
    </>
  );
};

export default Layout;
