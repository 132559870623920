import { useLocation } from 'react-router-dom';
import logo from '../../assets/image/menu_logo_onlu_img_color.png';
import {
  Box,
  Drawer,
  Stack,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import { items } from './config';
import SideNavItem from './SideNavItem';
import { useLogoutMutation } from '../../service/user/userHooks';
import LogoutIcon from '@mui/icons-material/Logout';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from '../../store';
import { SIDE_NAV_WIDTH } from '../../pages/Layout';

const SideNav = () => {
  const userInfo = useRecoilValue(UserInfoState);
  const logout = useLogoutMutation();
  const location = useLocation();
  const pathname = location.pathname.split('/')[1];

  const handleLogout = () => {
    logout();
  };

  const reload = () => {
    window.location.href = 'https://biddingeasy.co.kr';
  };

  const content = (
    <Stack
      justifyContent={'space-between'}
      sx={{ backgroundColor: 'white', height: '100%' }}
      alignItems={'center'}
    >
      <Box
        component="div"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 3,
        }}
      >
        <img
          src={logo}
          width="30px"
          alt="bidding_easy_logo"
          onClick={reload}
          style={{ cursor: 'pointer' }}
        />
        <Divider sx={{ p: 2, boxSizing: 'border-box' }} />
      </Box>
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 3,
        }}
      >
        <Stack
          component="ul"
          spacing={1}
          sx={{
            listStyle: 'none',
          }}
        >
          {items.map((item) => {
            const active = item.path ? pathname === item.path : false;
            if (item.path === 'admin' && !userInfo.IS_ADMIN) {
              return null;
            } else {
              return (
                <SideNavItem
                  active={active}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            }
          })}
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Tooltip
          title={'로그아웃'}
          placement="right"
          arrow={true}
          componentsProps={{
            tooltip: {
              sx: {
                padding: 1,
                fontSize: '14px',
              },
            },
          }}
        >
          <IconButton onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          width: SIDE_NAV_WIDTH,
        },
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  );

  // return (
  //   <Drawer
  //     anchor="left"
  //     onClose={onClose}
  //     open={open}
  //     PaperProps={{
  //       sx: {
  //         width: SIDE_NAV_WIDTH,
  //       },
  //     }}
  //     sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
  //     variant="temporary"
  //   >
  //     {content}
  //   </Drawer>
  // );

  // return <>{content}</>;
};

export default SideNav;
