import { URL } from '../../config/axios/axios-config';
import axios from 'axios';

export const verifyAPI = {
  //사용자의 휴대폰에 인증번호전송
  async SendVerificationNumber(phoneNumber: string) {
    return await axios.post(`${URL}/verify/phone-number`, { phoneNumber });
  },

  //인증번호가 맞는지 확인
  async checkVerificationNumber(
    phoneNumber: string,
    authenticationNumber: string,
  ) {
    return await axios.post(`${URL}/verify/authentication-number`, {
      phoneNumber,
      authenticationNumber,
    });
  },

  //아이디 중복 확인
  async checkUserIdAvailable(id: string) {
    return await axios.get(`${URL}/verify/userid/${id}`);
  },

  //임시 비밀번호 메일 발송
  async SendTempPasswordToEmail(id: string) {
    return await axios.post(`${URL}/verify/temp-password`, { id });
  },
};
