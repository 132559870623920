import { Box, ButtonBase, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
interface sideNavItemProps {
  active: boolean;
  icon: React.ReactNode;
  path: string;
  title?: string;
}

const SideNavItem: React.FC<sideNavItemProps> = (props) => {
  const { active = false, icon, path, title } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  const handlerClick = () => {
    navigate(path);
  };

  return (
    <li>
      <Tooltip title={title} placement="right" arrow={true}>
        <ButtonBase
          sx={{
            position: 'relative',
            alignItems: 'center',
            borderRadius: 1,
            p: 2,
            textAlign: 'center',
            width: '100%',
            backdropFilter: 'blur(20px)',

            ...(active && {
              backgroundColor: theme.palette.grey[200],
            }),
            '&:hover': {
              backgroundColor: theme.palette.grey[200],
            },
          }}
          onClick={handlerClick}
        >
          {icon && (
            <Box
              component="span"
              sx={{
                color: 'grey[200]',
                // ...(active && {
                //   color: 'primary.main',
                // }),
              }}
            >
              {icon}
            </Box>
          )}
        </ButtonBase>
      </Tooltip>
    </li>
  );
};

export default SideNavItem;
