import { Box, Card, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { PieChart } from 'react-minimal-pie-chart';
import { useTheme } from '@mui/material';
import { useKeywordUsage } from '../../service/user/userHooks';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const persent = (a: number, b: number) => {
  return Math.floor((a === 0 ? a : a / b) * 100);
};

const KeywordUsageGraph: React.FC = () => {
  const theme = useTheme();
  const { data, refetch, isLoading } = useKeywordUsage();
  
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.split('/')[1] === 'mypage') {
      refetch();
    }
  }, [pathname]);

  return (
    <>
      <Card
        sx={{
          height: '100%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* 타이틀 */}
          <Stack
            direction="row"
            sx={{ mb: 1, width: '100%' }}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: '20px', fontWeight: 'bold' }}
            >
              키워드
            </Typography>
            <Tooltip title={"새로고침"} arrow placement='top'>

            <IconButton
              onClick={() => {
                refetch();
              }}
              >
              <RefreshIcon />
            </IconButton>
              </Tooltip>
          </Stack>

          {isLoading && <CircularProgress></CircularProgress>}
          {!!data && (
            <>
              <Box sx={{ maxWidth: '130px', mb: 2 }}>
                <PieChart
                  data={[
                    {
                      value: persent(data.COUNT, data.CHECK_KEYWORD_COUNT),
                      color: theme.palette.primary.main,
                      name: '사용량',
                    },
                  ]}
                  reveal={persent(data.COUNT, data.CHECK_KEYWORD_COUNT)} // 퍼센트치수
                  lineWidth={28} // 도넛 두깨
                  background="#f3f3f3"
                  lengthAngle={360}
                  rounded
                  animate
                  startAngle={270}
                  label={({ dataEntry }) => dataEntry.value + '%'}
                  labelStyle={{
                    fontSize: '26px',
                    fill: '#333333',
                  }}
                  labelPosition={0}
                />
              </Box>
              <Stack direction="row" gap={1}>
                <Typography fontWeight={'bold'}>
                  {data.COUNT.toLocaleString()}{' '}
                </Typography>
                <Typography>
                  / {data.CHECK_KEYWORD_COUNT.toLocaleString()}
                </Typography>
              </Stack>
            </>
          )}
        </Box>
      </Card>
    </>
  );
};

export default KeywordUsageGraph;
