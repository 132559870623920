import {
  Box,
  useTheme,
  Typography,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Button,
  Input,
  Dialog,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { city_list, county_list, area_list } from '../../../assets/text/region';
import { customToast } from '../../../utils/customToast';
import {
  useUpdatePowerGroupStateMutation,
} from '../../../service/group/groupHooks';
import { GroupType } from '../../../service/group/type';


const MIN_TIMER = 10; // 최소 입찰 주기 websocket이 동작하는 주기와 동일함 

interface GroupSettingProps {
  data: GroupType;
  open: boolean;
  onClose: () => void;
}

const GroupSetting: React.FC<GroupSettingProps> = ({ data, open, onClose }) => {
  const theme = useTheme();
  const [city, setCity] = useState<string>('');
  const [county, setCounty] = useState<string>('');
  const [area, setArea] = useState<string>('');
  const [biddingIntervals, setBiddingIntervals] = useState<string>('');
  const [mode, setMode] = useState<string>('');

  const { mutateAsync } = useUpdatePowerGroupStateMutation();

  useEffect(() => {
    setCity(data.region[0]);
    setCounty(data.region[1]);
    setArea(data.region[2]);
    setBiddingIntervals(data.intervals);
    setMode(data.deviceCode);

  }, [data]);

  const handleBidCycleInSeconedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    //숫자가 아닌 다른게 들어오면 지워버리기
    let value = event.target.value as string;
    const isNumeric = (value: string): boolean => /^[0-9]+$/.test(value);
    if (value === '' || isNumeric(value)) {
      setBiddingIntervals(value);
    }
  };

  const handleTempModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode((event.target as HTMLInputElement).value);
  };

  const handleCityChange = (event: SelectChangeEvent) => {
    setCity(event.target.value as string);
    setCounty('');
    setArea('');
  };

  const handleCountyChange = (event: SelectChangeEvent) => {
    setCounty(event.target.value as string);
    setArea('');
  };

  const handleAreaChange = (event: SelectChangeEvent) => {
    setArea(event.target.value as string);
  };

  const handleSubmit = () => {
    if (mode === '') {
      customToast('warn', '광고 매체를 선택해 주세요');
    } else if (Number(biddingIntervals) < MIN_TIMER) {
      customToast('warn', `입찰 주기는 최소 ${MIN_TIMER}초 입니다. 다시 설정해 주세요`);
    } else if (!city) {
      customToast('warn', '지역을 설정해 주세요');
    } else if (!county) {
      customToast('warn', '지역을 설정해 주세요');
    } else if (!!area_list[county] && !area) {
      customToast('warn', '지역을 설정해 주세요');
    } else {
      mutateAsync({
        grpId: data.groupId,
        device: mode,
        intervals: biddingIntervals,
        region1: city,
        region2: county,
        region3: area,
      });
      onClose();
    }
  };
  return (
    <>
      <Dialog onClose={onClose} open={open}>
        <Box
          sx={{
            width: '600px',
            borderRadius: '8px',
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey[400]}`,
            p: 4,
          }}
        >
          <Typography variant="subtitle2" color="primary" sx={{ mb: 2 }}>
            {`${data.campaignName} > ${data.groupName}`}
          </Typography>
          <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
            그룹 설정
          </Typography>
          <Stack
            direction="column"
            sx={{
              width: '500px',
              margin: '0 auto',
            }}
            gap={7}
          >
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ mr: 3 }}>노출매체</FormLabel>
              <RadioGroup
                value={mode}
                sx={{ display: 'flex', flexDirection: 'row' }}
                onChange={handleTempModeChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="PC" />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="모바일"
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ mr: 3 }}>입찰 주기</FormLabel>
              <Input
                placeholder="초단위 입니다."
                onChange={handleBidCycleInSeconedChange}
                value={biddingIntervals}
                sx={{ width: '200px' }}
              />
              <Typography
                variant="subtitle2"
                sx={{ color: theme.palette.grey[600] }}
              >
                (초)
              </Typography>
            </FormControl>
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ mr: 3 }}>조회 지역</FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={city}
                onChange={handleCityChange}
                sx={{ width: '140px', mr: 1 }}
                size="medium"
              >
                {city_list.map((item, i) => (
                  <MenuItem value={item} key={i}>
                    {item}
                  </MenuItem>
                ))}
              </Select>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={county}
                onChange={handleCountyChange}
                sx={{ width: '130px', mr: 1 }}
                size="medium"
              >
                {!!county_list[city] &&
                  Array.isArray(county_list[city]) &&
                  county_list[city].map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>

              {!!area_list[`${city} ${county}`] &&
                area_list[`${city} ${county}`].length !== 0 && (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={area}
                    onChange={handleAreaChange}
                    sx={{ width: '130px' }}
                    size="medium"
                  >
                    {!!area_list[`${city} ${county}`] &&
                      Array.isArray(area_list[`${city} ${county}`]) &&
                      area_list[`${city} ${county}`].map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                )}
            </FormControl>
            <Stack
              direction="row"
              gap={2}
              justifyContent="end"
              sx={{ mb: 1, textAlign: 'right' }}
            >
              <Button variant="outlined" sx={{ width: '100px' }} onClick={onClose}>
                취소
              </Button>
              <Button
                variant="contained"
                sx={{ width: '100px' }}
                onClick={handleSubmit}
              >
                설정 완료
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default GroupSetting;
