import { customAxios } from '../../config/axios/axios-config';
import { ScheduleType } from './type';

export const groupAPI = {
  //DB에서 그룹리스트 가져오기
  async getPowerGroupList() {
    const API = '/power/group';

    return await customAxios.get(API);
  },

  //네이버랑 DB 동기화하기
  async syncPowerGroupList() {
    const API = '/power/group/sync';
    return await customAxios.post(API);
  },

  async powerGroupBiddingActiveState(grpId: string, isCheck: string) {
    const API = '/power/group/bidding';
    const body = {
      grp_id: grpId,
      is_check: isCheck,
    };
    return await customAxios.put(API, body);
  },

  //그룹설정 업데이트
  async updatePowerGroupState(
    grp_id: string,
    device: string,
    intervals: number,
    region1: string,
    region2: string,
    region3: string,
  ) {
    const API = '/power/group';
    const body = {
      grp_id,
      device,
      intervals,
      region1,
      region2,
      region3,
    };
    return await customAxios.put(API, body);
  },

  //그룹 스케쥴 설정
  async updateSchedule(data: ScheduleType) {
    const API = '/power/group/schedule';
    const body = {
      grp_id: data.groupId,
      schedule: data.schedule,
    };
    return await customAxios.put(API, body);
  },

  //개별 그룹정보 불러오기
  async getPowerGroup(groupId: string) {
    const API = `/power/group/spec/${groupId}`;
    return await customAxios.get(API);
  },
};
