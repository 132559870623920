import EditIcon from '@mui/icons-material/Edit';
import { useState, useMemo, useEffect } from 'react';
import {
  Box,
  MenuList,
  Menu,
  Stack,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  IconButton,
  Chip,
  Card,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { useNavigate } from 'react-router-dom';
import { UserInfoState, UserInfoStateType } from '../../store/userInfoState';
import { RegistNaverApiButton } from './RegistNaverApiButton';
import {
  useGetSubUserList,
  useUpdateBookmark,
} from '../../service/user/userHooks';
import { SubUserType } from '../../service/user/type';
import { userAPI } from '../../service/user/userAxios';
import { useQueryClient } from '@tanstack/react-query';
import { SelectedUserInfoState } from '../../store/selectedUserInfoState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { customToast } from '../../utils/customToast';

interface UserInfoProps {
  userInfo: UserInfoStateType;
}

//전화번호 포멧
const phoneNumberFormat = (value: string) => {
  value = value.slice(0, 3) + '-' + value.slice(3);
  value =
    value.slice(0, value.length - 4) + '-' + value.slice(value.length - 4);
  return value;
};

const UserInfo: React.FC<UserInfoProps> = ({ userInfo }) => {
  const navigate = useNavigate();
  const selectedUserInfo = useRecoilValue(SelectedUserInfoState);

  //잔여일 계산기
  const handleShowExpirationDate = () => {
    const expiration_date: Date = new Date(userInfo.EXPIRATION_DATE);
    const current_date: Date = new Date();
    const timeDiff: number = expiration_date.getTime() - current_date.getTime();
    
    const day = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return day <= 0 ? 0 : day;
  };
  const handleMoveUserEdit = () => {
    navigate('/user-info-edit');
  };
  return (
    <>
      <Card sx={{ height: '100%' }}>
        <Stack>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Typography variant="h5">회원정보</Typography>
            <Tooltip title={'회원정보 수정'} arrow placement="top">
              <IconButton onClick={handleMoveUserEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack gap={1}>
            {/* 요금제 , 이름, 아이디  */}
            <Stack direction={'row'} gap={1} alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="h5" fontWeight={'bold'}>
                {selectedUserInfo.CUSTOMER_NAME}
              </Typography>
              <UserList userId={userInfo.ID} />
              <Chip label={userInfo.PRICE_NAME} variant="outlined" />
            </Stack>

            {/* 이메일 */}
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography>이메일</Typography>
              <Typography>{userInfo.EMAIL} </Typography>
            </Stack>

            {/* 연락처 */}
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography>연락처</Typography>
              <Typography>
                {phoneNumberFormat(userInfo.PHONE_NUMBER)}
              </Typography>
            </Stack>
            {/* 잔여일 */}
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography>잔여일</Typography>
              <Typography>D - {handleShowExpirationDate()}</Typography>
            </Stack>
          </Stack>
          <Box sx={{ mt: 2 }}>
            <RegistNaverApiButton />
          </Box>
        </Stack>
      </Card>
    </>
  );
};

export default UserInfo;

interface UserListProps {
  userId: string;
}
const UserList: React.FC<UserListProps> = ({ userId }) => {
  const { data: subUserList, isLoading } = useGetSubUserList();
  const [users, setUsers] = useState<SubUserType[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();
  const { mutate: updateBookmark } = useUpdateBookmark();
  const userInfo = useRecoilValue(UserInfoState);
  const [selectedUserInfo, setSelectedUserInfo] = useRecoilState(
    SelectedUserInfoState,
  );

  const handleChangeUser = async (
    id: string,
    customerId: string,
    customerName: string,
    type: 'PARENT' | 'CHILDREN',
  ) => {
    try {
      await userAPI.getChangeUser(customerId).then(() => {
        //보여지는 정보 변경
        setSelectedUserInfo({
          ID: id, // rlaqjatr
          CUSTOMER_NAME: customerName, //김범식
          TYPE: type, // PARENT | CHILDREN
        });

        // 전부 초기화
        queryClient.invalidateQueries({ queryKey: [] });
      });
    } catch (e: any) {
      customToast('error', e.message);
    }
  };

  //북마크 변경
  const handleChangeBookmark = (customerId: string, bookmark: string) => {
    updateBookmark({ customerId, bookmark });
  };

  //메뉴 열기
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  //메뉴 닫기
  const handleClose = () => {
    setAnchorEl(null);
  };
  //검색
  const handleSearchId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.currentTarget.value);
  };

  useEffect(() => {
    if (subUserList) {
      setUsers(subUserList.CHILDREN.map((item) => item));
    }
  }, [subUserList]);

  //검색 알고리즘
  const sortedUsers = useMemo(() => {
    const searchQueryLowerCase = searchQuery.toLowerCase();
    return users
      .filter(
        (user) =>
          searchQueryLowerCase === '' ||
          user.MANAGER_NAME.toLowerCase().includes(searchQueryLowerCase) ||
          user.CLIENT_LOGIN_ID.toLowerCase().includes(searchQueryLowerCase),
      )
      .sort((a, b) => {
        if (a.BOOKMARK === 'Y' && b.BOOKMARK === 'N') return -1;
        if (a.BOOKMARK === 'N' && b.BOOKMARK === 'Y') return 1;
        return 0;
      });
  }, [searchQuery, users]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Tooltip title="걔정 정보변경" arrow placement="top">
        <Typography
          variant="h5"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={handleClick}
          color="primary"
        >
          {selectedUserInfo?.ID}
        </Typography>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 15,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <Stack sx={{ p: 2, pb: 0 }}>
          {/* 임시로 가져오는 내정보  */}
          <Typography variant="subtitle2">관리 계정</Typography>
          <Typography
          sx={{ cursor: 'pointer',"&:hover":{
            backgroundColor: 'rgba(0,0,0,0.1)',
          } }}
            variant="subtitle1"
            onClick={() => {
              handleChangeUser(
                userInfo.ID,
                userInfo.CUSTOMER_ID,
                userInfo.CUSTOMER_NAME,
                'PARENT',
              );
            }}
          >
            {subUserList?.PARENT.CUSTOMER_NAME} ({subUserList?.PARENT.ID})
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <TextField
            placeholder="광고주 ID를 입력하세요"
            onChange={handleSearchId}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <MenuList sx={{ overflowY: 'auto', maxHeight: '300px' }}>
            {sortedUsers.map((item, i) => (
              <MenuItem sx={{ height: '30px', width: '100%' }} key={i}>
                <ListItemIcon
                  onClick={() => {
                    handleChangeBookmark(
                      item.CLIENT_CUSTOMER_ID,
                      item.BOOKMARK === 'Y' ? 'N' : 'Y',
                    );
                  }}
                >
                  {item.BOOKMARK === 'Y' ? (
                    <StarIcon color="primary" fontSize="small" />
                  ) : (
                    <StarBorderOutlinedIcon fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText
                  onClick={() => {
                    handleChangeUser(
                      item.CLIENT_LOGIN_ID,
                      item.CLIENT_CUSTOMER_ID,
                      item.MANAGER_NAME,
                      'CHILDREN',
                    );
                  }}
                >
                 {item.MANAGER_NAME} ({item.CLIENT_LOGIN_ID})
                </ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Stack>
      </Menu>
    </>
  );
};
