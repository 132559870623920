export const QUERY_KEY = {
  campaigns: 'campaigns',
  groups: 'groups',
  user: 'user',
  keywords: 'keywords',
  login: 'login',
  signup: 'signup',
  info: 'info',
  customerInfoList:"customerInfoList",
  socketList:"socketList",
  graph: 'graph',
  bidCount : "bidCount",
  keywordUsage:"keywordUsage",
  subUserList:"subUserList",
  childrenBidCountList:"childrenBidCountList"
};
