import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { keywordAPI } from './keywordAxios';
import { QUERY_KEY } from '../queryKey';
import { bidData } from '../../components/bidDashBoard/bidTable/BidTable';
import { customToast } from '../../utils/customToast';
import {
  GraphDataType,
  KeywordDetailInfo,
  UpdateScheduleType,
  updateKeywordStateByKeywordIdProps,
} from './type';

export const makeKeywordActive = (
  time: string | null,
  status: string,
  errorHttpMessage: string | null | undefined,
  keywordStatusReason: string,
) => {
  let result = '';
  if (!!time) {
    result = 'success*' + time;
  } else if (status === 'ON') {
    const a = 'ON*' + status;
    const b = !!errorHttpMessage ? errorHttpMessage : '';
    result = a + '*' + b;
  } else {
    const a = 'OFF*' + status;
    const b = keywordStatusReason;
    result = a + '*' + b;
  }

  return result;
};

//그룹아이디로 키워드 리스트 불러오기
export const useGetKeywordListByGroupId = (groupId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.keywords, groupId],
    queryFn: () => keywordAPI.getKeywordListByGroupId(groupId),
    select: (data: any) => {
      const result: Map<string, bidData> = new Map<string, bidData>();
      data.data.forEach((item: any) => {
        data = {
          nccKeywordId: item.KEYWORD_ID,
          keyword: item.KEYWORD_NAME,
          current_ranking: !!item.CURRENT_RANK ? item.CURRENT_RANK : 0, // 현재순위
          target_ranking: !!item.TARGET_RANK ? item.TARGET_RANK : 15, // 목표순위
          current_bid_price: !!item.CURRENT_PRICE ? item.CURRENT_PRICE : 0, //현재입찰금액
          maximum_bid_price: !!item.MAX_BID_PRICE ? item.MAX_BID_PRICE : 0, // 최대입찰금액
          max_exposure: !!item.MAX_EXPOSURE_COUNT ? item.MAX_EXPOSURE_COUNT : 0, //최대노출수
          adjustment_price: !!item.ADD_SUBTRACT_PRICE
            ? item.ADD_SUBTRACT_PRICE
            : 0, //가감액
          is_bidding_active: item.IS_CHECK === 'Y' ? true : false, //입찰중 여부
          setting: true,
          keyword_sync_time: item.KEYWORD_SYNC_TIME,
          keyword_active: makeKeywordActive(
            item.KEYWORD_BID_COMPLETE_TIME,
            item.KEYWORD_ACTIVE,
            item.ERROR_HTTP_MESSAGE,
            item.KEYWORD_STATUS_REASON,
          ),
        };
        result.set(item.KEYWORD_ID, data);
      });
      return result;
    },
    retry: false,
  });
};

//키워드 하나의 값 가져오기
export const useGetKeywordByKeywordId = (keywordId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.keywords, keywordId],
    queryFn: () => keywordAPI.getKeywordByKeywordId(keywordId),
    select: (data: any) => {
      const result: KeywordDetailInfo = {
        keywordId: data.data.KEYWORD_ID,
        keywordName: data.data.KEYWORD_NAME,
        targetRank: !!data.data.TARGET_RANK ? data.data.TARGET_RANK : 15,
        currentPrice: !!data.data.CURRENT_PRICE ? data.data.CURRENT_PRICE : 0,
        maxBidPrice: !!data.data.MAX_BID_PRICE ? data.data.MAX_BID_PRICE : 0,
        addSubtractPrice: !!data.data.ADD_SUBTRACT_PRICE
          ? data.data.ADD_SUBTRACT_PRICE
          : 0,
        currentRank: !!data.data.CURRENT_RANK ? data.data.CURRENT_RANK : 0,
        maxexposure: !!data.data.MAX_EXPOSURE_COUNT
          ? data.data.MAX_EXPOSURE_COUNT
          : 0,
        isCheck: data.data.IS_CHECK,
        scheduleYN: data.data.SCHEDULE_YN,
        schedule: data.data.SCHEDULE,
      };
      return result;
    },
    retry: false,
  });
};

// 키워드 동기화
export const useSyncKeywordListByGroupId = (groupId: string) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error>({
    mutationFn: () => keywordAPI.syncKeywordListByGroupId(groupId),
    onSuccess: (data) => {
      customToast('success', data.data.message);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.keywords],
      });
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.keywords],
      });
      customToast(
        'error',
        '키워드 동기화중 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
      );
    },
  });
};

// 키워드 입찰 여부 변경
export const useUpdateKeywordActive = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, { keywordId: string; isCheck: string }>({
    mutationFn: ({ keywordId, isCheck }) =>
      keywordAPI.updateKeywordActiveByKeywordId(keywordId, isCheck),
    onSuccess: (data) => {
      // console.log('키워드 입찰 여부 변경');
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.keywords],
      });
      customToast(
        'error',
        '키워드 입찰중 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
      );
    },
  });
};

//키워드 상태 값 변경
export const useUpdateKeywordState = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, updateKeywordStateByKeywordIdProps>({
    mutationFn: (data: updateKeywordStateByKeywordIdProps) =>
      keywordAPI.updateKeywordStateByKeywordId(data),
    onSuccess: (data) => {},
    onError: () => {
      // queryClient.invalidateQueries({
      //   queryKey: [QUERY_KEY.keywords],
      // });
      customToast(
        'error',
        '키워드 입찰중 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
      );
    },
  });
};

//키워드 상태 값 변경
export const useUpdateKeywordSchedule = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, UpdateScheduleType>({
    mutationFn: (data: UpdateScheduleType) =>
      keywordAPI.updateKeywordTimeByKeywordId(data),
    onSuccess: (data) => {},
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.keywords],
      });
      customToast(
        'error',
        '스케쥴 변경중 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
      );
    },
  });
};

//그래프 불러오기 log 값 불러오기
export const useGetGraphData = (groupId: string, keywordId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.graph, groupId, keywordId],
    queryFn: () => keywordAPI.getKeywordGraphByKeywordId(groupId, keywordId),
    select: (data: any) => {
      const result: GraphDataType[] = [];
      data.data.forEach((item: any, index: number) => {
        result.push({
          keywordId: item.keyword_id,
          keywordName: item.keyword_name,
          targetRank: item.t_rank,
          rank: item.rank,
          maxPrice: item.maxPrice,
          price: item.price,
          time: dateFormatter(item.real_time),
        });
      });
      return result;
    },
    retry: false,
  });
};

export const useUpdateSelectedKeywordStateByKeywordId = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, { keywordIdList: string[]; isCheck: string }>({
    mutationFn: ({ keywordIdList, isCheck }) =>
      keywordAPI.updateSelectedKeywordStateByKeywordId(keywordIdList, isCheck),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.keywords],
      });
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.keywords],
      });
      customToast(
        'error',
        '키워드 변경중에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
      );
    },
  });
};

//날짜 포맷 변경
const dateFormatter = (date: string) => {
  const koreanDate = new Date(date);
  const year = koreanDate.getFullYear();
  const month = String(koreanDate.getMonth() + 1).padStart(2, '0');
  const day = String(koreanDate.getDate()).padStart(2, '0');
  const hours = String(koreanDate.getHours()).padStart(2, '0');
  const minutes = String(koreanDate.getMinutes()).padStart(2, '0');
  const seconds = String(koreanDate.getSeconds()).padStart(2, '0');

  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
