import { AtomEffect, atom } from 'recoil';
import LOCALSTORAGE_KEY from './localStageKey';


//localStorage 와 동기화 하는 함수
const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const RememberMeState = atom<boolean>({
  key: 'RememberMeState',
  default: false,
  effects: [localStorageEffect(LOCALSTORAGE_KEY.REMEMBER_ME)],
});
