import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Card,
  // Stack,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import Logo from '../assets/image/login_logo.png';
import { useLoginMutation } from '../service/user/userHooks';
import { useRecoilState } from 'recoil';
import { useAutoLoginMutation } from '../service/user/userHooks';
import { useEffect } from 'react';
import { RememberMeState } from '../store/index';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import SendIcon from '@mui/icons-material/Send';
import { customToast } from '../utils/customToast';
import { verifyAPI } from '../service/verify/verifyAxios';
import backgroundImage from '../assets/image/login_background.jpg';
// const client_id = process.env.REACT_APP_CLIENT_ID;
// const redirectURI = 'https://localhost:3000/callback';
// const { naver } = window;

const Login: React.FC = () => {
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [rememberMeState, setRememberMeState] = useRecoilState(RememberMeState);
  const { mutate: login } = useLoginMutation(id, pw);
  const autoLogin = useAutoLoginMutation();
  const theme = useTheme();
  const navigate = useNavigate();

  // 네이버 로그인
  // const initializeNaverLogin = () => {
  //   const naverLogin = new naver.LoginWithNaverId({
  //     clientId: client_id,
  //     callbackUrl: redirectURI,
  //     isPopup: false,
  //     loginButton: { color: 'green', type: 1, height: 50 },
  //     callbackHandle: true,
  //   });
  //   naverLogin.init();
  // };

  const handleMoveSignupPage = () => {
    navigate('/signup');
  };

  useEffect(() => {
    // 자동 로그인 실행
    autoLogin();
    // initializeNaverLogin();
  }, []);

  // 로그인정보 기억 여부 표시
  const handleRememberMe = () => {
    setRememberMeState(!rememberMeState);
  };

  const handleIdInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  };

  const handlePwInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPw(e.target.value);
  };

  //로그인 함수 실행
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login();
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        minHeight:"1000px",
        position: 'relative',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // 검은색 투명도 조절 가능
          backdropFilter: 'blur(8px)', // 흐림 효과
          zIndex: 1, // 배경 이미지 위에 요소를 쌓기 위해 필요
        }}
      >
        <Card
          sx={{
            top: '10%',
            left: '50%',
            position: 'absolute',
            transform: 'translateX(-50%)',
            width: '400px',
            backgroundColor: 'background.paper',
            boxShadow: theme.shadows,
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={Logo} width="300px" alt="로고" />

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3 }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="id"
                label="아이디"
                name="id"
                autoComplete="id"
                autoFocus
                onChange={handleIdInput}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="비밀번호"
                type="password"
                id="password"
                onChange={handlePwInput}
              />

              <FormControlLabel
                id="Remember_me"
                control={
                  <Checkbox
                    checked={rememberMeState}
                    onClick={handleRememberMe}
                    color="primary"
                  />
                }
                label="로그인 상태 유지"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 1, p: 2 }}
              >
                로그인
              </Button>

              <Button
                type="button"
                fullWidth
                variant="outlined"
                sx={{ mt: 1, mb: 2, p: 2 }}
                onClick={handleMoveSignupPage}
              >
                회원가입
              </Button>

              {/* 소셜 로그인  */}
              {/* <Stack justifyContent={'space-around'} direction={'row'}>
            <div id="naverIdLogin" />
          </Stack> */}

              <FinePassowrdButton />
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Login;

const FinePassowrdButton: React.FC = () => {
  const [open, setOpen] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [id, setId] = useState('');
  const [verificationNumber, setVerificationNumber] = useState('');
  const [verifyForm, setVerifyForm] = useState(false);
  const [isPhoneNumberVerify, setIsPhoneNumberVerify] = useState(false);
  const [email, setEmail] = useState('');
  const [countdown, setCountdown] = useState(300);
  const [sendTempPassword, setSendTempPassword] = useState(false);

  useEffect(() => {
    setPhoneNumber('');
    setId('');
    setVerificationNumber('');
    setVerifyForm(false);
    setIsPhoneNumberVerify(false);
    setEmail('');
    setCountdown(300);
    setSendTempPassword(false);
  }, [open]);

  const handleDialog = () => {
    setOpen((prev) => !prev);
  };

  //아이디 입력
  const handleIdInput = (event: ChangeEvent<HTMLInputElement>) => {
    setId(event.target.value);
  };

  //인증번호 입력
  const handleVerificationNumberInput = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setVerificationNumber(event.target.value);
  };

  //휴대폰 번호 입력
  const handlePhoneNumberInput = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPhoneNumberVerify(false);
    setPhoneNumber(event.target.value);
  };

  //countdown을 통해 남은시간 보여주기
  const showTimer = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    return `${minutes}분 ${seconds}초`;
  };

  //5분 타이머
  const startTimer = () => {
    let timerInterval: any;
    clearInterval(timerInterval);
    timerInterval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 0) {
          clearInterval(timerInterval);
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
  };

  //인증번호 전송 로직
  const handleSendVerificationNumber = () => {
    if (phoneNumber.length !== 11) {
      customToast('error', '잘못된 전화번호입니다. 다시 시도해주세요');
    } else {
      verifyAPI
        .SendVerificationNumber(phoneNumber)
        .then((data) => {
          if (data.data === '중복') {
            customToast('warn', '중복된 사용자입니다. 잠시만 기다려 주십시오');
          } else {
            customToast('success', '인증번호를 전송했습니다.');
            setVerifyForm(true); //form 보여주기
            setCountdown(300); // 시간 초기화
            startTimer();
            setVerificationNumber('');
          }
        })
        .catch((err) => {
          customToast(
            'error',
            '전화번호가 잘못되었습니다. 전화번호를 확인한 다음 다시 시도해주세요',
          );
        });
    }
  };

  //임시 비밀번호 전송
  const handleSendTempPasswordToEmail = () => {
    verifyAPI
      .SendTempPasswordToEmail(id)
      .then((res) => {
        customToast('info', '임시비밀번호를 이메일로 발송하였습니다.');
        setSendTempPassword(true);
        setEmail(res.data.email);
      })
      .catch((err) => {
        customToast('error', '아이디를 확인해 주세요.');
      });
  };

  //인증번호 확인로직
  const handleCheckVerificationNumber = () => {
    verifyAPI
      .checkVerificationNumber(phoneNumber, verificationNumber)
      .then(() => {
        customToast('success', '전화번호가 인증되었습니다.');
        setIsPhoneNumberVerify(true);
        setVerifyForm(false);
      })
      .catch(() => {
        customToast('success', '인증번호를 다시한번 확인해 주세요');
      });
  };

  return (
    <>
      <Button sx={{ textDecoration: 'underline' }} onClick={handleDialog}>
        비밀번호 찾기
      </Button>
      <Dialog
        open={open}
        // onClose={handleClose}

        sx={{ minWidth: '600px', margin: '0 auto' }}
      >
        <DialogTitle>임시 비밀번호 발급</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            휴대폰 인증을 통해 사전에 등록한 이메일로 임시 비밀번호를 발급해
            드립니다.
          </DialogContentText>

          {/* 아이디 */}
          <TextField
            onChange={handleIdInput}
            autoFocus
            margin="dense"
            id="id"
            label="아이디"
            type="text"
            fullWidth
            variant="outlined"
            value={id}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* 휴대폰 번호 */}
            <TextField
              onChange={handlePhoneNumberInput}
              margin="dense"
              id="phoneNumber"
              label="전화번호 ( '-' 없이 )"
              type="text"
              fullWidth
              variant="outlined"
              sx={{ mr: 1 }}
              value={phoneNumber}
            />
            {isPhoneNumberVerify ? (
              <Button
                variant="contained"
                disabled
                sx={{ width: '150px', height: '52px' }}
                onClick={handleSendVerificationNumber}
              >
                인증 완료
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ width: '150px', height: '52px' }}
                onClick={handleSendVerificationNumber}
              >
                인증번호 전송
              </Button>
            )}
          </Box>
          {verifyForm && (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {/* 인증번호 */}
                <TextField
                  onChange={handleVerificationNumberInput}
                  margin="dense"
                  id="phoneNumber"
                  label="인증번호"
                  type="text"
                  fullWidth
                  variant="outlined"
                  sx={{ mr: 1 }}
                  value={verificationNumber}
                />
                <Button
                  onClick={handleCheckVerificationNumber}
                  variant="contained"
                  sx={{ width: '130px', height: '50px' }}
                >
                  인증하기
                </Button>
              </Box>
              <Typography sx={{ ml: 1 }} variant="caption" color="error">
                {showTimer()}
              </Typography>
            </Box>
          )}
          {isPhoneNumberVerify && (
            <>
              {sendTempPassword ? (
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ height: '50px' }}
                  endIcon={<SendIcon />}
                  disabled
                >
                  임시 비밀번호 전송 완료
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ height: '50px' }}
                  endIcon={<SendIcon />}
                  onClick={handleSendTempPasswordToEmail}
                >
                  임시 비밀번호 전송
                </Button>
              )}
            </>
          )}

          {sendTempPassword && (
            <Box
              sx={{
                mt: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <MarkEmailReadOutlinedIcon color="primary" fontSize="large" />
              <Typography
                sx={{ mt: 1 }}
                variant="subtitle1"
              >{`[ ${email} ] 으로 임시 번호가 발송됐습니다.`}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
