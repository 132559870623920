import PersonIcon from '@mui/icons-material/Person';
import InsightsIcon from '@mui/icons-material/Insights';
// import AutoGraphIcon from '@mui/icons-material/AutoGraph';
// import ConstructionIcon from '@mui/icons-material/Construction';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const items = [
  {
    title: '마이페이지',
    path: 'mypage',
    icon: <PersonIcon />,
  },
  {
    title: '파워 링크',
    path: 'power-link',
    icon: <InsightsIcon />,
  },
  // {
  //   title: '파워 컨텐츠',
  //   path: 'power-content',
  //   icon: <AutoGraphIcon />,
  // },
  {
    title: '관리자 페이지',
    path: 'admin',
    icon: <ManageAccountsIcon />,
  },
  // {
  //   title: 'test',
  //   path: 'test',
  //   icon: <ConstructionIcon />,
  // },
];
