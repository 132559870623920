import { Grid, Box, Typography } from '@mui/material';
import DaliyBidCountGraph from '../components/mypage/DailyBidCountGraph';
import KeywordUsageGraph from '../components/mypage/KeywordUsageGraph';
import UserInfo from '../components/mypage/UserInfo';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from '../store';
import ChildrenBidCountTable from '../components/mypage/ChildrenBidCountTable';
import { SelectedUserInfoState } from '../store/selectedUserInfoState';

declare global {
  interface Window {
    Kakao: any;
  }
}

const KAKAO_JAVASCRIPT_KEY = process.env.REACT_APP_JAVASCRIPT_KEY;

const Mypage: React.FC = () => {
  const userInfo = useRecoilValue(UserInfoState);
  const selectedUserInfo = useRecoilValue(SelectedUserInfoState);

  useEffect(() => {
    try {
      if (window.Kakao) {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
          kakao.init(KAKAO_JAVASCRIPT_KEY);
        }
      }

      window.Kakao.Channel.createChatButton({
        container: '#kakao-talk-channel-chat-button',
        channelPublicId: '_bBxlxjb',
        title: 'consult',
        size: 'small',
        color: 'yellow',
        shape: 'pc',
        supportMultipleDensities: true,
      });
    } catch (err) {}
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ p: 4, mt: 5, maxWidth: '1400px', margin: '0 auto' }}
      >
        <Grid item xs={12}>
          <Typography
            id="tableTitle"
            component="div"
            sx={{ fontSize: '1.6rem', fontWeight: 'bold' }}
          >
            마이페이지
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <UserInfo userInfo={userInfo} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <KeywordUsageGraph />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DaliyBidCountGraph />
        </Grid>
        {selectedUserInfo.TYPE === 'PARENT' && false && (
          <Grid item xs={12}>
            <ChildrenBidCountTable />
          </Grid>
        )}
      </Grid>

      {/* 카카오 톡상담 버튼  */}
      <Box
        sx={{
          position: 'fixed',
          right: '50px',
          bottom: '50px',
          transition: '0.2s',
          '&:hover': {
            bottom: '55px',
          },
        }}
        id="kakao-talk-channel-chat-button"
      ></Box>
    </>
  );
};

export default Mypage;
