export type CityListType = (typeof city_list)[number];
export const city_list: string[] = [
  '서울특별시',
  '경기도',
  '인천광역시',
  '부산광역시',
  '광주광역시',
  '대전광역시',
  '대구광역시',
  '울산광역시',
  '세종특별자치시',
  '강원도',
  '경상남도',
  '경상북도',
  '전라남도',
  '전라북도',
  '충청남도',
  '충청북도',
  '제주특별자치도',
];

export type CountyListType = {
  [key: CityListType]: string[];
};
export const county_list: CountyListType = {
  서울특별시: [
    '종로구',
    '중구',
    '용산구',
    '성동구',
    '광진구',
    '동대문구',
    '중랑구',
    '성북구',
    '강북구',
    '도봉구',
    '노원구',
    '은평구',
    '서대문구',
    '마포구',
    '양천구',
    '강서구',
    '구로구',
    '금천구',
    '영등포구',
    '동작구',
    '관악구',
    '서초구',
    '강남구',
    '송파구',
    '강동구',
  ],
  경기도: [
    '수원시 장안구',
    '수원시 권선구',
    '수원시 팔달구',
    '수원시 영통구',
    '성남시 수정구',
    '성남시 중원구',
    '성남시 분당구',
    '의정부시',
    '안양시 만안구',
    '안양시 동안구',
    '부천시',
    '광명시',
    '평택시',
    '동두천시',
    '안산시 상록구',
    '안산시 단원구',
    '고양시 덕양구',
    '고양시 일산동구',
    '고양시 일산서구',
    '과천시',
    '구리시',
    '남양주시',
    '오산시',
    '시흥시',
    '군포시',
    '의왕시',
    '하남시',
    '용인시 처인구',
    '용인시 기흥구',
    '용인시 수지구',
    '파주시',
    '이천시',
    '안성시',
    '김포시',
    '화성시',
    '광주시',
    '양주시',
    '포천시',
    '여주시',
    '연천군',
    '가평군',
    '양평군',
  ],
  인천광역시: [
    '중구',
    '동구',
    '미추홀구',
    '연수구',
    '남동구',
    '부평구',
    '계양구',
    '서구',
    '강화군',
    '옹진군',
  ],
  부산광역시: [
    '중구',
    '서구',
    '동구',
    '영도구',
    '부산진구',
    '동래구',
    '남구',
    '북구',
    '해운대구',
    '사하구',
    '금정구',
    '강서구',
    '연제구',
    '수영구',
    '사상구',
    '기장군',
  ],
  광주광역시: ['동구', '서구', '남구', '북구', '광산구'],
  대전광역시: ['동구', '중구', '서구', '유성구', '대덕구'],
  대구광역시: [
    '중구',
    '동구',
    '서구',
    '남구',
    '북구',
    '수성구',
    '달서구',
    '달성군',
  ],
  울산광역시: ['중구', '남구', '동구', '북구', '울주군'],
  세종특별자치시: [
    '반곡동',
    '소담동',
    '보람동',
    '대평동',
    '가람동',
    '한솔동',
    '나성동',
    '새롬동',
    '다정동',
    '어진동',
    '종촌동',
    '고운동',
    '아름동',
    '도담동',
    '산울동',
    '해밀동',
    '합강동',
    '집현동',
    '조치원읍',
    '연기면',
    '연동면',
    '부강면',
    '금남면',
    '장군면',
    '연서면',
    '전의면',
    '전동면',
    '소정면',
  ],
  강원도: [
    '춘천시',
    '원주시',
    '강릉시',
    '동해시',
    '태백시',
    '속초시',
    '삼척시',
    '홍천군',
    '횡성군',
    '영월군',
    '평창군',
    '정선군',
    '철원군',
    '화천군',
    '양구군',
    '인제군',
    '고성군',
    '양양군',
  ],
  경상남도: [
    '창원시 의창구',
    '창원시 성산구',
    '창원시 마산합포구',
    '창원시 마산회원구',
    '창원시 진해구',
    '진주시',
    '통영시',
    '사천시',
    '김해시',
    '밀양시',
    '거제시',
    '양산시',
    '의령군',
    '함안군',
    '창녕군',
    '고성군',
    '남해군',
    '하동군',
    '산청군',
    '함양군',
    '거창군',
    '합천군',
  ],
  경상북도: [
    '포항시 남구',
    '포항시 북구',
    '경주시',
    '김천시',
    '안동시',
    '구미시',
    '영주시',
    '영천시',
    '상주시',
    '문경시',
    '경산시',
    '군위군',
    '의성군',
    '청송군',
    '영양군',
    '영덕군',
    '청도군',
    '고령군',
    '성주군',
    '칠곡군',
    '예천군',
    '봉화군',
    '울진군',
    '울릉군',
  ],
  전라남도: [
    '목포시',
    '여수시',
    '순천시',
    '나주시',
    '광양시',
    '담양군',
    '곡성군',
    '구례군',
    '고흥군',
    '보성군',
    '화순군',
    '장흥군',
    '강진군',
    '해남군',
    '영암군',
    '무안군',
    '함평군',
    '영광군',
    '장성군',
    '완도군',
    '진도군',
    '신안군',
  ],
  전라북도: [
    '전주시 완산구',
    '전주시 덕진구',
    '군산시',
    '익산시',
    '정읍시',
    '남원시',
    '김제시',
    '완주군',
    '진안군',
    '무주군',
    '장수군',
    '임실군',
    '순창군',
    '고창군',
    '부안군',
  ],
  충청남도: [
    '천안시 동남구',
    '천안시 서북구',
    '공주시',
    '보령시',
    '아산시',
    '서산시',
    '논산시',
    '계룡시',
    '당진시',
    '금산군',
    '부여군',
    '서천군',
    '청양군',
    '홍성군',
    '예산군',
    '태안군',
  ],
  충청북도: [
    '청주시 상당구',
    '청주시 서원구',
    '청주시 흥덕구',
    '청주시 청원구',
    '충주시',
    '제천시',
    '보은군',
    '옥천군',
    '영동군',
    '증평군',
    '진천군',
    '괴산군',
    '음성군',
    '단양군',
  ],
  제주특별자치도: ['제주시', '서귀포시'],
};

export type AreaListType = {
  [key: string]: string[];
};
export const area_list: AreaListType = {
  '경기도 수원시 권선구': [
    '세류동',
    '평동',
    '고색동',
    '오목천동',
    '평리동',
    '서둔동',
    '구운동',
    '탑동',
    '금곡동',
    '호매실동',
    '곡반정동',
    '권선동',
    '장지동',
    '대황교동',
    '입북동',
    '당수동',
  ],
  '세종특별자치시 소담동': [],
  '서울특별시 구로구': [
    '신도림동',
    '구로동',
    '가리봉동',
    '고척동',
    '개봉동',
    '오류동',
    '궁동',
    '온수동',
    '천왕동',
    '항동',
  ],
  '경기도 시흥시': [
    '대야동',
    '신천동',
    '방산동',
    '포동',
    '미산동',
    '은행동',
    '안현동',
    '매화동',
    '도창동',
    '금이동',
    '과림동',
    '계수동',
    '화정동',
    '능곡동',
    '하중동',
    '하상동',
    '광석동',
    '물왕동',
    '산현동',
    '조남동',
    '논곡동',
    '목감동',
    '거모동',
    '군자동',
    '장현동',
    '장곡동',
    '월곶동',
    '정왕동',
    '죽율동',
    '무지내동',
  ],
  '경상북도 경주시': [
    '동부동',
    '서부동',
    '북부동',
    '성동동',
    '황오동',
    '노동동',
    '노서동',
    '성건동',
    '사정동',
    '황남동',
    '교동',
    '인왕동',
    '탑동',
    '충효동',
    '서악동',
    '효현동',
    '광명동',
    '동방동',
    '도지동',
    '남산동',
    '배반동',
    '구황동',
    '보문동',
    '황성동',
    '용강동',
    '동천동',
    '평동',
    '조양동',
    '시동',
    '시래동',
    '구정동',
    '마동',
    '하동',
    '진현동',
    '천군동',
    '신평동',
    '덕동',
    '암곡동',
    '황용동',
    '북군동',
    '손곡동',
    '율동',
    '배동',
    '석장동',
    '감포읍',
    '안강읍',
    '건천읍',
    '외동읍',
    '양북면',
    '양남면',
    '내남면',
    '산내면',
    '서면',
    '현곡면',
    '강동면',
    '천북면',
  ],
  '경기도 안산시 상록구': [
    '일동',
    '이동',
    '사동',
    '본오동',
    '팔곡이동',
    '양상동',
    '부곡동',
    '성포동',
    '월피동',
    '팔곡일동',
    '건건동',
    '사사동',
    '수암동',
    '장상동',
    '장하동',
  ],
  '충청북도 음성군': [
    '음성읍',
    '금왕읍',
    '소이면',
    '원남면',
    '맹동면',
    '대소면',
    '삼성면',
    '생극면',
    '감곡면',
  ],
  '전라남도 화순군': [
    '화순읍',
    '한천면',
    '춘양면',
    '청풍면',
    '이양면',
    '능주면',
    '도곡면',
    '도암면',
    '이서면',
    '백아면',
    '동복면',
    '사평면',
    '동면',
  ],
  '서울특별시 중구': [
    '무교동',
    '다동',
    '태평로1가',
    '을지로1가',
    '을지로2가',
    '남대문로1가',
    '삼각동',
    '수하동',
    '장교동',
    '수표동',
    '소공동',
    '남창동',
    '북창동',
    '태평로2가',
    '남대문로2가',
    '남대문로3가',
    '남대문로4가',
    '남대문로5가',
    '봉래동1가',
    '봉래동2가',
    '회현동1가',
    '회현동2가',
    '회현동3가',
    '충무로1가',
    '충무로2가',
    '명동1가',
    '명동2가',
    '남산동1가',
    '남산동2가',
    '남산동3가',
    '저동1가',
    '충무로4가',
    '충무로5가',
    '인현동2가',
    '예관동',
    '묵정동',
    '필동1가',
    '필동2가',
    '필동3가',
    '남학동',
    '주자동',
    '예장동',
    '장충동1가',
    '장충동2가',
    '광희동1가',
    '광희동2가',
    '쌍림동',
    '을지로6가',
    '을지로7가',
    '을지로4가',
    '을지로5가',
    '주교동',
    '방산동',
    '오장동',
    '을지로3가',
    '입정동',
    '산림동',
    '충무로3가',
    '초동',
    '인현동1가',
    '저동2가',
    '신당동',
    '흥인동',
    '무학동',
    '황학동',
    '서소문동',
    '정동',
    '순화동',
    '의주로1가',
    '충정로1가',
    '중림동',
    '의주로2가',
    '만리동1가',
    '만리동2가',
  ],
  '충청남도 홍성군': [
    '홍성읍',
    '광천읍',
    '홍북읍',
    '금마면',
    '홍동면',
    '장곡면',
    '은하면',
    '결성면',
    '서부면',
    '갈산면',
    '구항면',
  ],
  '대구광역시 달성군': [
    '화원읍',
    '논공읍',
    '다사읍',
    '유가읍',
    '옥포읍',
    '현풍읍',
    '가창면',
    '하빈면',
    '구지면',
  ],
  '서울특별시 종로구': [
    '청운동',
    '신교동',
    '궁정동',
    '효자동',
    '창성동',
    '통의동',
    '적선동',
    '통인동',
    '누상동',
    '누하동',
    '옥인동',
    '체부동',
    '필운동',
    '내자동',
    '사직동',
    '도렴동',
    '당주동',
    '내수동',
    '세종로',
    '신문로1가',
    '신문로2가',
    '청진동',
    '서린동',
    '수송동',
    '중학동',
    '종로1가',
    '공평동',
    '관훈동',
    '견지동',
    '와룡동',
    '권농동',
    '운니동',
    '익선동',
    '경운동',
    '관철동',
    '인사동',
    '낙원동',
    '종로2가',
    '팔판동',
    '삼청동',
    '안국동',
    '소격동',
    '화동',
    '사간동',
    '송현동',
    '가회동',
    '재동',
    '계동',
    '원서동',
    '훈정동',
    '묘동',
    '봉익동',
    '돈의동',
    '장사동',
    '관수동',
    '종로3가',
    '인의동',
    '예지동',
    '원남동',
    '연지동',
    '종로4가',
    '효제동',
    '종로5가',
    '종로6가',
    '이화동',
    '연건동',
    '충신동',
    '동숭동',
    '혜화동',
    '명륜1가',
    '명륜2가',
    '명륜4가',
    '명륜3가',
    '창신동',
    '숭인동',
    '교남동',
    '평동',
    '송월동',
    '홍파동',
    '교북동',
    '행촌동',
    '구기동',
    '평창동',
    '부암동',
    '홍지동',
    '신영동',
    '무악동',
  ],
  '경기도 평택시': [
    '서정동',
    '장당동',
    '모곡동',
    '칠괴동',
    '칠원동',
    '도일동',
    '가재동',
    '장안동',
    '이충동',
    '지산동',
    '독곡동',
    '신장동',
    '평택동',
    '통복동',
    '군문동',
    '유천동',
    '합정동',
    '비전동',
    '동삭동',
    '세교동',
    '지제동',
    '신대동',
    '소사동',
    '용이동',
    '월곡동',
    '청룡동',
    '죽백동',
    '팽성읍',
    '안중읍',
    '포승읍',
    '청북읍',
    '진위면',
    '서탄면',
    '고덕면',
    '오성면',
    '현덕면',
  ],
  '경상북도 청도군': [
    '화양읍',
    '청도읍',
    '각남면',
    '풍각면',
    '각북면',
    '이서면',
    '운문면',
    '금천면',
    '매전면',
  ],
  '세종특별자치시 도담동': [],
  '서울특별시 노원구': ['월계동', '공릉동', '하계동', '상계동', '중계동'],
  '충청북도 충주시': [
    '성내동',
    '성남동',
    '성서동',
    '충인동',
    '교현동',
    '용산동',
    '호암동',
    '직동',
    '단월동',
    '풍동',
    '가주동',
    '용관동',
    '용두동',
    '달천동',
    '봉방동',
    '칠금동',
    '연수동',
    '목행동',
    '용탄동',
    '종민동',
    '안림동',
    '목벌동',
    '충의동',
    '지현동',
    '문화동',
    '금릉동',
    '주덕읍',
    '살미면',
    '수안보면',
    '대소원면',
    '신니면',
    '노은면',
    '앙성면',
    '중앙탑면',
    '금가면',
    '동량면',
    '산척면',
    '엄정면',
    '소태면',
  ],
  '충청남도 서산시': [
    '읍내동',
    '동문동',
    '갈산동',
    '온석동',
    '잠홍동',
    '수석동',
    '석림동',
    '석남동',
    '예천동',
    '죽성동',
    '양대동',
    '오남동',
    '장동',
    '덕지천동',
    '대산읍',
    '인지면',
    '부석면',
    '팔봉면',
    '지곡면',
    '성연면',
    '음암면',
    '운산면',
    '해미면',
    '고북면',
  ],
  '충청북도 영동군': [
    '영동읍',
    '용산면',
    '황간면',
    '추풍령면',
    '매곡면',
    '상촌면',
    '양강면',
    '용화면',
    '학산면',
    '양산면',
    '심천면',
  ],
  '서울특별시 도봉구': ['쌍문동', '방학동', '창동', '도봉동'],
  '부산광역시 서구': [
    '동대신동1가',
    '동대신동2가',
    '동대신동3가',
    '서대신동1가',
    '서대신동2가',
    '서대신동3가',
    '부용동1가',
    '부용동2가',
    '부민동1가',
    '부민동2가',
    '부민동3가',
    '토성동1가',
    '토성동2가',
    '토성동3가',
    '아미동1가',
    '아미동2가',
    '토성동4가',
    '토성동5가',
    '초장동',
    '충무동1가',
    '충무동2가',
    '충무동3가',
    '남부민동',
    '암남동',
  ],
  '경기도 고양시 일산동구': [
    '식사동',
    '중산동',
    '정발산동',
    '장항동',
    '마두동',
    '백석동',
    '풍동',
    '산황동',
    '사리현동',
    '지영동',
    '설문동',
    '문봉동',
    '성석동',
  ],
  '경기도 성남시 수정구': [
    '신흥동',
    '태평동',
    '수진동',
    '단대동',
    '산성동',
    '양지동',
    '복정동',
    '창곡동',
    '신촌동',
    '오야동',
    '심곡동',
    '고등동',
    '상적동',
    '둔전동',
    '시흥동',
    '금토동',
    '사송동',
  ],
  '경기도 동두천시': [
    '송내동',
    '지행동',
    '생연동',
    '광암동',
    '걸산동',
    '보산동',
    '동두천동',
    '안흥동',
    '상봉암동',
    '하봉암동',
    '탑동동',
    '상패동',
  ],
  '경기도 안산시 단원구': [
    '고잔동',
    '와동',
    '신길동',
    '성곡동',
    '원시동',
    '목내동',
    '초지동',
    '원곡동',
    '선부동',
    '대부동동',
    '대부북동',
    '대부남동',
    '선감동',
    '풍도동',
    '화정동',
  ],
  '경상북도 구미시': [
    '원평동',
    '지산동',
    '도량동',
    '봉곡동',
    '부곡동',
    '선기동',
    '수점동',
    '남통동',
    '형곡동',
    '송정동',
    '신평동',
    '비산동',
    '공단동',
    '광평동',
    '사곡동',
    '상모동',
    '임은동',
    '오태동',
    '신동',
    '구평동',
    '황상동',
    '인의동',
    '진평동',
    '시미동',
    '임수동',
    '양호동',
    '거의동',
    '옥계동',
    '구포동',
    '금전동',
    '선산읍',
    '고아읍',
    '무을면',
    '옥성면',
    '도개면',
    '해평면',
    '산동면',
    '장천면',
  ],
  '전라남도 영광군': [
    '영광읍',
    '백수읍',
    '홍농읍',
    '대마면',
    '묘량면',
    '불갑면',
    '군서면',
    '군남면',
    '염산면',
    '법성면',
    '낙월면',
  ],
  '경상남도 양산시': [
    '다방동',
    '남부동',
    '중부동',
    '북부동',
    '명곡동',
    '신기동',
    '북정동',
    '산막동',
    '호계동',
    '교동',
    '유산동',
    '어곡동',
    '용당동',
    '삼호동',
    '명동',
    '주남동',
    '소주동',
    '주진동',
    '평산동',
    '덕계동',
    '매곡동',
    '물금읍',
    '동면',
    '원동면',
    '상북면',
    '하북면',
  ],
  '인천광역시 강화군': [
    '강화읍',
    '선원면',
    '불은면',
    '길상면',
    '화도면',
    '양도면',
    '내가면',
    '하점면',
    '양사면',
    '송해면',
    '교동면',
    '삼산면',
    '서도면',
  ],
  '경기도 광명시': [
    '광명동',
    '철산동',
    '하안동',
    '소하동',
    '노온사동',
    '일직동',
    '가학동',
    '옥길동',
  ],
  '경상북도 봉화군': [
    '봉화읍',
    '물야면',
    '봉성면',
    '법전면',
    '춘양면',
    '소천면',
    '재산면',
    '명호면',
    '상운면',
    '석포면',
  ],
  '경기도 용인시 수지구': [
    '풍덕천동',
    '죽전동',
    '동천동',
    '고기동',
    '신봉동',
    '성복동',
    '상현동',
  ],
  '인천광역시 동구': [
    '만석동',
    '화수동',
    '송현동',
    '화평동',
    '창영동',
    '금곡동',
    '송림동',
  ],
  '경기도 과천시': [
    '관문동',
    '문원동',
    '갈현동',
    '막계동',
    '과천동',
    '주암동',
    '중앙동',
    '원문동',
    '별양동',
    '부림동',
  ],
  '경기도 의왕시': [
    '고천동',
    '이동',
    '삼동',
    '왕곡동',
    '오전동',
    '학의동',
    '내손동',
    '청계동',
    '포일동',
    '월암동',
    '초평동',
  ],
  '부산광역시 동래구': [
    '명장동',
    '안락동',
    '칠산동',
    '낙민동',
    '복천동',
    '수안동',
    '명륜동',
    '온천동',
    '사직동',
  ],
  '경상북도 칠곡군': [
    '왜관읍',
    '북삼읍',
    '석적읍',
    '지천면',
    '동명면',
    '가산면',
    '약목면',
    '기산면',
  ],
  '광주광역시 북구': [
    '중흥동',
    '유동',
    '누문동',
    '북동',
    '임동',
    '신안동',
    '용봉동',
    '동림동',
    '운암동',
    '우산동',
    '풍향동',
    '문흥동',
    '각화동',
    '두암동',
    '오치동',
    '삼각동',
    '매곡동',
    '충효동',
    '덕의동',
    '망월동',
    '청풍동',
    '화암동',
    '장등동',
    '운정동',
    '본촌동',
    '일곡동',
    '양산동',
    '연제동',
    '신용동',
    '용두동',
    '지야동',
    '태령동',
    '수곡동',
    '효령동',
    '용전동',
    '용강동',
    '생용동',
    '월출동',
    '대촌동',
    '오룡동',
  ],
  '세종특별자치시 고운동': [],
  '경기도 고양시 덕양구': [
    '주교동',
    '원당동',
    '신원동',
    '원흥동',
    '도내동',
    '성사동',
    '북한동',
    '효자동',
    '지축동',
    '오금동',
    '삼송동',
    '동산동',
    '용두동',
    '벽제동',
    '선유동',
    '고양동',
    '대자동',
    '관산동',
    '내유동',
    '토당동',
    '내곡동',
    '대장동',
    '화정동',
    '강매동',
    '행주내동',
    '행주외동',
    '신평동',
    '행신동',
    '화전동',
    '현천동',
    '덕은동',
    '향동동',
  ],
  '세종특별자치시 금남면': [],
  '전라남도 목포시': [
    '용당동',
    '산정동',
    '연산동',
    '대성동',
    '양동',
    '북교동',
    '남교동',
    '호남동',
    '대안동',
    '창평동',
    '명륜동',
    '죽동',
    '무안동',
    '측후동',
    '상락동1가',
    '상락동2가',
    '복만동',
    '동명동',
    '광동1가',
    '광동2가',
    '광동3가',
    '영해동1가',
    '영해동2가',
    '행복동1가',
    '행복동2가',
    '축복동1가',
    '축복동2가',
    '축복동3가',
    '보광동1가',
    '보광동2가',
    '보광동3가',
    '유달동',
    '대의동1가',
    '대의동2가',
    '대의동3가',
    '중앙동1가',
    '중앙동2가',
    '중앙동3가',
    '만호동',
    '수강동1가',
    '수강동2가',
    '해안동1가',
    '해안동2가',
    '해안동3가',
    '해안동4가',
    '항동',
    '중동1가',
    '중동2가',
    '유동',
    '금동1가',
    '금동2가',
    '경동1가',
    '경동2가',
    '서산동',
    '금화동',
    '온금동',
    '죽교동',
    '상동',
    '용해동',
    '석현동',
    '달동',
    '율도동',
    '대양동',
    '옥암동',
  ],
  '경상북도 상주시': [
    '성하동',
    '성동동',
    '인봉동',
    '복룡동',
    '냉림동',
    '서성동',
    '남성동',
    '서문동',
    '무양동',
    '낙양동',
    '개운동',
    '신봉동',
    '가장동',
    '양촌동',
    '지천동',
    '오대동',
    '흥각동',
    '거동동',
    '인평동',
    '서곡동',
    '화개동',
    '외답동',
    '헌신동',
    '병성동',
    '도남동',
    '낙상동',
    '중덕동',
    '초산동',
    '화산동',
    '계산동',
    '부원동',
    '죽전동',
    '만산동',
    '연원동',
    '남장동',
    '남적동',
    '함창읍',
    '중동면',
    '사벌국면',
    '낙동면',
    '청리면',
    '공성면',
    '외남면',
    '내서면',
    '모동면',
    '모서면',
    '화동면',
    '화서면',
    '화북면',
    '외서면',
    '은척면',
    '공검면',
    '이안면',
    '화남면',
  ],
  '인천광역시 부평구': [
    '부평동',
    '십정동',
    '산곡동',
    '청천동',
    '삼산동',
    '갈산동',
    '부개동',
    '일신동',
    '구산동',
  ],
  '강원도 원주시': [
    '중앙동',
    '평원동',
    '원동',
    '인동',
    '개운동',
    '명륜동',
    '단구동',
    '일산동',
    '학성동',
    '단계동',
    '우산동',
    '태장동',
    '봉산동',
    '행구동',
    '무실동',
    '관설동',
    '반곡동',
    '가현동',
    '문막읍',
    '소초면',
    '호저면',
    '지정면',
    '부론면',
    '귀래면',
    '흥업면',
    '판부면',
    '신림면',
  ],
  '전라북도 순창군': [
    '순창읍',
    '인계면',
    '동계면',
    '풍산면',
    '금과면',
    '팔덕면',
    '쌍치면',
    '복흥면',
    '적성면',
    '유등면',
    '구림면',
  ],
  '경상북도 영양군': [
    '영양읍',
    '입암면',
    '청기면',
    '일월면',
    '수비면',
    '석보면',
  ],
  '강원도 태백시': [
    '황지동',
    '장성동',
    '금천동',
    '철암동',
    '문곡동',
    '동점동',
    '소도동',
    '혈동',
    '화전동',
    '적각동',
    '창죽동',
    '통동',
    '백산동',
    '원동',
    '상사미동',
    '하사미동',
    '조탄동',
  ],
  '세종특별자치시 집현동': [],
  '충청남도 태안군': [
    '태안읍',
    '안면읍',
    '고남면',
    '남면',
    '근흥면',
    '소원면',
    '원북면',
    '이원면',
  ],
  '부산광역시 중구': [
    '영주동',
    '대창동1가',
    '대창동2가',
    '중앙동1가',
    '중앙동2가',
    '중앙동3가',
    '중앙동4가',
    '중앙동5가',
    '중앙동6가',
    '중앙동7가',
    '동광동1가',
    '동광동2가',
    '동광동3가',
    '동광동4가',
    '동광동5가',
    '대청동1가',
    '대청동2가',
    '대청동3가',
    '대청동4가',
    '보수동1가',
    '보수동2가',
    '보수동3가',
    '부평동1가',
    '부평동2가',
    '부평동3가',
    '부평동4가',
    '신창동1가',
    '신창동2가',
    '신창동3가',
    '신창동4가',
    '창선동1가',
    '창선동2가',
    '광복동1가',
    '광복동2가',
    '광복동3가',
    '남포동1가',
    '남포동2가',
    '남포동3가',
    '남포동4가',
    '남포동5가',
    '남포동6가',
  ],
  '경상북도 안동시': [
    '삼산동',
    '서부동',
    '북문동',
    '명륜동',
    '신안동',
    '율세동',
    '옥정동',
    '신세동',
    '법흥동',
    '용상동',
    '동문동',
    '동부동',
    '운흥동',
    '천리동',
    '남부동',
    '남문동',
    '안흥동',
    '대석동',
    '옥야동',
    '광석동',
    '당북동',
    '태화동',
    '화성동',
    '목성동',
    '법상동',
    '금곡동',
    '평화동',
    '안기동',
    '운안동',
    '성곡동',
    '상아동',
    '안막동',
    '옥동',
    '이천동',
    '노하동',
    '송현동',
    '송천동',
    '석동동',
    '정상동',
    '정하동',
    '수상동',
    '수하동',
    '풍산읍',
    '와룡면',
    '북후면',
    '서후면',
    '풍천면',
    '일직면',
    '남후면',
    '남선면',
    '임하면',
    '길안면',
    '임동면',
    '예안면',
    '도산면',
    '녹전면',
  ],
  '경기도 의정부시': [
    '의정부동',
    '호원동',
    '장암동',
    '신곡동',
    '용현동',
    '민락동',
    '낙양동',
    '자일동',
    '금오동',
    '가능동',
    '녹양동',
    '고산동',
    '산곡동',
  ],
  '세종특별자치시 대평동': [],
  '강원도 홍천군': [
    '홍천읍',
    '화촌면',
    '두촌면',
    '내촌면',
    '서석면',
    '동면',
    '남면',
    '서면',
    '북방면',
    '내면',
  ],
  '광주광역시 남구': [
    '사동',
    '구동',
    '서동',
    '월산동',
    '백운동',
    '주월동',
    '노대동',
    '진월동',
    '덕남동',
    '행암동',
    '임암동',
    '송하동',
    '양림동',
    '방림동',
    '봉선동',
    '구소동',
    '양촌동',
    '도금동',
    '승촌동',
    '지석동',
    '압촌동',
    '화장동',
    '칠석동',
    '석정동',
    '신장동',
    '양과동',
    '이장동',
    '대지동',
    '원산동',
    '월성동',
  ],
  '인천광역시 연수구': [
    '옥련동',
    '선학동',
    '연수동',
    '청학동',
    '동춘동',
    '송도동',
  ],
  '경상북도 영덕군': [
    '영덕읍',
    '강구면',
    '남정면',
    '달산면',
    '지품면',
    '축산면',
    '영해면',
    '병곡면',
    '창수면',
  ],
  '경상남도 합천군': [
    '합천읍',
    '봉산면',
    '묘산면',
    '가야면',
    '야로면',
    '율곡면',
    '초계면',
    '쌍책면',
    '덕곡면',
    '청덕면',
    '적중면',
    '대양면',
    '쌍백면',
    '삼가면',
    '가회면',
    '대병면',
    '용주면',
  ],
  '부산광역시 연제구': ['거제동', '연산동'],
  '광주광역시 동구': [
    '대인동',
    '금남로5가',
    '충장로5가',
    '수기동',
    '대의동',
    '궁동',
    '장동',
    '동명동',
    '계림동',
    '산수동',
    '지산동',
    '남동',
    '광산동',
    '금동',
    '호남동',
    '황금동',
    '서석동',
    '소태동',
    '용연동',
    '운림동',
    '학동',
    '월남동',
    '선교동',
    '내남동',
    '용산동',
    '충장로1가',
    '충장로2가',
    '충장로3가',
    '충장로4가',
    '금남로1가',
    '금남로2가',
    '금남로3가',
    '금남로4가',
  ],
  '전라북도 군산시': [
    '해망동',
    '신흥동',
    '금동',
    '월명동',
    '신창동',
    '오룡동',
    '금광동',
    '신풍동',
    '송풍동',
    '문화동',
    '삼학동',
    '선양동',
    '둔율동',
    '창성동',
    '명산동',
    '송창동',
    '개복동',
    '중앙로1가',
    '영화동',
    '장미동',
    '중앙로2가',
    '영동',
    '신영동',
    '죽성동',
    '평화동',
    '중앙로3가',
    '대명동',
    '장재동',
    '미원동',
    '중동',
    '금암동',
    '동흥남동',
    '서흥남동',
    '조촌동',
    '경장동',
    '경암동',
    '구암동',
    '내흥동',
    '개정동',
    '사정동',
    '수송동',
    '미장동',
    '지곡동',
    '나운동',
    '미룡동',
    '소룡동',
    '오식도동',
    '비응도동',
    '신관동',
    '개사동',
    '산북동',
    '내초동',
    '옥구읍',
    '옥산면',
    '회현면',
    '임피면',
    '서수면',
    '대야면',
    '개정면',
    '성산면',
    '나포면',
    '옥도면',
    '옥서면',
  ],
  '전라남도 진도군': [
    '진도읍',
    '군내면',
    '고군면',
    '의신면',
    '임회면',
    '지산면',
    '조도면',
  ],
  '서울특별시 용산구': [
    '후암동',
    '용산동2가',
    '용산동4가',
    '갈월동',
    '남영동',
    '용산동1가',
    '동자동',
    '서계동',
    '청파동1가',
    '청파동2가',
    '청파동3가',
    '원효로1가',
    '원효로2가',
    '신창동',
    '산천동',
    '청암동',
    '원효로3가',
    '원효로4가',
    '효창동',
    '도원동',
    '용문동',
    '문배동',
    '신계동',
    '한강로1가',
    '한강로2가',
    '용산동3가',
    '용산동5가',
    '한강로3가',
    '이촌동',
    '이태원동',
    '한남동',
    '동빙고동',
    '서빙고동',
    '주성동',
    '용산동6가',
    '보광동',
  ],
  '경상남도 하동군': [
    '하동읍',
    '화개면',
    '악양면',
    '적량면',
    '횡천면',
    '고전면',
    '금남면',
    '진교면',
    '양보면',
    '북천면',
    '청암면',
    '옥종면',
    '금성면',
  ],
  '강원도 정선군': [
    '정선읍',
    '고한읍',
    '사북읍',
    '신동읍',
    '남면',
    '북평면',
    '임계면',
    '화암면',
    '여량면',
  ],
  '서울특별시 마포구': [
    '아현동',
    '공덕동',
    '신공덕동',
    '도화동',
    '용강동',
    '토정동',
    '마포동',
    '대흥동',
    '염리동',
    '노고산동',
    '신수동',
    '현석동',
    '구수동',
    '창전동',
    '상수동',
    '하중동',
    '신정동',
    '당인동',
    '서교동',
    '동교동',
    '합정동',
    '망원동',
    '연남동',
    '성산동',
    '중동',
    '상암동',
  ],
  '강원도 화천군': ['화천읍', '간동면', '하남면', '상서면', '사내면'],
  '충청남도 금산군': [
    '금산읍',
    '금성면',
    '제원면',
    '부리면',
    '군북면',
    '남일면',
    '남이면',
    '진산면',
    '복수면',
    '추부면',
  ],
  '전라남도 나주시': [
    '토계동',
    '송월동',
    '안창동',
    '삼영동',
    '교동',
    '서내동',
    '산정동',
    '경현동',
    '보산동',
    '금계동',
    '금성동',
    '남내동',
    '과원동',
    '성북동',
    '중앙동',
    '대호동',
    '송촌동',
    '석현동',
    '청동',
    '남외동',
    '죽림동',
    '삼도동',
    '영산동',
    '용산동',
    '관정동',
    '평산동',
    '부덕동',
    '이창동',
    '대기동',
    '운곡동',
    '동수동',
    '오량동',
    '진포동',
    '빛가람동',
    '남평읍',
    '세지면',
    '왕곡면',
    '반남면',
    '공산면',
    '동강면',
    '다시면',
    '문평면',
    '노안면',
    '금천면',
    '산포면',
    '다도면',
    '봉황면',
  ],
  '경상남도 함양군': [
    '함양읍',
    '마천면',
    '휴천면',
    '유림면',
    '수동면',
    '지곡면',
    '안의면',
    '서하면',
    '서상면',
    '백전면',
    '병곡면',
  ],
  '충청북도 청주시 청원구': [
    '우암동',
    '내덕동',
    '율량동',
    '사천동',
    '주성동',
    '주중동',
    '정상동',
    '정하동',
    '정북동',
    '오동동',
    '외남동',
    '외평동',
    '외하동',
    '내수읍',
    '오창읍',
    '북이면',
  ],
  '경상남도 창원시 마산합포구': [
    '가포동',
    '교방동',
    '교원동',
    '남성동',
    '대내동',
    '대성동1가',
    '대성동2가',
    '대외동',
    '대창동',
    '덕동동',
    '동성동',
    '두월동1가',
    '두월동2가',
    '두월동3가',
    '문화동',
    '반월동',
    '부림동',
    '산호동',
    '상남동',
    '서성동',
    '성호동',
    '수성동',
    '신월동',
    '신창동',
    '신포동1가',
    '신포동2가',
    '신흥동',
    '완월동',
    '월남동1가',
    '월남동2가',
    '월남동3가',
    '월남동4가',
    '월남동5가',
    '월영동',
    '월포동',
    '예곡동',
    '오동동',
    '우산동',
    '유록동',
    '자산동',
    '장군동1가',
    '장군동2가',
    '장군동3가',
    '장군동4가',
    '장군동5가',
    '중성동',
    '중앙동1가',
    '중앙동2가',
    '중앙동3가',
    '창동',
    '창포동1가',
    '창포동2가',
    '창포동3가',
    '청계동',
    '추산동',
    '평화동',
    '화영동',
    '해운동',
    '현동',
    '홍문동',
    '구산면',
    '진동면',
    '진북면',
    '진전면',
  ],
  '울산광역시 북구': [
    '창평동',
    '호계동',
    '가대동',
    '신천동',
    '중산동',
    '상안동',
    '천곡동',
    '달천동',
    '시례동',
    '무룡동',
    '구유동',
    '정자동',
    '신명동',
    '대안동',
    '당사동',
    '신현동',
    '산하동',
    '어물동',
    '명촌동',
    '진장동',
    '연암동',
    '효문동',
    '양정동',
    '화봉동',
    '송정동',
    '염포동',
  ],
  '세종특별자치시 연동면': [],
  '충청남도 논산시': [
    '화지동',
    '반월동',
    '대교동',
    '부창동',
    '취암동',
    '등화동',
    '지산동',
    '덕지동',
    '내동',
    '강산동',
    '관촉동',
    '강경읍',
    '연무읍',
    '성동면',
    '광석면',
    '노성면',
    '상월면',
    '부적면',
    '연산면',
    '벌곡면',
    '양촌면',
    '가야곡면',
    '은진면',
    '채운면',
  ],
  '충청남도 보령시': [
    '대천동',
    '죽정동',
    '화산동',
    '동대동',
    '명천동',
    '궁촌동',
    '내항동',
    '남곡동',
    '요암동',
    '신흑동',
    '웅천읍',
    '주포면',
    '오천면',
    '천북면',
    '청소면',
    '청라면',
    '남포면',
    '주산면',
    '미산면',
    '성주면',
    '주교면',
  ],
  '경상남도 사천시': [
    '동동',
    '서동',
    '선구동',
    '동금동',
    '서금동',
    '동림동',
    '좌룡동',
    '벌리동',
    '용강동',
    '와룡동',
    '봉남동',
    '이금동',
    '이홀동',
    '궁지동',
    '사등동',
    '향촌동',
    '대방동',
    '실안동',
    '마도동',
    '늑도동',
    '신수동',
    '백천동',
    '신벽동',
    '노룡동',
    '대포동',
    '송포동',
    '죽림동',
    '사천읍',
    '정동면',
    '사남면',
    '용현면',
    '축동면',
    '곤양면',
    '곤명면',
    '서포면',
  ],
  '충청남도 천안시 동남구': [
    '대흥동',
    '성황동',
    '문화동',
    '사직동',
    '영성동',
    '오룡동',
    '원성동',
    '구성동',
    '청수동',
    '삼룡동',
    '청당동',
    '유량동',
    '봉명동',
    '다가동',
    '용곡동',
    '신방동',
    '쌍용동',
    '신부동',
    '안서동',
    '구룡동',
    '목천읍',
    '풍세면',
    '광덕면',
    '북면',
    '성남면',
    '수신면',
    '병천면',
    '동면',
  ],
  '전라북도 정읍시': [
    '수성동',
    '장명동',
    '상동',
    '시기동',
    '연지동',
    '농소동',
    '하모동',
    '상평동',
    '과교동',
    '삼산동',
    '진산동',
    '금붕동',
    '송산동',
    '신월동',
    '용산동',
    '교암동',
    '부전동',
    '쌍암동',
    '내장동',
    '영파동',
    '하북동',
    '구룡동',
    '흑암동',
    '용계동',
    '공평동',
    '망제동',
    '신정동',
    '신태인읍',
    '북면',
    '입암면',
    '소성면',
    '고부면',
    '영원면',
    '덕천면',
    '이평면',
    '정우면',
    '태인면',
    '감곡면',
    '옹동면',
    '칠보면',
    '산내면',
    '산외면',
  ],
  '전라북도 장수군': [
    '장수읍',
    '산서면',
    '번암면',
    '장계면',
    '천천면',
    '계남면',
    '계북면',
  ],
  '경기도 용인시 기흥구': [
    '신갈동',
    '구갈동',
    '상갈동',
    '하갈동',
    '보라동',
    '지곡동',
    '공세동',
    '고매동',
    '농서동',
    '서천동',
    '영덕동',
    '언남동',
    '마북동',
    '청덕동',
    '동백동',
    '중동',
    '상하동',
    '보정동',
  ],
  '강원도 인제군': ['인제읍', '남면', '북면', '기린면', '서화면', '상남면'],
  '전라남도 구례군': [
    '구례읍',
    '문척면',
    '간전면',
    '토지면',
    '마산면',
    '광의면',
    '용방면',
    '산동면',
  ],
  '전라남도 장흥군': [
    '장흥읍',
    '관산읍',
    '대덕읍',
    '용산면',
    '안양면',
    '장동면',
    '장평면',
    '유치면',
    '부산면',
    '회진면',
  ],
  '경기도 파주시': [
    '금촌동',
    '아동동',
    '야동동',
    '검산동',
    '맥금동',
    '교하동',
    '야당동',
    '다율동',
    '오도동',
    '상지석동',
    '산남동',
    '동패동',
    '당하동',
    '문발동',
    '송촌동',
    '목동동',
    '하지석동',
    '서패동',
    '신촌동',
    '연다산동',
    '와동동',
    '금릉동',
    '문산읍',
    '파주읍',
    '법원읍',
    '조리읍',
    '월롱면',
    '탄현면',
    '광탄면',
    '파평면',
    '적성면',
    '군내면',
    '장단면',
    '진동면',
    '진서면',
  ],
  '경상남도 남해군': [
    '남해읍',
    '이동면',
    '상주면',
    '삼동면',
    '미조면',
    '남면',
    '서면',
    '고현면',
    '설천면',
    '창선면',
  ],
  '경기도 양주시': [
    '유양동',
    '어둔동',
    '남방동',
    '마전동',
    '산북동',
    '광사동',
    '만송동',
    '삼숭동',
    '고읍동',
    '덕정동',
    '봉양동',
    '회암동',
    '율정동',
    '옥정동',
    '고암동',
    '덕계동',
    '회정동',
    '백석읍',
    '은현면',
    '남면',
    '광적면',
    '장흥면',
  ],
  '충청북도 청주시 서원구': [
    '사직동',
    '사창동',
    '모충동',
    '산남동',
    '미평동',
    '분평동',
    '수곡동',
    '성화동',
    '개신동',
    '죽림동',
    '장성동',
    '장암동',
    '남이면',
    '현도면',
  ],
  '세종특별자치시 어진동': [],
  '대구광역시 북구': [
    '칠성동1가',
    '칠성동2가',
    '고성동1가',
    '고성동2가',
    '고성동3가',
    '침산동',
    '노원동1가',
    '노원동2가',
    '노원동3가',
    '대현동',
    '산격동',
    '복현동',
    '검단동',
    '동변동',
    '서변동',
    '조야동',
    '노곡동',
    '읍내동',
    '동호동',
    '학정동',
    '도남동',
    '국우동',
    '구암동',
    '동천동',
    '관음동',
    '태전동',
    '매천동',
    '팔달동',
    '금호동',
    '사수동',
    '연경동',
  ],
  '세종특별자치시 다정동': [],
  '경기도 이천시': [
    '창전동',
    '관고동',
    '중리동',
    '증일동',
    '율현동',
    '진리동',
    '안흥동',
    '갈산동',
    '증포동',
    '송정동',
    '사음동',
    '단월동',
    '대포동',
    '고담동',
    '장록동',
    '장호원읍',
    '부발읍',
    '신둔면',
    '백사면',
    '호법면',
    '마장면',
    '대월면',
    '모가면',
    '설성면',
    '율면',
  ],
  '전라북도 고창군': [
    '고창읍',
    '고수면',
    '아산면',
    '무장면',
    '공음면',
    '상하면',
    '해리면',
    '성송면',
    '대산면',
    '심원면',
    '흥덕면',
    '성내면',
    '신림면',
    '부안면',
  ],
  '서울특별시 서대문구': [
    '충정로2가',
    '충정로3가',
    '합동',
    '미근동',
    '냉천동',
    '천연동',
    '옥천동',
    '영천동',
    '현저동',
    '북아현동',
    '홍제동',
    '대현동',
    '대신동',
    '신촌동',
    '봉원동',
    '창천동',
    '연희동',
    '홍은동',
    '북가좌동',
    '남가좌동',
  ],
  '경기도 군포시': [
    '당동',
    '당정동',
    '부곡동',
    '산본동',
    '금정동',
    '둔대동',
    '속달동',
    '대야미동',
    '도마교동',
  ],
  '전라북도 부안군': [
    '부안읍',
    '주산면',
    '동진면',
    '행안면',
    '계화면',
    '보안면',
    '변산면',
    '진서면',
    '백산면',
    '상서면',
    '하서면',
    '줄포면',
    '위도면',
  ],
  '경상북도 경산시': [
    '삼남동',
    '삼북동',
    '서상동',
    '신교동',
    '상방동',
    '백천동',
    '옥곡동',
    '사정동',
    '옥산동',
    '중산동',
    '정평동',
    '대평동',
    '대정동',
    '임당동',
    '대동',
    '계양동',
    '중방동',
    '조영동',
    '남방동',
    '내동',
    '여천동',
    '유곡동',
    '신천동',
    '점촌동',
    '평산동',
    '사동',
    '삼풍동',
    '갑제동',
    '하양읍',
    '진량읍',
    '압량읍',
    '와촌면',
    '자인면',
    '용성면',
    '남산면',
    '남천면',
  ],
  '서울특별시 성동구': [
    '상왕십리동',
    '하왕십리동',
    '홍익동',
    '도선동',
    '마장동',
    '사근동',
    '행당동',
    '응봉동',
    '금호동1가',
    '금호동2가',
    '금호동3가',
    '금호동4가',
    '옥수동',
    '성수동1가',
    '성수동2가',
    '송정동',
    '용답동',
  ],
  '대전광역시 서구': [
    '복수동',
    '변동',
    '도마동',
    '정림동',
    '용문동',
    '탄방동',
    '괴정동',
    '가장동',
    '내동',
    '갈마동',
    '둔산동',
    '월평동',
    '가수원동',
    '도안동',
    '관저동',
    '흑석동',
    '매노동',
    '산직동',
    '장안동',
    '평촌동',
    '오동',
    '우명동',
    '원정동',
    '용촌동',
    '봉곡동',
    '괴곡동',
    '만년동',
  ],
  '충청남도 천안시 서북구': [
    '와촌동',
    '성정동',
    '백석동',
    '두정동',
    '성성동',
    '차암동',
    '쌍용동',
    '불당동',
    '업성동',
    '신당동',
    '부대동',
    '성환읍',
    '성거읍',
    '직산읍',
    '입장면',
  ],
  '전라북도 김제시': [
    '요촌동',
    '신풍동',
    '용동',
    '검산동',
    '순동',
    '백학동',
    '서암동',
    '신곡동',
    '교동',
    '옥산동',
    '갈공동',
    '하동',
    '흥사동',
    '상동동',
    '월성동',
    '황산동',
    '난봉동',
    '오정동',
    '복죽동',
    '입석동',
    '장화동',
    '신덕동',
    '월봉동',
    '신월동',
    '연정동',
    '명덕동',
    '제월동',
    '도장동',
    '서정동',
    '양전동',
    '만경읍',
    '죽산면',
    '백산면',
    '용지면',
    '백구면',
    '부량면',
    '공덕면',
    '청하면',
    '성덕면',
    '진봉면',
    '금구면',
    '봉남면',
    '황산면',
    '금산면',
    '광활면',
  ],
  '강원도 평창군': [
    '평창읍',
    '미탄면',
    '방림면',
    '대화면',
    '봉평면',
    '용평면',
    '진부면',
    '대관령면',
  ],
  '전라남도 강진군': [
    '강진읍',
    '군동면',
    '칠량면',
    '대구면',
    '도암면',
    '신전면',
    '성전면',
    '작천면',
    '병영면',
    '옴천면',
    '마량면',
  ],
  '대전광역시 중구': [
    '은행동',
    '목동',
    '중촌동',
    '대흥동',
    '문창동',
    '석교동',
    '호동',
    '옥계동',
    '대사동',
    '부사동',
    '용두동',
    '오류동',
    '태평동',
    '유천동',
    '산성동',
    '사정동',
    '안영동',
    '무수동',
    '구완동',
    '침산동',
    '목달동',
    '정생동',
    '어남동',
    '금동',
  ],
  '경기도 안성시': [
    '봉산동',
    '숭인동',
    '영동',
    '봉남동',
    '구포동',
    '동본동',
    '명륜동',
    '옥천동',
    '낙원동',
    '창전동',
    '성남동',
    '신흥동',
    '인지동',
    '금산동',
    '연지동',
    '대천동',
    '서인동',
    '석정동',
    '아양동',
    '금석동',
    '계동',
    '옥산동',
    '사곡동',
    '도기동',
    '당왕동',
    '가사동',
    '가현동',
    '신건지동',
    '신소현동',
    '신모산동',
    '현수동',
    '발화동',
    '중리동',
    '공도읍',
    '보개면',
    '금광면',
    '서운면',
    '미양면',
    '대덕면',
    '양성면',
    '원곡면',
    '일죽면',
    '죽산면',
    '삼죽면',
    '고삼면',
  ],
  '경기도 성남시 중원구': [
    '성남동',
    '금광동',
    '은행동',
    '상대원동',
    '여수동',
    '도촌동',
    '갈현동',
    '하대원동',
    '중앙동',
  ],
  '경기도 성남시 분당구': [
    '분당동',
    '수내동',
    '정자동',
    '율동',
    '서현동',
    '이매동',
    '야탑동',
    '판교동',
    '삼평동',
    '백현동',
    '금곡동',
    '궁내동',
    '동원동',
    '구미동',
    '운중동',
    '대장동',
    '석운동',
    '하산운동',
  ],
  '전라남도 순천시': [
    '삼거동',
    '와룡동',
    '영동',
    '옥천동',
    '행동',
    '금곡동',
    '매곡동',
    '석현동',
    '가곡동',
    '용당동',
    '조곡동',
    '생목동',
    '덕암동',
    '연향동',
    '풍덕동',
    '남정동',
    '인제동',
    '저전동',
    '장천동',
    '남내동',
    '중앙동',
    '동외동',
    '교량동',
    '대룡동',
    '홍내동',
    '오천동',
    '덕월동',
    '야흥동',
    '인월동',
    '안풍동',
    '대대동',
    '왕지동',
    '조례동',
    '승주읍',
    '해룡면',
    '서면',
    '황전면',
    '월등면',
    '주암면',
    '송광면',
    '외서면',
    '낙안면',
    '별량면',
    '상사면',
  ],
  '경기도 하남시': [
    '천현동',
    '하산곡동',
    '창우동',
    '배알미동',
    '상산곡동',
    '신장동',
    '당정동',
    '덕풍동',
    '망월동',
    '풍산동',
    '미사동',
    '선동',
    '감북동',
    '감일동',
    '감이동',
    '학암동',
    '교산동',
    '춘궁동',
    '하사창동',
    '상사창동',
    '항동',
    '초일동',
    '초이동',
    '광암동',
  ],
  '대전광역시 유성구': [
    '원내동',
    '교촌동',
    '대정동',
    '용계동',
    '학하동',
    '계산동',
    '성북동',
    '세동',
    '송정동',
    '방동',
    '봉명동',
    '구암동',
    '덕명동',
    '원신흥동',
    '상대동',
    '복용동',
    '장대동',
    '갑동',
    '노은동',
    '지족동',
    '죽동',
    '궁동',
    '어은동',
    '구성동',
    '신성동',
    '가정동',
    '도룡동',
    '장동',
    '방현동',
    '화암동',
    '덕진동',
    '하기동',
    '추목동',
    '자운동',
    '신봉동',
    '수남동',
    '안산동',
    '외삼동',
    '반석동',
    '문지동',
    '전민동',
    '원촌동',
    '탑립동',
    '용산동',
    '봉산동',
    '관평동',
    '송강동',
    '금고동',
    '대동',
    '금탄동',
    '신동',
    '둔곡동',
    '구룡동',
  ],
  '경기도 수원시 팔달구': [
    '팔달로1가',
    '팔달로2가',
    '팔달로3가',
    '남창동',
    '영동',
    '중동',
    '구천동',
    '남수동',
    '매향동',
    '북수동',
    '신풍동',
    '장안동',
    '교동',
    '매교동',
    '매산로1가',
    '매산로2가',
    '매산로3가',
    '고등동',
    '화서동',
    '지동',
    '우만동',
    '인계동',
  ],
  '경기도 안양시 동안구': ['비산동', '관양동', '평촌동', '호계동'],
  '경기도 고양시 일산서구': [
    '일산동',
    '주엽동',
    '탄현동',
    '대화동',
    '덕이동',
    '가좌동',
    '구산동',
    '법곳동',
  ],
  '경기도 김포시': [
    '북변동',
    '걸포동',
    '운양동',
    '장기동',
    '감정동',
    '사우동',
    '풍무동',
    '마산동',
    '구래동',
    '통진읍',
    '고촌읍',
    '양촌읍',
    '대곶면',
    '월곶면',
    '하성면',
  ],
  '서울특별시 강남구': [
    '역삼동',
    '개포동',
    '청담동',
    '삼성동',
    '대치동',
    '신사동',
    '논현동',
    '압구정동',
    '세곡동',
    '자곡동',
    '율현동',
    '일원동',
    '수서동',
    '도곡동',
  ],
  '서울특별시 강동구': [
    '명일동',
    '고덕동',
    '상일동',
    '길동',
    '둔촌동',
    '암사동',
    '성내동',
    '천호동',
    '강일동',
  ],
  '경상북도 고령군': [
    '대가야읍',
    '덕곡면',
    '운수면',
    '성산면',
    '다산면',
    '개진면',
    '우곡면',
    '쌍림면',
  ],
  '대구광역시 동구': [
    '신암동',
    '신천동',
    '효목동',
    '평광동',
    '봉무동',
    '불로동',
    '도동',
    '지저동',
    '입석동',
    '검사동',
    '방촌동',
    '둔산동',
    '부동',
    '신평동',
    '서호동',
    '동호동',
    '신기동',
    '율하동',
    '용계동',
    '율암동',
    '상매동',
    '매여동',
    '각산동',
    '신서동',
    '동내동',
    '괴전동',
    '금강동',
    '대림동',
    '사복동',
    '숙천동',
    '내곡동',
    '능성동',
    '진인동',
    '도학동',
    '백안동',
    '미곡동',
    '용수동',
    '신무동',
    '미대동',
    '내동',
    '신용동',
    '중대동',
    '송정동',
    '덕곡동',
    '지묘동',
  ],
  '강원도 영월군': [
    '영월읍',
    '상동읍',
    '중동면',
    '김삿갓면',
    '북면',
    '남면',
    '한반도면',
    '주천면',
    '무릉도원면',
  ],
  '충청북도 단양군': [
    '단양읍',
    '매포읍',
    '대강면',
    '가곡면',
    '영춘면',
    '어상천면',
    '적성면',
    '단성면',
  ],
  '충청남도 공주시': [
    '반죽동',
    '봉황동',
    '중학동',
    '중동',
    '산성동',
    '교동',
    '웅진동',
    '금성동',
    '옥룡동',
    '금학동',
    '봉정동',
    '주미동',
    '태봉동',
    '오곡동',
    '신기동',
    '소학동',
    '상왕동',
    '무릉동',
    '월송동',
    '신관동',
    '금흥동',
    '쌍신동',
    '월미동',
    '검상동',
    '석장리동',
    '송선동',
    '동현동',
    '유구읍',
    '이인면',
    '탄천면',
    '계룡면',
    '반포면',
    '의당면',
    '정안면',
    '우성면',
    '사곡면',
    '신풍면',
  ],
  '전라남도 영암군': [
    '영암읍',
    '삼호읍',
    '덕진면',
    '금정면',
    '신북면',
    '시종면',
    '도포면',
    '군서면',
    '서호면',
    '학산면',
    '미암면',
  ],
  '서울특별시 양천구': ['신정동', '목동', '신월동'],
  '전라북도 완주군': [
    '삼례읍',
    '봉동읍',
    '용진읍',
    '상관면',
    '이서면',
    '소양면',
    '구이면',
    '고산면',
    '비봉면',
    '운주면',
    '화산면',
    '동상면',
    '경천면',
  ],
  '경상북도 김천시': [
    '감호동',
    '용두동',
    '모암동',
    '성내동',
    '평화동',
    '남산동',
    '황금동',
    '신음동',
    '교동',
    '삼락동',
    '문당동',
    '다수동',
    '백옥동',
    '부곡동',
    '지좌동',
    '덕곡동',
    '대광동',
    '응명동',
    '양천동',
    '율곡동',
    '아포읍',
    '농소면',
    '남면',
    '개령면',
    '감문면',
    '어모면',
    '봉산면',
    '대항면',
    '감천면',
    '조마면',
    '구성면',
    '지례면',
    '부항면',
    '대덕면',
    '증산면',
  ],
  '부산광역시 금정구': [
    '두구동',
    '노포동',
    '청룡동',
    '남산동',
    '선동',
    '오륜동',
    '구서동',
    '장전동',
    '부곡동',
    '서동',
    '금사동',
    '회동동',
    '금성동',
  ],
  '경상남도 창원시 마산회원구': [
    '구암동',
    '두척동',
    '봉암동',
    '석전동',
    '양덕동',
    '합성동',
    '회성동',
    '회원동',
    '내서읍',
  ],
  '경기도 오산시': [
    '오산동',
    '부산동',
    '원동',
    '궐동',
    '청학동',
    '가장동',
    '금암동',
    '수청동',
    '은계동',
    '내삼미동',
    '외삼미동',
    '양산동',
    '세교동',
    '지곶동',
    '서랑동',
    '서동',
    '벌음동',
    '두곡동',
    '탑동',
    '누읍동',
    '가수동',
    '고현동',
    '청호동',
    '갈곶동',
  ],
  '경상남도 의령군': [
    '의령읍',
    '가례면',
    '칠곡면',
    '대의면',
    '화정면',
    '용덕면',
    '정곡면',
    '지정면',
    '낙서면',
    '부림면',
    '봉수면',
    '궁류면',
    '유곡면',
  ],
  '부산광역시 부산진구': [
    '양정동',
    '전포동',
    '부전동',
    '범천동',
    '범전동',
    '연지동',
    '초읍동',
    '부암동',
    '당감동',
    '가야동',
    '개금동',
  ],
  '전라북도 무주군': [
    '무주읍',
    '무풍면',
    '설천면',
    '적상면',
    '안성면',
    '부남면',
  ],
  '전라남도 해남군': [
    '해남읍',
    '삼산면',
    '화산면',
    '현산면',
    '송지면',
    '북평면',
    '북일면',
    '옥천면',
    '계곡면',
    '마산면',
    '황산면',
    '산이면',
    '문내면',
    '화원면',
  ],
  '경상남도 산청군': [
    '산청읍',
    '차황면',
    '오부면',
    '생초면',
    '금서면',
    '삼장면',
    '시천면',
    '단성면',
    '신안면',
    '생비량면',
    '신등면',
  ],
  '서울특별시 금천구': ['가산동', '독산동', '시흥동'],
  '세종특별자치시 산울동': [],
  '서울특별시 송파구': [
    '잠실동',
    '신천동',
    '풍납동',
    '송파동',
    '석촌동',
    '삼전동',
    '가락동',
    '문정동',
    '장지동',
    '방이동',
    '오금동',
    '거여동',
    '마천동',
  ],
  '경기도 포천시': [
    '신읍동',
    '어룡동',
    '자작동',
    '선단동',
    '설운동',
    '동교동',
    '소흘읍',
    '군내면',
    '내촌면',
    '가산면',
    '신북면',
    '창수면',
    '영중면',
    '일동면',
    '이동면',
    '영북면',
    '관인면',
    '화현면',
  ],
  '강원도 춘천시': [
    '봉의동',
    '요선동',
    '낙원동',
    '중앙로1가',
    '중앙로2가',
    '중앙로3가',
    '옥천동',
    '조양동',
    '죽림동',
    '운교동',
    '약사동',
    '효자동',
    '소양로1가',
    '소양로2가',
    '소양로3가',
    '소양로4가',
    '근화동',
    '우두동',
    '사농동',
    '후평동',
    '온의동',
    '교동',
    '퇴계동',
    '석사동',
    '삼천동',
    '칠전동',
    '송암동',
    '신동',
    '중도동',
    '신북읍',
    '동면',
    '동산면',
    '신동면',
    '남면',
    '서면',
    '사북면',
    '북산면',
    '동내면',
    '남산면',
  ],
  '서울특별시 동작구': [
    '노량진동',
    '상도동',
    '상도1동',
    '본동',
    '흑석동',
    '동작동',
    '사당동',
    '대방동',
    '신대방동',
  ],
  '전라북도 임실군': [
    '임실읍',
    '청웅면',
    '운암면',
    '신평면',
    '성수면',
    '오수면',
    '신덕면',
    '삼계면',
    '관촌면',
    '강진면',
    '덕치면',
    '지사면',
  ],
  '인천광역시 남동구': [
    '구월동',
    '간석동',
    '만수동',
    '장수동',
    '서창동',
    '운연동',
    '남촌동',
    '수산동',
    '도림동',
    '논현동',
    '고잔동',
  ],
  '부산광역시 동구': ['초량동', '수정동', '좌천동', '범일동'],
  '전라남도 곡성군': [
    '곡성읍',
    '오곡면',
    '삼기면',
    '석곡면',
    '목사동면',
    '죽곡면',
    '고달면',
    '옥과면',
    '입면',
    '겸면',
    '오산면',
  ],
  '인천광역시 중구': [
    '해안동1가',
    '해안동2가',
    '해안동3가',
    '해안동4가',
    '관동1가',
    '관동2가',
    '관동3가',
    '항동1가',
    '항동2가',
    '항동3가',
    '항동4가',
    '항동5가',
    '항동6가',
    '항동7가',
    '송학동1가',
    '송학동2가',
    '송학동3가',
    '사동',
    '신생동',
    '신포동',
    '답동',
    '신흥동1가',
    '신흥동2가',
    '신흥동3가',
    '선화동',
    '유동',
    '율목동',
    '내동',
    '경동',
    '용동',
    '인현동',
    '북성동1가',
    '북성동2가',
    '북성동3가',
    '선린동',
    '송월동1가',
    '송월동2가',
    '송월동3가',
    '중산동',
    '운남동',
    '운서동',
    '운북동',
    '을왕동',
    '남북동',
    '덕교동',
    '무의동',
  ],
  '충청북도 보은군': [
    '보은읍',
    '속리산면',
    '장안면',
    '마로면',
    '탄부면',
    '삼승면',
    '수한면',
    '회남면',
    '회인면',
    '내북면',
    '산외면',
  ],
  '경상북도 포항시 남구': [
    '상도동',
    '대도동',
    '해도동',
    '송도동',
    '청림동',
    '일월동',
    '송정동',
    '송내동',
    '괴동동',
    '동촌동',
    '장흥동',
    '인덕동',
    '호동',
    '효자동',
    '지곡동',
    '대잠동',
    '이동',
    '구룡포읍',
    '연일읍',
    '오천읍',
    '대송면',
    '동해면',
    '장기면',
    '호미곶면',
  ],
  '전라북도 남원시': [
    '동충동',
    '하정동',
    '죽항동',
    '쌍교동',
    '천거동',
    '금동',
    '조산동',
    '왕정동',
    '신정동',
    '화정동',
    '향교동',
    '용정동',
    '광치동',
    '내척동',
    '산곡동',
    '도통동',
    '월락동',
    '고죽동',
    '식정동',
    '갈치동',
    '노암동',
    '어현동',
    '신촌동',
    '운봉읍',
    '주천면',
    '수지면',
    '송동면',
    '주생면',
    '금지면',
    '대강면',
    '대산면',
    '사매면',
    '덕과면',
    '보절면',
    '산동면',
    '이백면',
    '아영면',
    '산내면',
    '인월면',
  ],
  '인천광역시 옹진군': [
    '북도면',
    '백령면',
    '대청면',
    '덕적면',
    '영흥면',
    '자월면',
    '연평면',
  ],
  '전라남도 담양군': [
    '담양읍',
    '봉산면',
    '고서면',
    '가사문학면',
    '창평면',
    '대덕면',
    '무정면',
    '금성면',
    '용면',
    '월산면',
    '수북면',
    '대전면',
  ],
  '부산광역시 해운대구': [
    '반송동',
    '석대동',
    '반여동',
    '재송동',
    '우동',
    '중동',
    '좌동',
    '송정동',
  ],
  '전라북도 전주시 덕진구': [
    '진북동',
    '인후동1가',
    '인후동2가',
    '덕진동1가',
    '덕진동2가',
    '금암동',
    '팔복동1가',
    '팔복동2가',
    '팔복동3가',
    '산정동',
    '금상동',
    '우아동1가',
    '우아동2가',
    '우아동3가',
    '호성동1가',
    '호성동2가',
    '호성동3가',
    '전미동1가',
    '전미동2가',
    '송천동1가',
    '송천동2가',
    '반월동',
    '화전동',
    '용정동',
    '성덕동',
    '원동',
    '고랑동',
    '여의동',
    '만성동',
    '장동',
    '팔복동4가',
    '도도동',
    '강흥동',
    '도덕동',
    '남정동',
    '중동',
    '여의동2가',
  ],
  '경상북도 문경시': [
    '점촌동',
    '영신동',
    '흥덕동',
    '우지동',
    '창동',
    '신기동',
    '불정동',
    '유곡동',
    '공평동',
    '모전동',
    '윤직동',
    '문경읍',
    '가은읍',
    '영순면',
    '산양면',
    '호계면',
    '산북면',
    '동로면',
    '마성면',
    '농암면',
  ],
  '세종특별자치시 장군면': [],
  '세종특별자치시 연서면': [],
  '충청북도 제천시': [
    '의림동',
    '서부동',
    '동현동',
    '남천동',
    '교동',
    '중앙로1가',
    '중앙로2가',
    '명동',
    '화산동',
    '영천동',
    '하소동',
    '신월동',
    '청전동',
    '모산동',
    '고암동',
    '장락동',
    '흑석동',
    '두학동',
    '고명동',
    '신백동',
    '강제동',
    '명지동',
    '산곡동',
    '왕암동',
    '천남동',
    '신동',
    '자작동',
    '대랑동',
    '봉양읍',
    '금성면',
    '청풍면',
    '수산면',
    '덕산면',
    '한수면',
    '백운면',
    '송학면',
  ],
  '서울특별시 강북구': ['미아동', '번동', '수유동', '우이동'],
  '전라남도 신안군': [
    '지도읍',
    '압해읍',
    '증도면',
    '임자면',
    '자은면',
    '비금면',
    '도초면',
    '흑산면',
    '하의면',
    '신의면',
    '장산면',
    '안좌면',
    '팔금면',
    '암태면',
  ],
  '경상남도 고성군': [
    '고성읍',
    '삼산면',
    '하일면',
    '하이면',
    '상리면',
    '대가면',
    '영현면',
    '영오면',
    '개천면',
    '구만면',
    '회화면',
    '마암면',
    '동해면',
    '거류면',
  ],
  '강원도 고성군': ['간성읍', '거진읍', '현내면', '죽왕면', '토성면', '수동면'],
  '세종특별자치시 보람동': [],
  '충청남도 서천군': [
    '장항읍',
    '서천읍',
    '마서면',
    '화양면',
    '기산면',
    '한산면',
    '마산면',
    '시초면',
    '문산면',
    '판교면',
    '종천면',
    '비인면',
    '서면',
  ],
  '경기도 수원시 영통구': [
    '매탄동',
    '원천동',
    '이의동',
    '하동',
    '영통동',
    '신동',
    '망포동',
  ],
  '전라남도 무안군': [
    '무안읍',
    '일로읍',
    '삼향읍',
    '몽탄면',
    '청계면',
    '현경면',
    '망운면',
    '해제면',
    '운남면',
  ],
  '서울특별시 서초구': [
    '방배동',
    '양재동',
    '우면동',
    '원지동',
    '잠원동',
    '반포동',
    '서초동',
    '내곡동',
    '염곡동',
    '신원동',
  ],
  '경상남도 창녕군': [
    '창녕읍',
    '남지읍',
    '고암면',
    '성산면',
    '대합면',
    '이방면',
    '유어면',
    '대지면',
    '계성면',
    '영산면',
    '장마면',
    '도천면',
    '길곡면',
    '부곡면',
  ],
  '제주특별자치도 서귀포시': [
    '서귀동',
    '법환동',
    '서호동',
    '호근동',
    '동홍동',
    '서홍동',
    '상효동',
    '하효동',
    '신효동',
    '보목동',
    '토평동',
    '중문동',
    '회수동',
    '대포동',
    '월평동',
    '강정동',
    '도순동',
    '하원동',
    '색달동',
    '상예동',
    '하예동',
    '영남동',
    '대정읍',
    '남원읍',
    '성산읍',
    '안덕면',
    '표선면',
  ],
  '충청북도 진천군': [
    '진천읍',
    '덕산읍',
    '초평면',
    '문백면',
    '백곡면',
    '이월면',
    '광혜원면',
  ],
  '인천광역시 미추홀구': [
    '숭의동',
    '용현동',
    '학익동',
    '도화동',
    '주안동',
    '관교동',
    '문학동',
  ],
  '전라북도 진안군': [
    '진안읍',
    '용담면',
    '안천면',
    '동향면',
    '상전면',
    '백운면',
    '성수면',
    '마령면',
    '부귀면',
    '정천면',
    '주천면',
  ],
  '세종특별자치시 소정면': [],
  '경기도 남양주시': [
    '호평동',
    '평내동',
    '금곡동',
    '일패동',
    '이패동',
    '삼패동',
    '수석동',
    '지금동',
    '도농동',
    '별내동',
    '다산동',
    '와부읍',
    '진접읍',
    '화도읍',
    '진건읍',
    '오남읍',
    '퇴계원읍',
    '별내면',
    '수동면',
    '조안면',
  ],
  '울산광역시 중구': [
    '학성동',
    '학산동',
    '복산동',
    '북정동',
    '옥교동',
    '성남동',
    '우정동',
    '성안동',
    '유곡동',
    '태화동',
    '다운동',
    '동동',
    '서동',
    '남외동',
    '장현동',
    '약사동',
    '반구동',
  ],
  '전라북도 익산시': [
    '창인동1가',
    '창인동2가',
    '중앙동1가',
    '중앙동2가',
    '중앙동3가',
    '평화동',
    '갈산동',
    '주현동',
    '인화동1가',
    '인화동2가',
    '동산동',
    '마동',
    '남중동',
    '모현동1가',
    '모현동2가',
    '송학동',
    '목천동',
    '만석동',
    '현영동',
    '신용동',
    '신동',
    '영등동',
    '어양동',
    '신흥동',
    '금강동',
    '석탄동',
    '팔봉동',
    '덕기동',
    '석왕동',
    '은기동',
    '정족동',
    '임상동',
    '월성동',
    '부송동',
    '용제동',
    '석암동',
    '함열읍',
    '오산면',
    '황등면',
    '함라면',
    '웅포면',
    '성당면',
    '용안면',
    '낭산면',
    '망성면',
    '여산면',
    '금마면',
    '왕궁면',
    '춘포면',
    '삼기면',
    '용동면',
  ],
  '경상북도 예천군': [
    '예천읍',
    '용문면',
    '감천면',
    '보문면',
    '호명면',
    '유천면',
    '용궁면',
    '개포면',
    '지보면',
    '풍양면',
    '효자면',
    '은풍면',
  ],
  '서울특별시 성북구': [
    '성북동',
    '성북동1가',
    '돈암동',
    '동소문동1가',
    '동소문동2가',
    '동소문동3가',
    '동소문동4가',
    '동소문동5가',
    '동소문동6가',
    '동소문동7가',
    '삼선동1가',
    '삼선동2가',
    '삼선동3가',
    '삼선동4가',
    '삼선동5가',
    '동선동1가',
    '동선동2가',
    '동선동3가',
    '동선동4가',
    '동선동5가',
    '안암동1가',
    '안암동2가',
    '안암동3가',
    '안암동4가',
    '안암동5가',
    '보문동4가',
    '보문동5가',
    '보문동6가',
    '보문동7가',
    '보문동1가',
    '보문동2가',
    '보문동3가',
    '정릉동',
    '길음동',
    '종암동',
    '하월곡동',
    '상월곡동',
    '장위동',
    '석관동',
  ],
  '세종특별자치시 연기면': [],
  '충청북도 청주시 상당구': [
    '영동',
    '북문로1가',
    '북문로2가',
    '북문로3가',
    '남문로1가',
    '남문로2가',
    '문화동',
    '서운동',
    '서문동',
    '남주동',
    '석교동',
    '수동',
    '탑동',
    '대성동',
    '영운동',
    '금천동',
    '용담동',
    '명암동',
    '산성동',
    '용암동',
    '용정동',
    '방서동',
    '평촌동',
    '지북동',
    '운동동',
    '월오동',
    '낭성면',
    '미원면',
    '가덕면',
    '남일면',
    '문의면',
  ],
  '경상북도 청송군': [
    '청송읍',
    '주왕산면',
    '부남면',
    '현동면',
    '현서면',
    '안덕면',
    '파천면',
    '진보면',
  ],
  '인천광역시 서구': [
    '백석동',
    '시천동',
    '검암동',
    '경서동',
    '공촌동',
    '연희동',
    '심곡동',
    '가정동',
    '신현동',
    '석남동',
    '원창동',
    '가좌동',
    '마전동',
    '당하동',
    '원당동',
    '대곡동',
    '금곡동',
    '오류동',
    '왕길동',
    '불로동',
    '청라동',
  ],
  '경기도 양평군': [
    '양평읍',
    '강상면',
    '강하면',
    '양서면',
    '옥천면',
    '서종면',
    '단월면',
    '청운면',
    '양동면',
    '지평면',
    '용문면',
    '개군면',
  ],
  '제주특별자치도 제주시': [
    '일도일동',
    '일도이동',
    '이도일동',
    '이도이동',
    '삼도일동',
    '삼도이동',
    '건입동',
    '용담일동',
    '용담이동',
    '용담삼동',
    '화북일동',
    '화북이동',
    '삼양일동',
    '삼양이동',
    '삼양삼동',
    '봉개동',
    '아라일동',
    '아라이동',
    '오라일동',
    '오라이동',
    '오라삼동',
    '노형동',
    '외도일동',
    '외도이동',
    '이호일동',
    '이호이동',
    '도두일동',
    '도두이동',
    '도남동',
    '도련일동',
    '도련이동',
    '용강동',
    '회천동',
    '오등동',
    '월평동',
    '영평동',
    '연동',
    '도평동',
    '해안동',
    '내도동',
    '한림읍',
    '애월읍',
    '구좌읍',
    '조천읍',
    '한경면',
    '추자면',
    '우도면',
  ],
  '강원도 횡성군': [
    '횡성읍',
    '우천면',
    '안흥면',
    '둔내면',
    '갑천면',
    '청일면',
    '공근면',
    '서원면',
    '강림면',
  ],
  '울산광역시 남구': [
    '무거동',
    '옥동',
    '두왕동',
    '신정동',
    '달동',
    '삼산동',
    '여천동',
    '야음동',
    '선암동',
    '상개동',
    '부곡동',
    '고사동',
    '성암동',
    '황성동',
    '용연동',
    '남화동',
    '용잠동',
    '장생포동',
    '매암동',
  ],
  '충청남도 아산시': [
    '온천동',
    '실옥동',
    '방축동',
    '기산동',
    '초사동',
    '신인동',
    '법곡동',
    '장존동',
    '좌부동',
    '읍내동',
    '풍기동',
    '용화동',
    '모종동',
    '권곡동',
    '배미동',
    '득산동',
    '점양동',
    '신동',
    '남동',
    '염치읍',
    '배방읍',
    '송악면',
    '탕정면',
    '음봉면',
    '둔포면',
    '영인면',
    '인주면',
    '선장면',
    '도고면',
    '신창면',
  ],
  '충청북도 청주시 흥덕구': [
    '운천동',
    '신봉동',
    '가경동',
    '복대동',
    '봉명동',
    '송정동',
    '강서동',
    '석곡동',
    '휴암동',
    '신전동',
    '현암동',
    '동막동',
    '수의동',
    '지동동',
    '서촌동',
    '신성동',
    '평동',
    '신대동',
    '남촌동',
    '내곡동',
    '상신동',
    '원평동',
    '문암동',
    '송절동',
    '화계동',
    '외북동',
    '향정동',
    '비하동',
    '석소동',
    '정봉동',
    '신촌동',
    '오송읍',
    '강내면',
    '옥산면',
  ],
  '경상남도 함안군': [
    '가야읍',
    '칠원읍',
    '함안면',
    '군북면',
    '법수면',
    '대산면',
    '칠서면',
    '칠북면',
    '산인면',
    '여항면',
  ],
  '경상북도 성주군': [
    '성주읍',
    '선남면',
    '용암면',
    '수륜면',
    '가천면',
    '금수면',
    '대가면',
    '벽진면',
    '초전면',
    '월항면',
  ],
  '경기도 부천시': [
    '원미동',
    '심곡동',
    '춘의동',
    '도당동',
    '약대동',
    '소사동',
    '역곡동',
    '중동',
    '상동',
    '소사본동',
    '심곡본동',
    '범박동',
    '괴안동',
    '송내동',
    '옥길동',
    '계수동',
    '오정동',
    '여월동',
    '작동',
    '원종동',
    '고강동',
    '대장동',
    '삼정동',
    '내동',
  ],
  '충청북도 증평군': ['증평읍', '도안면'],
  '세종특별자치시 합강동': [],
  '경기도 구리시': [
    '갈매동',
    '사노동',
    '인창동',
    '교문동',
    '수택동',
    '아천동',
    '토평동',
  ],
  '서울특별시 은평구': [
    '수색동',
    '녹번동',
    '불광동',
    '갈현동',
    '구산동',
    '대조동',
    '응암동',
    '역촌동',
    '신사동',
    '증산동',
    '진관동',
  ],
  '경기도 화성시': [
    '진안동',
    '병점동',
    '능동',
    '기산동',
    '반월동',
    '반정동',
    '황계동',
    '배양동',
    '기안동',
    '송산동',
    '안녕동',
    '반송동',
    '석우동',
    '오산동',
    '청계동',
    '영천동',
    '중동',
    '신동',
    '목동',
    '산척동',
    '장지동',
    '송동',
    '방교동',
    '금곡동',
    '새솔동',
    '봉담읍',
    '우정읍',
    '향남읍',
    '남양읍',
    '매송면',
    '비봉면',
    '마도면',
    '송산면',
    '서신면',
    '팔탄면',
    '장안면',
    '양감면',
    '정남면',
  ],
  '충청남도 부여군': [
    '부여읍',
    '규암면',
    '은산면',
    '외산면',
    '내산면',
    '구룡면',
    '홍산면',
    '옥산면',
    '남면',
    '충화면',
    '양화면',
    '임천면',
    '장암면',
    '세도면',
    '석성면',
    '초촌면',
  ],
  '부산광역시 강서구': [
    '대저1동',
    '대저2동',
    '강동동',
    '명지동',
    '죽림동',
    '식만동',
    '죽동동',
    '봉림동',
    '송정동',
    '화전동',
    '녹산동',
    '생곡동',
    '구랑동',
    '지사동',
    '미음동',
    '범방동',
    '신호동',
    '동선동',
    '성북동',
    '눌차동',
    '천성동',
    '대항동',
  ],
  '강원도 철원군': [
    '철원읍',
    '김화읍',
    '갈말읍',
    '동송읍',
    '서면',
    '근남면',
    '근북면',
    '근동면',
    '원동면',
    '원남면',
    '임남면',
  ],
  '서울특별시 동대문구': [
    '신설동',
    '용두동',
    '제기동',
    '전농동',
    '답십리동',
    '장안동',
    '청량리동',
    '회기동',
    '휘경동',
    '이문동',
  ],
  '경상북도 영천시': [
    '조교동',
    '망정동',
    '야사동',
    '문내동',
    '문외동',
    '창구동',
    '교촌동',
    '과전동',
    '성내동',
    '화룡동',
    '도동',
    '금노동',
    '완산동',
    '범어동',
    '작산동',
    '봉동',
    '본촌동',
    '채신동',
    '괴연동',
    '대전동',
    '녹전동',
    '도림동',
    '오미동',
    '오수동',
    '쌍계동',
    '도남동',
    '매산동',
    '언하동',
    '신기동',
    '서산동',
    '금호읍',
    '청통면',
    '신녕면',
    '화산면',
    '화북면',
    '화남면',
    '자양면',
    '임고면',
    '고경면',
    '북안면',
    '대창면',
  ],
  '경기도 연천군': [
    '연천읍',
    '전곡읍',
    '군남면',
    '청산면',
    '백학면',
    '미산면',
    '왕징면',
    '신서면',
    '중면',
    '장남면',
  ],
  '경상남도 창원시 진해구': [
    '동상동',
    '도천동',
    '도만동',
    '신흥동',
    '현동',
    '비봉동',
    '태평동',
    '충의동',
    '무송동',
    '인의동',
    '숭인동',
    '대영동',
    '남빈동',
    '앵곡동',
    '제황산동',
    '속천동',
    '대죽동',
    '안곡동',
    '수송동',
    '회현동',
    '익선동',
    '창선동',
    '대천동',
    '광화동',
    '통신동',
    '중앙동',
    '부흥동',
    '중평동',
    '근화동',
    '송죽동',
    '화천동',
    '송학동',
    '대흥동',
    '평안동',
    '충무동',
    '인사동',
    '여좌동',
    '태백동',
    '경화동',
    '석동',
    '이동',
    '자은동',
    '덕산동',
    '풍호동',
    '장천동',
    '행암동',
    '북부동',
    '성내동',
    '서중동',
    '남문동',
    '제덕동',
    '수도동',
    '연도동',
    '명동',
    '죽곡동',
    '원포동',
    '남양동',
    '마천동',
    '소사동',
    '대장동',
    '두동',
    '청안동',
    '안골동',
    '용원동',
    '가주동',
  ],
  '전라북도 전주시 완산구': [
    '중앙동1가',
    '중앙동2가',
    '중앙동3가',
    '중앙동4가',
    '경원동1가',
    '경원동2가',
    '경원동3가',
    '풍남동1가',
    '풍남동2가',
    '풍남동3가',
    '전동',
    '전동3가',
    '다가동1가',
    '다가동2가',
    '다가동3가',
    '다가동4가',
    '고사동',
    '교동',
    '태평동',
    '중노송동',
    '남노송동',
    '동완산동',
    '서완산동1가',
    '서완산동2가',
    '동서학동',
    '서서학동',
    '중화산동1가',
    '중화산동2가',
    '서신동',
    '석구동',
    '원당동',
    '평화동1가',
    '평화동2가',
    '평화동3가',
    '중인동',
    '용복동',
    '삼천동1가',
    '삼천동2가',
    '삼천동3가',
    '효자동1가',
    '효자동2가',
    '효자동3가',
    '대성동',
    '색장동',
    '상림동',
    '서노송동',
  ],
  '세종특별자치시 전의면': [],
  '충청북도 괴산군': [
    '괴산읍',
    '감물면',
    '장연면',
    '연풍면',
    '칠성면',
    '문광면',
    '청천면',
    '청안면',
    '사리면',
    '소수면',
    '불정면',
  ],
  '경상남도 창원시 성산구': [
    '토월동',
    '사파정동',
    '가음정동',
    '외동',
    '대방동',
    '남산동',
    '삼정자동',
    '천선동',
    '불모산동',
    '안민동',
    '내동',
    '남지동',
    '상복동',
    '완암동',
    '창곡동',
    '월림동',
    '적현동',
    '양곡동',
    '반송동',
    '귀산동',
    '귀곡동',
    '귀현동',
    '신촌동',
    '반지동',
    '중앙동',
    '반림동',
    '상남동',
    '성주동',
    '웅남동',
    '사파동',
    '가음동',
    '성산동',
    '남양동',
  ],
  '대구광역시 서구': [
    '내당동',
    '비산동',
    '평리동',
    '상리동',
    '중리동',
    '이현동',
    '원대동1가',
    '원대동2가',
    '원대동3가',
  ],
  '인천광역시 계양구': [
    '효성동',
    '계산동',
    '작전동',
    '서운동',
    '임학동',
    '용종동',
    '병방동',
    '방축동',
    '박촌동',
    '동양동',
    '귤현동',
    '상야동',
    '하야동',
    '평동',
    '노오지동',
    '선주지동',
    '이화동',
    '오류동',
    '갈현동',
    '둑실동',
    '목상동',
    '다남동',
    '장기동',
  ],
  '세종특별자치시 종촌동': [],
  '충청남도 당진시': [
    '읍내동',
    '채운동',
    '우두동',
    '원당동',
    '시곡동',
    '수청동',
    '대덕동',
    '행정동',
    '용연동',
    '사기소동',
    '구룡동',
    '합덕읍',
    '송악읍',
    '고대면',
    '석문면',
    '대호지면',
    '정미면',
    '면천면',
    '순성면',
    '우강면',
    '신평면',
    '송산면',
  ],
  '전라남도 장성군': [
    '장성읍',
    '진원면',
    '남면',
    '동화면',
    '삼서면',
    '삼계면',
    '황룡면',
    '서삼면',
    '북일면',
    '북이면',
    '북하면',
  ],
  '대구광역시 중구': [
    '동인동1가',
    '동인동2가',
    '동인동3가',
    '동인동4가',
    '삼덕동1가',
    '삼덕동2가',
    '삼덕동3가',
    '봉산동',
    '장관동',
    '상서동',
    '수동',
    '덕산동',
    '종로1가',
    '종로2가',
    '사일동',
    '동일동',
    '남일동',
    '전동',
    '동성로3가',
    '동문동',
    '문화동',
    '공평동',
    '동성로2가',
    '교동',
    '용덕동',
    '상덕동',
    '완전동',
    '도원동',
    '수창동',
    '태평로3가',
    '인교동',
    '서야동',
    '서성로1가',
    '시장북로',
    '하서동',
    '남성로',
    '계산동1가',
    '계산동2가',
    '동산동',
    '서문로2가',
    '서성로2가',
    '포정동',
    '서문로1가',
    '서내동',
    '북성로2가',
    '대안동',
    '동성로1가',
    '북성로1가',
    '화전동',
    '향촌동',
    '북내동',
    '대신동',
    '달성동',
    '남산동',
    '대봉동',
  ],
  '세종특별자치시 부강면': [],
  '경상북도 울릉군': ['울릉읍', '서면', '북면'],
  '강원도 속초시': [
    '영랑동',
    '동명동',
    '중앙동',
    '금호동',
    '청학동',
    '교동',
    '노학동',
    '조양동',
    '청호동',
    '대포동',
    '도문동',
    '설악동',
    '장사동',
  ],
  '전라남도 완도군': [
    '완도읍',
    '금일읍',
    '노화읍',
    '군외면',
    '신지면',
    '고금면',
    '약산면',
    '청산면',
    '소안면',
    '금당면',
    '보길면',
    '생일면',
  ],
  '광주광역시 광산구': [
    '송정동',
    '도산동',
    '도호동',
    '신촌동',
    '서봉동',
    '운수동',
    '선암동',
    '소촌동',
    '우산동',
    '황룡동',
    '박호동',
    '비아동',
    '도천동',
    '수완동',
    '월계동',
    '쌍암동',
    '산월동',
    '신창동',
    '신가동',
    '운남동',
    '안청동',
    '진곡동',
    '장덕동',
    '흑석동',
    '하남동',
    '장수동',
    '산정동',
    '월곡동',
    '등임동',
    '산막동',
    '고룡동',
    '신룡동',
    '두정동',
    '임곡동',
    '광산동',
    '오산동',
    '사호동',
    '하산동',
    '유계동',
    '본덕동',
    '용봉동',
    '요기동',
    '복룡동',
    '송대동',
    '옥동',
    '월전동',
    '장록동',
    '송촌동',
    '지죽동',
    '용동',
    '용곡동',
    '지정동',
    '명화동',
    '동산동',
    '연산동',
    '도덕동',
    '송산동',
    '지평동',
    '오운동',
    '삼거동',
    '양동',
    '내산동',
    '대산동',
    '송학동',
    '신동',
    '삼도동',
    '남산동',
    '송치동',
    '산수동',
    '선동',
    '지산동',
    '왕동',
    '북산동',
    '명도동',
    '동호동',
    '덕림동',
    '양산동',
    '동림동',
    '오선동',
  ],
  '세종특별자치시 아름동': [],
  '세종특별자치시 반곡동': [],
  '경기도 광주시': [
    '경안동',
    '쌍령동',
    '송정동',
    '회덕동',
    '탄벌동',
    '목현동',
    '삼동',
    '중대동',
    '직동',
    '태전동',
    '장지동',
    '역동',
    '목동',
    '오포읍',
    '초월읍',
    '곤지암읍',
    '도척면',
    '퇴촌면',
    '남종면',
    '남한산성면',
  ],
  '강원도 동해시': [
    '천곡동',
    '평릉동',
    '송정동',
    '용정동',
    '지흥동',
    '효가동',
    '동회동',
    '나안동',
    '쇄운동',
    '부곡동',
    '발한동',
    '북평동',
    '구미동',
    '추암동',
    '구호동',
    '단봉동',
    '지가동',
    '이도동',
    '귀운동',
    '대구동',
    '호현동',
    '내동',
    '묵호진동',
    '삼화동',
    '이기동',
    '이로동',
    '어달동',
    '대진동',
    '망상동',
    '심곡동',
    '초구동',
    '괴란동',
    '만우동',
    '신흥동',
    '비천동',
    '달방동',
  ],
  '경상북도 군위군': [
    '군위읍',
    '소보면',
    '효령면',
    '부계면',
    '우보면',
    '의흥면',
    '산성면',
    '삼국유사면',
  ],
  '경기도 안양시 만안구': ['안양동', '석수동', '박달동'],
  '전라남도 고흥군': [
    '고흥읍',
    '도양읍',
    '풍양면',
    '도덕면',
    '금산면',
    '도화면',
    '포두면',
    '봉래면',
    '점암면',
    '과역면',
    '남양면',
    '동강면',
    '대서면',
    '두원면',
    '영남면',
    '동일면',
  ],
  '경상남도 거제시': [
    '능포동',
    '장승포동',
    '두모동',
    '아양동',
    '아주동',
    '옥포동',
    '덕포동',
    '장평동',
    '고현동',
    '상동동',
    '문동동',
    '삼거동',
    '양정동',
    '수월동',
    '일운면',
    '동부면',
    '남부면',
    '거제면',
    '둔덕면',
    '사등면',
    '연초면',
    '하청면',
    '장목면',
  ],
  '전라남도 광양시': [
    '황금동',
    '황길동',
    '도이동',
    '성황동',
    '중군동',
    '중동',
    '마동',
    '광영동',
    '태인동',
    '금호동',
    '광양읍',
    '봉강면',
    '옥룡면',
    '옥곡면',
    '진상면',
    '진월면',
    '다압면',
  ],
  '서울특별시 광진구': [
    '중곡동',
    '능동',
    '구의동',
    '광장동',
    '자양동',
    '화양동',
    '군자동',
  ],
  '충청북도 옥천군': [
    '옥천읍',
    '동이면',
    '안남면',
    '안내면',
    '청성면',
    '청산면',
    '이원면',
    '군서면',
    '군북면',
  ],
  '서울특별시 영등포구': [
    '영등포동',
    '영등포동1가',
    '영등포동2가',
    '영등포동3가',
    '영등포동4가',
    '영등포동5가',
    '영등포동6가',
    '영등포동7가',
    '영등포동8가',
    '여의도동',
    '당산동1가',
    '당산동2가',
    '당산동3가',
    '당산동4가',
    '당산동5가',
    '당산동6가',
    '당산동',
    '도림동',
    '문래동1가',
    '문래동2가',
    '문래동3가',
    '문래동4가',
    '문래동5가',
    '문래동6가',
    '양평동1가',
    '양평동2가',
    '양평동3가',
    '양평동4가',
    '양평동5가',
    '양평동6가',
    '양화동',
    '신길동',
    '대림동',
    '양평동',
  ],
  '대구광역시 수성구': [
    '범어동',
    '만촌동',
    '수성동1가',
    '수성동2가',
    '수성동3가',
    '수성동4가',
    '황금동',
    '중동',
    '상동',
    '파동',
    '두산동',
    '지산동',
    '범물동',
    '시지동',
    '매호동',
    '성동',
    '사월동',
    '신매동',
    '욱수동',
    '노변동',
    '삼덕동',
    '연호동',
    '이천동',
    '고모동',
    '가천동',
    '대흥동',
  ],
  '세종특별자치시 한솔동': [],
  '경기도 여주시': [
    '상동',
    '홍문동',
    '창동',
    '우만동',
    '단현동',
    '신진동',
    '하동',
    '교동',
    '월송동',
    '가업동',
    '연라동',
    '상거동',
    '하거동',
    '삼교동',
    '점봉동',
    '능현동',
    '멱곡동',
    '연양동',
    '매룡동',
    '천송동',
    '오학동',
    '현암동',
    '오금동',
    '가남읍',
    '점동면',
    '흥천면',
    '금사면',
    '능서면',
    '대신면',
    '북내면',
    '강천면',
    '산북면',
  ],
  '세종특별자치시 가람동': [],
  '울산광역시 동구': [
    '방어동',
    '화정동',
    '일산동',
    '전하동',
    '미포동',
    '주전동',
    '동부동',
    '서부동',
  ],
  '대전광역시 동구': [
    '원동',
    '인동',
    '효동',
    '천동',
    '가오동',
    '신흥동',
    '판암동',
    '삼정동',
    '용운동',
    '대동',
    '자양동',
    '신안동',
    '소제동',
    '가양동',
    '용전동',
    '성남동',
    '홍도동',
    '삼성동',
    '정동',
    '중동',
    '추동',
    '비룡동',
    '주산동',
    '마산동',
    '효평동',
    '직동',
    '세천동',
    '신상동',
    '신하동',
    '신촌동',
    '사성동',
    '내탑동',
    '오동',
    '주촌동',
    '낭월동',
    '대별동',
    '이사동',
    '대성동',
    '장척동',
    '소호동',
    '구도동',
    '삼괴동',
    '상소동',
    '하소동',
  ],
  '대구광역시 달서구': [
    '성당동',
    '두류동',
    '파호동',
    '호림동',
    '갈산동',
    '신당동',
    '이곡동',
    '장동',
    '장기동',
    '용산동',
    '죽전동',
    '감삼동',
    '본리동',
    '상인동',
    '도원동',
    '진천동',
    '유천동',
    '대천동',
    '월성동',
    '월암동',
    '송현동',
    '대곡동',
    '본동',
    '호산동',
  ],
  '전라남도 보성군': [
    '보성읍',
    '벌교읍',
    '노동면',
    '미력면',
    '겸백면',
    '율어면',
    '복내면',
    '문덕면',
    '조성면',
    '득량면',
    '회천면',
    '웅치면',
  ],
  '경상북도 포항시 북구': [
    '대흥동',
    '신흥동',
    '남빈동',
    '상원동',
    '여천동',
    '중앙동',
    '덕산동',
    '덕수동',
    '대신동',
    '동빈1가',
    '동빈2가',
    '학산동',
    '항구동',
    '득량동',
    '학잠동',
    '죽도동',
    '용흥동',
    '우현동',
    '창포동',
    '두호동',
    '장성동',
    '양덕동',
    '환호동',
    '여남동',
    '흥해읍',
    '신광면',
    '청하면',
    '송라면',
    '기계면',
    '죽장면',
    '기북면',
  ],
  '울산광역시 울주군': [
    '온산읍',
    '언양읍',
    '온양읍',
    '범서읍',
    '청량읍',
    '삼남읍',
    '서생면',
    '웅촌면',
    '두동면',
    '두서면',
    '상북면',
    '삼동면',
  ],
  '서울특별시 강서구': [
    '염창동',
    '등촌동',
    '화곡동',
    '가양동',
    '마곡동',
    '내발산동',
    '외발산동',
    '공항동',
    '방화동',
    '개화동',
    '과해동',
    '오곡동',
    '오쇠동',
  ],
  '부산광역시 수영구': ['망미동', '수영동', '민락동', '광안동', '남천동'],
  '경기도 수원시 장안구': [
    '파장동',
    '정자동',
    '이목동',
    '율전동',
    '천천동',
    '영화동',
    '송죽동',
    '조원동',
    '연무동',
    '상광교동',
    '하광교동',
  ],
  '경상북도 영주시': [
    '영주동',
    '상망동',
    '하망동',
    '휴천동',
    '가흥동',
    '문정동',
    '고현동',
    '창진동',
    '상줄동',
    '조와동',
    '조암동',
    '적서동',
    '아지동',
    '풍기읍',
    '이산면',
    '평은면',
    '문수면',
    '장수면',
    '안정면',
    '봉현면',
    '순흥면',
    '단산면',
    '부석면',
  ],
  '경상남도 진주시': [
    '망경동',
    '주약동',
    '강남동',
    '칠암동',
    '본성동',
    '동성동',
    '남성동',
    '인사동',
    '대안동',
    '평안동',
    '중안동',
    '계동',
    '봉곡동',
    '상봉동',
    '봉래동',
    '수정동',
    '장대동',
    '옥봉동',
    '상대동',
    '하대동',
    '상평동',
    '초전동',
    '장재동',
    '하촌동',
    '신안동',
    '평거동',
    '이현동',
    '유곡동',
    '판문동',
    '귀곡동',
    '가좌동',
    '호탄동',
    '충무공동',
    '문산읍',
    '내동면',
    '정촌면',
    '금곡면',
    '진성면',
    '일반성면',
    '이반성면',
    '사봉면',
    '지수면',
    '대곡면',
    '금산면',
    '집현면',
    '미천면',
    '명석면',
    '대평면',
    '수곡면',
  ],
  '충청남도 청양군': [
    '청양읍',
    '운곡면',
    '대치면',
    '정산면',
    '목면',
    '청남면',
    '장평면',
    '남양면',
    '화성면',
    '비봉면',
  ],
  '경상남도 통영시': [
    '도천동',
    '서호동',
    '명정동',
    '항남동',
    '중앙동',
    '문화동',
    '태평동',
    '동호동',
    '정량동',
    '북신동',
    '무전동',
    '평림동',
    '인평동',
    '당동',
    '미수동',
    '봉평동',
    '도남동',
    '산양읍',
    '용남면',
    '도산면',
    '광도면',
    '욕지면',
    '한산면',
    '사량면',
  ],
  '경기도 용인시 처인구': [
    '김량장동',
    '역북동',
    '삼가동',
    '남동',
    '유방동',
    '고림동',
    '마평동',
    '운학동',
    '호동',
    '해곡동',
    '포곡읍',
    '모현읍',
    '이동읍',
    '남사면',
    '원삼면',
    '백암면',
    '양지면',
  ],
  '강원도 양양군': ['양양읍', '서면', '손양면', '현북면', '현남면', '강현면'],
  '충청남도 예산군': [
    '예산읍',
    '삽교읍',
    '대술면',
    '신양면',
    '광시면',
    '대흥면',
    '응봉면',
    '덕산면',
    '봉산면',
    '고덕면',
    '신암면',
    '오가면',
  ],
  '경상북도 의성군': [
    '의성읍',
    '단촌면',
    '점곡면',
    '옥산면',
    '사곡면',
    '춘산면',
    '가음면',
    '금성면',
    '봉양면',
    '비안면',
    '구천면',
    '단밀면',
    '단북면',
    '안계면',
    '다인면',
    '신평면',
    '안평면',
    '안사면',
  ],
  '전라남도 여수시': [
    '종화동',
    '수정동',
    '공화동',
    '관문동',
    '고소동',
    '동산동',
    '중앙동',
    '교동',
    '군자동',
    '충무동',
    '연등동',
    '광무동',
    '서교동',
    '봉강동',
    '봉산동',
    '남산동',
    '국동',
    '신월동',
    '여서동',
    '문수동',
    '오림동',
    '미평동',
    '둔덕동',
    '오천동',
    '만흥동',
    '덕충동',
    '경호동',
    '학동',
    '학용동',
    '안산동',
    '소호동',
    '시전동',
    '신기동',
    '웅천동',
    '선원동',
    '여천동',
    '화장동',
    '주삼동',
    '봉계동',
    '해산동',
    '화치동',
    '월하동',
    '평여동',
    '중흥동',
    '적량동',
    '월내동',
    '묘도동',
    '낙포동',
    '신덕동',
    '상암동',
    '호명동',
    '돌산읍',
    '소라면',
    '율촌면',
    '화양면',
    '남면',
    '화정면',
    '삼산면',
  ],
  '강원도 강릉시': [
    '홍제동',
    '남문동',
    '명주동',
    '성내동',
    '임당동',
    '금학동',
    '용강동',
    '성남동',
    '옥천동',
    '교동',
    '포남동',
    '초당동',
    '강문동',
    '송정동',
    '견소동',
    '내곡동',
    '회산동',
    '장현동',
    '박월동',
    '담산동',
    '노암동',
    '유산동',
    '월호평동',
    '신석동',
    '입암동',
    '청량동',
    '두산동',
    '학동',
    '병산동',
    '남항진동',
    '유천동',
    '지변동',
    '죽헌동',
    '대전동',
    '운정동',
    '난곡동',
    '저동',
    '안현동',
    '운산동',
    '주문진읍',
    '성산면',
    '왕산면',
    '구정면',
    '강동면',
    '옥계면',
    '사천면',
    '연곡면',
  ],
  '경상남도 창원시 의창구': [
    '북동',
    '중동',
    '서상동',
    '소답동',
    '도계동',
    '동정동',
    '소계동',
    '용동',
    '용지동',
    '삼동동',
    '두대동',
    '덕정동',
    '지귀동',
    '서곡동',
    '반송동',
    '봉림동',
    '퇴촌동',
    '명곡동',
    '반계동',
    '사화동',
    '차용동',
    '내리동',
    '대원동',
    '명서동',
    '사림동',
    '신월동',
    '봉곡동',
    '용호동',
    '팔용동',
    '동읍',
    '북면',
    '대산면',
  ],
  '경상북도 울진군': [
    '울진읍',
    '평해읍',
    '북면',
    '근남면',
    '기성면',
    '온정면',
    '죽변면',
    '후포면',
    '금강송면',
    '매화면',
  ],
  '세종특별자치시 해밀동': [],
  '강원도 삼척시': [
    '성내동',
    '성북동',
    '읍상동',
    '읍중동',
    '당저동',
    '교동',
    '갈천동',
    '증산동',
    '우지동',
    '마달동',
    '자원동',
    '평전동',
    '등봉동',
    '도경동',
    '마평동',
    '오사동',
    '건지동',
    '원당동',
    '성남동',
    '남양동',
    '사직동',
    '오분동',
    '적노동',
    '조비동',
    '정상동',
    '정하동',
    '근산동',
    '도계읍',
    '원덕읍',
    '근덕면',
    '하장면',
    '노곡면',
    '미로면',
    '가곡면',
    '신기면',
  ],
  '대구광역시 남구': ['이천동', '봉덕동', '대명동'],
  '전라남도 함평군': [
    '함평읍',
    '손불면',
    '신광면',
    '학교면',
    '엄다면',
    '대동면',
    '나산면',
    '해보면',
    '월야면',
  ],
  '강원도 양구군': ['양구읍', '국토정중앙면', '동면', '방산면', '해안면'],
  '세종특별자치시 전동면': [],
  '세종특별자치시 조치원읍': [],
  '부산광역시 북구': ['금곡동', '화명동', '만덕동', '덕천동', '구포동'],
  '경기도 가평군': ['가평읍', '설악면', '청평면', '상면', '조종면', '북면'],
  '부산광역시 기장군': ['기장읍', '장안읍', '정관읍', '일광면', '철마면'],
  '서울특별시 중랑구': [
    '면목동',
    '상봉동',
    '중화동',
    '묵동',
    '망우동',
    '신내동',
  ],
  '경상남도 밀양시': [
    '내일동',
    '내이동',
    '교동',
    '삼문동',
    '남포동',
    '용평동',
    '활성동',
    '가곡동',
    '삼랑진읍',
    '하남읍',
    '부북면',
    '상동면',
    '산외면',
    '산내면',
    '단장면',
    '상남면',
    '초동면',
    '무안면',
    '청도면',
  ],
  '경상남도 거창군': [
    '거창읍',
    '주상면',
    '웅양면',
    '고제면',
    '북상면',
    '위천면',
    '마리면',
    '남상면',
    '남하면',
    '신원면',
    '가조면',
    '가북면',
  ],
  '부산광역시 영도구': [
    '대교동1가',
    '대교동2가',
    '대평동1가',
    '대평동2가',
    '남항동1가',
    '남항동2가',
    '남항동3가',
    '영선동1가',
    '영선동2가',
    '영선동3가',
    '영선동4가',
    '신선동1가',
    '신선동2가',
    '신선동3가',
    '봉래동1가',
    '봉래동2가',
    '봉래동3가',
    '봉래동4가',
    '봉래동5가',
    '청학동',
    '동삼동',
  ],
  '부산광역시 사하구': [
    '괴정동',
    '당리동',
    '하단동',
    '신평동',
    '장림동',
    '다대동',
    '구평동',
    '감천동',
  ],
  '부산광역시 사상구': [
    '삼락동',
    '모라동',
    '덕포동',
    '괘법동',
    '감전동',
    '주례동',
    '학장동',
    '엄궁동',
  ],
  '대전광역시 대덕구': [
    '오정동',
    '대화동',
    '읍내동',
    '연축동',
    '신대동',
    '와동',
    '송촌동',
    '법동',
    '중리동',
    '비래동',
    '석봉동',
    '목상동',
    '문평동',
    '신일동',
    '덕암동',
    '상서동',
    '평촌동',
    '장동',
    '용호동',
    '이현동',
    '갈전동',
    '부수동',
    '황호동',
    '삼정동',
    '미호동',
    '신탄진동',
  ],
  '세종특별자치시 나성동': [],
  '서울특별시 관악구': ['봉천동', '신림동', '남현동'],
  '광주광역시 서구': [
    '양동',
    '농성동',
    '광천동',
    '유촌동',
    '덕흥동',
    '쌍촌동',
    '화정동',
    '치평동',
    '내방동',
    '서창동',
    '세하동',
    '용두동',
    '풍암동',
    '벽진동',
    '금호동',
    '마륵동',
    '매월동',
    '동천동',
  ],
  '충청남도 계룡시': ['금암동', '두마면', '엄사면', '신도안면'],
  '세종특별자치시 새롬동': [],
  '경상남도 김해시': [
    '동상동',
    '서상동',
    '부원동',
    '봉황동',
    '대성동',
    '구산동',
    '삼계동',
    '내동',
    '외동',
    '흥동',
    '풍유동',
    '명법동',
    '이동',
    '화목동',
    '전하동',
    '강동',
    '삼정동',
    '어방동',
    '삼방동',
    '안동',
    '지내동',
    '불암동',
    '유하동',
    '내덕동',
    '부곡동',
    '무계동',
    '신문동',
    '삼문동',
    '대청동',
    '관동동',
    '율하동',
    '장유동',
    '응달동',
    '수가동',
    '진영읍',
    '주촌면',
    '진례면',
    '한림면',
    '생림면',
    '상동면',
    '대동면',
  ],
  '부산광역시 남구': [
    '대연동',
    '용호동',
    '용당동',
    '문현동',
    '우암동',
    '감만동',
  ],
};
