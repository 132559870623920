import {
  Drawer,
  Box,
  IconButton,
  Typography,
  Button,
  FormControl,
  Tooltip as MuiTooltip,
  Select,
  MenuItem,
  Skeleton,
  Stack,
  InputLabel,
  Divider,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Line } from 'react-chartjs-2';
import { useState, useMemo, useRef } from 'react';
import zoomPlugin from 'chartjs-plugin-zoom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { bidData } from './BidTable';
//그래프 라이브러리

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { GroupType } from '../../../service/group/type';
import { useGetGraphData } from '../../../service/keyword/keywordHooks';
import { GraphDataType } from '../../../service/keyword/type';
import { customToast } from '../../../utils/customToast';
import React from 'react';

// 차트에 입력할 register데이터 정리
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  Filler,
);

interface KeywordDetailProps {
  groupInfo: GroupType;
  open: boolean;
  onClose: () => void;
  keywordInfo: bidData | undefined;
}

const KeywordDetail: React.FC<KeywordDetailProps> = ({
  groupInfo,
  open,
  onClose,
  keywordInfo,
}) => {
  const [dataLen, setDataLen] = useState<number>(0);
  const [range, setRange] = useState('3');
  const { data: graphData, refetch } = useGetGraphData(
    groupInfo.groupId,
    !!keywordInfo ? keywordInfo.nccKeywordId : '',
  );


  

  //
  //linechart를 가리는 ref 변수
  const chartRef: any = useRef(null);

  //그래프 줌 reset시키는 함수
  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current?.resetZoom();
    }
    refetch();
  };

  const handleDownload = () => {
    const now = new Date();

    // 시간 정보 추출
    const year = now.getFullYear(); // 연도
    const month = now.getMonth() + 1; // 월 (0부터 시작하므로 +1 해줌)
    const day = now.getDate(); // 일
    const hour = now.getHours(); // 시
    const minute = now.getMinutes(); // 분
    const second = now.getSeconds(); // 초

    const fileName = `${keywordInfo?.keyword} ${year}-${month}-${day} ${hour}-${minute}-${second}.csv`;

    let previouseBidPrice = graphData?.slice(dataLen)[0].price
      ? graphData?.slice(dataLen)[0].price
      : 0; // 최초입찰 값

    if (!!graphData) {
      const data = graphData.slice(dataLen).map((item) => {
        const result = {
          키워드: item.keywordName,
          입찰가: item.price.toLocaleString(),
          '현재 순위': item.rank,
          '목표 순위': item.targetRank,
          '최대 입찰금액': item.maxPrice.toLocaleString(),
          변동액: calculateVariation(
            previouseBidPrice,
            item.price,
          ).toLocaleString(),
          시간: item.time,
        };
        previouseBidPrice = item.price;

        return result;
      });

      // CSV 변환
      const csvHeaders = [
        '키워드',
        '입찰가',
        '현재 순위',
        '목표 순위',
        '최대 입찰금액',
        '변동액',
        '시간',
      ];
      const csvRows = data.map((row) => [
        row.키워드,
        `"${row.입찰가}"`,
        row['현재 순위'],
        row['목표 순위'],
        `"${row['최대 입찰금액']}"`,
        row.변동액,
        row.시간,
      ]);

      const csvContent = [
        csvHeaders.join(','), // CSV 헤더
        ...csvRows.map((row) => row.join(',')), // CSV 데이터
      ].join('\n');

      // UTF-8 BOM 추가
      const bom = '\uFEFF';
      const blob = new Blob([bom + csvContent], {
        type: 'text/csv;charset=utf-8;',
      });

      // 다운로드 처리
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      customToast('info', '데이터가 없습니다.');
    }
  };

  //그래프 옵션
  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      tootip: {
        mode: 'index' as const,
        intersect: false,
      },
      title: {
        display: false,
        text: '입찰 기록 그래프', //표 제목
      },
      zoom: {
        // 줌 옵션
        pan: {
          enabled: true,
        },
        limits: {
          price: { max: 100000, min: 0 },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
        },
      },
    },
    scales: {
      //x축 범례 설정
      x: {
        ticks: {
          autoSkip: true,
          autoSkipPadding: 50, //x축 라벨이 겹치지 않게 설정
          maxRotation: 0,
          minRotation: 0,
          // stepSize: 200,
          callback: function (index: any) {
            const value = graphData ? graphData[Number(index)].time : '';
            if (Number(range) < 24) {
              return value.split(' ')[1];
            } else {
              const a = value.split(' ')[0].split('/')[1];
              const b = value.split(' ')[0].split('/')[2];
              const c = value.split(' ')[1].split(':')[0];
              return `${a}/${b} ${c}시`;
            }
          },
        },
      },
      //y축 범례 설정
      price: {
        // 임의로 생성한 이름
        type: 'linear' as const, // 타입
        display: true, //이걸로 필터링을 걸수 있다.
        position: 'left' as const, // y축
        grid: {
          drawOnChartArea: true, // 가로선이 사라진다.
        },
        ticks: {
          stepSize: keywordInfo?.adjustment_price
            ? keywordInfo?.adjustment_price
            : 10,
          callback: function (value: any) {
            return (
              (Math.round(Number(value) / 10) * 10).toLocaleString() + ' 원'
            );
          },
        },
      },
      ranking: {
        max: 15,
        min: 0,
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          stepSize: 1,
          callback: function (value: any) {
            if (Number(value) <= 0 || Number(value) > 15) {
              return '-';
            } else {
              return Math.round(Number(value)).toLocaleString() + '위';
            }
          },
        },
        reverse: true,
      },
    },
  };

  //기간선택 함수
  const handleGraphRange = (event: SelectChangeEvent) => {
    setRange(event.target.value as string);
  };

  //그래프에 들어갈 데이터 가공
  const data = useMemo(() => {
    if (!graphData) return null;

    const currentBidPriceList = graphData.map(
      (item: GraphDataType) => item.price,
    );
    const currentRankingList = graphData.map(
      (item: GraphDataType) => item.rank,
    );
    const targetRankingList = graphData.map(
      (item: GraphDataType) => item.targetRank,
    );
    const timeStempList = graphData.map((item: GraphDataType) => item.time);

    const end = new Date(timeStempList[timeStempList.length - 1]);

    let len = 0;
    for (let i = 0; i < timeStempList.length; i++) {
      const start = new Date(timeStempList[i]);
      // 두 날짜 사이의 시간 차이를 밀리초 단위로 계산
      const diffInMilliseconds = end.getTime() - start.getTime();
      len = i + 1;
      if (diffInMilliseconds / (60 * 60 * 1000) <= parseInt(range)) {
        break;
      }
    }
    setDataLen(len); // 이건 CSV 데이터 출력할 때 사용함

    return {
      labels: timeStempList.slice(len),
      // 위치에 따라 위로 올라오는 위치가 변경됨

      datasets: [
        {
          label: '현재입찰가',
          // fill: true,
          fill: false,
          // borderWidth: 1, // 선 굵기
          lineTension: 0.4,
          pointRadius: 0,
          data: currentBidPriceList.slice(len),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'price',
        },
        {
          label: '현재 순위',
          fill: false,
          pointRadius: 0,
          data: currentRankingList.slice(len),
          borderColor: 'rgb(153, 100, 200)',
          backgroundColor: 'rgba(153, 100, 200, 0.5)',
          yAxisID: 'ranking',
        },
        {
          label: '목표 순위',
          fill: false,
          pointRadius: 0,
          data: targetRankingList.slice(len),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          yAxisID: 'ranking',
        },
      ],
    };
  }, [graphData, range]); //비동기라 이거 넣어야 그래프가 그려짐

  return (
    <>
      <Drawer
        open={open}
        anchor={'right'}
        onClose={onClose}
        PaperProps={{
          sx: {
            maxWidth: '1400px',
            width: '60%',
            minWidth: '800px',
            backgroundColor: 'white',
          },
        }}
      >
        <Stack>
          {/* 닫기버튼  */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}
            sx={{ p: 2 }}
          >
            <Typography variant="subtitle2" color="primary">
              {`${groupInfo.campaignName} > ${groupInfo.groupName} > ${keywordInfo?.keyword}`}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>

          {/* 그래프 */}
          <Divider
            sx={{ height: '20px', backgroundColor: '#f2f2f2', border: 'none' }}
          ></Divider>
          <Box
            sx={{
              py: 2,
              px: 8,
              //  backgroundColor: '#f2f2f2'
            }}
          >
            <Stack
              direction="row"
              justifyContent={'space-between'}
              sx={{ py: 2 }}
            >
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {keywordInfo && keywordInfo.keyword}
              </Typography>
              <Stack direction={'row'} gap={2}>
                <MuiTooltip title="새로고침" arrow placement="top">
                  <IconButton onClick={handleResetZoom}>
                    <RefreshIcon />
                  </IconButton>
                </MuiTooltip>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">기간</InputLabel>
                  <Select
                    sx={{ width: '100px', height: '40px' }}
                    variant="outlined"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={range}
                    label="기간"
                    title="기간선택"
                    onChange={handleGraphRange}
                  >
                    <MenuItem value={3}>3시간</MenuItem>
                    <MenuItem value={6}>6시간</MenuItem>
                    <MenuItem value={24}>1일</MenuItem>
                    <MenuItem value={72}>3일</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            {!!data ? (
              <Line ref={chartRef} options={options} data={data} height={150} />
            ) : (
              <Skeleton variant="rounded" width={'100%'} height={220} />
            )}
          </Box>
          <Divider
            sx={{ height: '20px', backgroundColor: '#f2f2f2', border: 'none' }}
          ></Divider>

          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{ px: 10, py: 4 }}
            gap={1}
          >
            <Box>
              <Typography variant="caption">현재입찰금액</Typography>
              <Typography variant="h5" fontWeight={'bold'}>
                {keywordInfo && keywordInfo.current_bid_price.toLocaleString()}
                원
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">최대입찰금액</Typography>
              <Typography variant="h5" fontWeight={'bold'}>
                {keywordInfo && keywordInfo.maximum_bid_price.toLocaleString()}
                원
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">현재순위</Typography>
              <Typography variant="h5" fontWeight={'bold'}>
                {keywordInfo && keywordInfo.current_ranking.toLocaleString()}위
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">목표순위</Typography>
              <Typography variant="h5" fontWeight={'bold'}>
                {keywordInfo && keywordInfo.target_ranking.toLocaleString()}위
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">가감액</Typography>
              <Typography variant="h5" fontWeight={'bold'}>
                {keywordInfo && keywordInfo.adjustment_price.toLocaleString()}원
              </Typography>
            </Box>
          </Stack>
          {/* 그래프가 표현할 기간  */}
          <Box textAlign={'center'}>
            <Button
              variant="contained"
              sx={{ height: '40px', mt: 2 }}
              startIcon={<SaveAltIcon />}
              onClick={handleDownload}
            >
              엑셀 다운로드
            </Button>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};

export default KeywordDetail;

const boxStyle = {
  p: 3,
};

//변동액 계산 함수
const calculateVariation = (preValue: number, newValue: number) => {
  const up = '▲';
  const down = '▽';
  if (preValue === newValue) {
    return '-';
  } else if (preValue < newValue) {
    return up + (newValue - preValue);
  } else {
    return down + (preValue - newValue);
  }
};
