import { useQuery, useMutation } from '@tanstack/react-query';
import { adminAPI } from './adminAxios';
import { customToast } from '../../utils/customToast';
import { QUERY_KEY } from '../queryKey';
import queryClient from '../../config/react-query/react-query-config';
import {
  CustomerInfoType,
  SocketInfoType,
  getCustomerListType,
  updateCustomerInfoType,
} from './type';
//사용자 정보 가져오기
export const useGetCustomerListQuery = (data: getCustomerListType) => {
  return useQuery({
    queryKey: [
      'all',
      QUERY_KEY.customerInfoList,
      data.currentPage,
      data.rowsPerPage,
      data.searchQuery,
      data.sortOrder,
      data.sortType,
    ],
    queryFn: () => adminAPI.getCustomerList(data),
    select: (data) => {
      const result: { customerList: CustomerInfoType[]; totalCount: number } = {
        customerList: data.data.customerList,
        totalCount: data.data.totalCount,
      };
      return result;
    },
    retry: false,
  });
};

//사용자 정보 수정하기
export const useUpdateCustomerInfoMutation = (
  id: string,
  data: updateCustomerInfoType,
) => {
  return useMutation({
    mutationFn: () => adminAPI.updateCustomerInfo(id, data),
    onSuccess: (data) => {
      customToast('success', data.data.message);
      queryClient.invalidateQueries({
        queryKey: ['all', QUERY_KEY.customerInfoList], // 캐싱데이터 제거
        exact: false, //확실히 똑같은 key만지울건지 물어봄 난 query를 통해 그와 관련된걸 다지울것이기 때문에 false로 만듬
      });
    },
    onError: () => {
      customToast('error', '사용자 정보변경중 에러가 발생했습니다.');
    },
  });
};

//사용자 정보 가져오기
export const useGetSocketList = () => {
  return useQuery({
    queryKey: [QUERY_KEY.socketList],
    queryFn: () => adminAPI.getSocketList(),
    select: (data) => {
      const result: SocketInfoType[] = data.data;
      return result;
    },
    retry: false,
  });
};
