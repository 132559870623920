import * as React from 'react';
import {
  Typography,
  TablePagination,
  TableContainer,
  TableBody,
  Table,
  Box,
  Pagination,
  Card,
} from '@mui/material';

import {
  ShowColumnState,
  SearchQueryState,
  SearchQueryStateType,
  RowsPerPagesState,
  UserInfoState,
} from '../../../store/index';
import { useRecoilState, useRecoilValue } from 'recoil';
import KeywordDetail from './KeywordDetail';
import KeywordSetting from './KeywordSetting';
import KeywordGroupSetting from './KeywordGroupSetting';
import {
  makeKeywordActive,
  useSyncKeywordListByGroupId,
  useUpdateKeywordActive,
  useUpdateKeywordState,
} from '../../../service/keyword/keywordHooks';
import { SimpleBidData } from './KeywordGroupSetting';
import LoadingDialog from '../../loading/LoadingDialog';
import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { GroupType } from '../../../service/group/type';
import { TableOrderByState } from '../../../store/TableOrderBy';
import EnhancedTableRow from './EnhancedTableRow';
import { stableSort, getComparator } from './lib/bidTableLib';
import { QUERY_KEY } from '../../../service/queryKey';
import { useQueryClient } from '@tanstack/react-query';

export type KeywordStatusType = {
  bidCompleteInspectStatus: string;
  bidCompleteStatus: string;
  bidCompleteUserlock: boolean;

  groupStatus: string;
  groupStatusReason: string;
  groupUserlock: boolean;

  keywordInspectStatus: string;
  keywordStatus: string;
  keywordStatusReason: string;
  keywordUserlock: boolean;
};

export interface bidData {
  nccKeywordId: string;
  keyword: string; //키워드
  max_exposure: number; // 최대 노출 개수
  current_ranking: number; // 현재순위
  target_ranking: number; // 목표순위
  current_bid_price: number; //현재입찰금액
  maximum_bid_price: number; // 최대입찰금액
  adjustment_price: number; //가감액
  is_bidding_active: boolean; //입찰중 여부
  setting: boolean;
  keyword_sync_time: string;
  keyword_active: string;
}

export interface HeadCell {
  disablePadding: boolean; //간편보기
  id: keyof bidData;
  label: string;
  numeric: boolean;
}

export type Order = 'asc' | 'desc';

export const headCells: readonly HeadCell[] = [
  {
    id: 'keyword',
    numeric: false,
    disablePadding: true,
    label: '키워드',
  },
  {
    id: 'current_ranking',
    numeric: true,
    disablePadding: false,
    label: '현재 순위',
  },
  {
    id: 'max_exposure',
    numeric: true,
    disablePadding: true,
    label: '최대 노출',
  },
  {
    id: 'target_ranking',
    numeric: true,
    disablePadding: false,
    label: '목표 순위',
  },
  {
    id: 'current_bid_price',
    numeric: true,
    disablePadding: false,
    label: '현재입찰 금액',
  },
  {
    id: 'maximum_bid_price',
    numeric: true,
    disablePadding: false,
    label: '최대입찰 금액',
  },
  {
    id: 'adjustment_price',
    numeric: true,
    disablePadding: false,
    label: '가감액',
  },
  {
    id: 'is_bidding_active',
    numeric: true,
    disablePadding: false,
    label: '상태',
  },
  {
    id: 'keyword_active',
    numeric: true,
    disablePadding: false,
    label: '결과',
  },
  {
    id: 'setting',
    numeric: true,
    disablePadding: false,
    label: '설정',
  },
];

interface bidTableProps {
  data: GroupType; // 그룹정보
  keywordList?: Map<string, bidData>;
}
// 테이블 전체 조립 (body) ------------------------------------------------------------------------------------------------------
const BidTable: React.FC<bidTableProps> = ({
  data,
  keywordList = new Map<string, bidData>(),
}) => {
  const queryClient = useQueryClient();
  const [order, setOrder] = useState<Order>('asc'); //오름차순 내림차순
  // const [orderBy, setOrderBy] = useState<keyof bidData>('keyword'); //시간은 내림차순
  const [orderBy, setOrderBy] = useRecoilState(TableOrderByState);
  const [selected, setSelected] = useState<
    { nccKeywordId: string; keyword: string }[]
  >([]); //선택 된 키워드

  const [page, setPage] = useState(0); // 현재 페이지
  const [dense, setDense] = useState(false); //간편보기 여부
  const [rowsPerPageState, setRowsPerPageState] =
    useRecoilState(RowsPerPagesState);
  const [bidDataMap, setBidDataMap] =
    useState<Map<string, bidData>>(keywordList); //websocket으로 가져오는 데이터 편하게 업데이트 하려고 만듬
  const [showDataCount, setShowDataCount] = useState<number>(0);
  const [showColumn, setShowColumn] =
    useRecoilState<boolean[]>(ShowColumnState); //보여지는 컬럼배열 [false, true, true, false .. ]
  const searchQueryState =
    useRecoilValue<SearchQueryStateType>(SearchQueryState);
  const [isChange, setIsChange] = useState<boolean>(false); // 값이 변경된 이력이 있는지 확인하는 변수
  const [keywordInfo, setKeywordInfo] = useState<bidData>(); // 선택된 단일 키워드의 값
  const [keywordDetailOpen, setKeywordDetailOpen] = useState<boolean>(false); // 키워드 정보 오픈
  const [keywordSettingOpen, setKeywordSettingOpen] = useState<boolean>(false); //키워드 셋팅 오픈
  const [keywordGroupSetting, setKeywordGroupSetting] =
    useState<boolean>(false); // 키워드 그룹 셋팅 오픈
  const userInfo = useRecoilValue(UserInfoState);

  const { groupId } = data;
  const isBiddingActive = data.isBiddingActive === 'Y' ? true : false;

  // websocket 연결하기
  const webSocket = useRef<WebSocket | null>(null);

  // websocket 데이터 업데이트 함수
  const handleWebsocketMessage = (event: MessageEvent) => {
    const BidThreadResponseList = JSON.parse(!!event.data ? event.data : '[]');

    // 에러 날때
    if (!!BidThreadResponseList['errorCode']) {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.groups] }); //socket 가져올 때마다 업데이트하기 ----=-=-=--=--==-=-=-=-=-=- 에러 나면 이거 때문임
      // 정상적인 값이 넘어왔을때
    } else if (event.data !== '{}') {
      const newbidDataMap = new Map<string, bidData>(bidDataMap);
      BidThreadResponseList?.forEach((res: BidThreadResponseType) => {
        if (newbidDataMap.has(res.KEYWORD_ID)) {
          const temp = newbidDataMap.get(res.KEYWORD_ID);
          if (!!temp) {
            newbidDataMap.set(res!.KEYWORD_ID, {
              ...temp,
              current_bid_price: res.CURRENT_PRICE,
              current_ranking: res.CURRENT_RANK
                ? res.CURRENT_RANK
                : temp.current_ranking,
              max_exposure: res.MAX_EXPOSURE_COUNT ? res.MAX_EXPOSURE_COUNT : 0,
              keyword_active: makeKeywordActive(
                res.KEYWORD_BID_COMPLETE_TIME,
                res.KEYWORD_ACTIVE,
                res.ERROR_HTTP_MESSAGE,
                res.KEYWORD_STATUS_REASON,
              ),
              // keyword_active: !!res.KEYWORD_BID_COMPLETE_TIME
              //   ? res.KEYWORD_BID_COMPLETE_TIME
              //   : res.KEYWORD_ACTIVE + ' ' + res.KEYWORD_STATUS_REASON,
              // status: {
              //   bidCompleteInspectStatus: res.BID_COMPLETE_INSPECT_STATUS,
              //   bidCompleteStatus: res.BID_COMPLETE_STATUS,
              //   bidCompleteUserlock: res.BID_COMPLETE_USERLOCK,

              //   groupStatus: res.GROUP_STATUS,
              //   groupStatusReason: res.GROUP_STATUS_REASON,
              //   groupUserlock: res.GROUP_USER_LOCK,

              //   keywordInspectStatus: res.KEYWORD_INSPECT_STATUS,
              //   keywordStatus: res.KEYWORD_STATUS,
              //   keywordStatusReason: res.KEYWORD_STATUS_REASON,
              //   keywordUserlock: res.KEYWORD_USER_LOCK,
              // },
            });
          }
        }
      });
      setBidDataMap(newbidDataMap); //이게 리렌더링을 발생시킴 => 근데 그 이후에 이 소켓 연결은 동작하지 않음 ..
    }
  };

  // 불러온 값 셋팅
  useEffect(() => {
    if (!!keywordList && groupId) {
      // setBidDataMap(keywordList);

      setBidDataMap(() => {
        //[]로 변경해서 정렬하고 다시 map 에 넣기 위함
        const newBidData = Array.from(keywordList.values());
        return new Map(
          stableSort(
            newBidData,
            getComparator(order, orderBy),
          ).map((item) => [item.nccKeywordId, item]),
        );
      });

      setShowDataCount(keywordList.size);
    }
  }, [keywordList]);

  useEffect(() => {
    // console.log('원본 데이터 >>>', bidDataMap);
    if (isBiddingActive && !!webSocket) {
      webSocket.current = new WebSocket(
        `wss://biddingeasy.co.kr/ws/${userInfo.CUSTOMER_ID}/${groupId}`,
      );
      webSocket.current.onopen = () => {
//        console.log('WebSocket 연결!');
      };
      webSocket.current.onclose = (error) => {
        // console.log('websocket 연결 끊김');
      };
      webSocket.current.onerror = (error) => {
        // console.log('websocket 연결 에러');
      };
      webSocket.current.onmessage = handleWebsocketMessage;
    }

    // useEffect 마운트 해제될 때, 의존성 배열에 의한 재실행 전
    return () => {
      webSocket.current?.close();
      // console.log('useEffect return 에 의한 socket 연결 끊김');
    };
    // }, [isBiddingActive]);
  }, [isBiddingActive, bidDataMap]);

  //hook
  const { mutateAsync: updateKeyword } = useUpdateKeywordActive();
  const { mutateAsync: syncKeywordList, isPending } =
    useSyncKeywordListByGroupId(groupId);

  const { mutateAsync } = useUpdateKeywordState();

  //검색을 하면 1page로 돌아가기위한 함수
  useEffect(() => {
    setPage(0); // 검색을 하면 1page로 돌아가기 위한 함수
  }, [searchQueryState, groupId]);

  //키워드 상태 변경
  const handleUpdateBidInfo = async (keywordId: string) => {
    //키워드 상태 변경하기
    //로직 : rows를 먼저 변경하고 변경이 적용됐다면 bidData를 불러온다. 그렇지 않다면 bidData저장되어있는걸 가져온다.
    if (isChange) {
      const bidData = bidDataMap.get(keywordId)!;

      //로직 => mutate로 값변경 => queryClient.invalidate key => bidDataMap 변경 => rows 변경
      const {
        target_ranking,
        maximum_bid_price,
        adjustment_price,
        is_bidding_active,
        current_bid_price,
      } = bidData;
      await mutateAsync({
        keywordId,
        target_ranking,
        maximum_bid_price,
        adjustment_price,
        isBiddingActive: is_bidding_active ? 'Y' : 'N',
        current_bid_price,
      });
      setIsChange(false);
    }
  };

  //keywordGroupSetting 설정할 때 로컬값을 변경하기위해 임시로 설정하기 위해 처리해둠
  const handleChangeRows = (
    data: SimpleBidData,
    selectedKeywordList: { nccKeywordId: string; keyword: string }[],
  ) => {
    //값 변경 (new)
    setBidDataMap((prev) => {
      const newBidData = prev;
      selectedKeywordList.forEach(({ nccKeywordId }) => {
        //순회하면서 axios 요청 보내기
        mutateAsync({
          keywordId: nccKeywordId,
          target_ranking: data.target_ranking,
          maximum_bid_price: data.maximum_bid_price,
          adjustment_price: data.adjustment_price,
          isBiddingActive: data.is_bidding_active ? 'Y' : 'N',
          current_bid_price: data.current_bid_price,
        });
      });
      return newBidData;
    });
  };

  const handleKeywordRefetch = async () => {
    await syncKeywordList();
  };

  const handleKeywordGroupSetting = () => {
    setKeywordGroupSetting((pre) => !pre);
  };

  //키워드 입찰정보 셋팅 여닫는 함수
  const handleKeywordSetting = () => {
    setKeywordSettingOpen((pre) => !pre);
  };

  //keywordDetail 여닫는 함수
  const handleKeywordDetail = () => {
    setKeywordDetailOpen(!keywordDetailOpen);
  };

  //keywordInfo 값셋팅하는 함수  keywordDetail 컴포넌트에서 사용할거임
  const handleKeywordInfo = (keywordId: string) => {
    const bidData = bidDataMap.get(keywordId);
    if (bidData) {
      setKeywordInfo(bidData);
    }
  };

  //current_bid_price를 변경하는 함수
  const handleUpdateCurrentBidPrice = (nccKeywordId: string, value: number) => {
    const newBidData = new Map(bidDataMap); //불변성을 유지하기 위한 값 복사
    const data = newBidData.get(nccKeywordId)!; //기존 값 출력
    newBidData.set(nccKeywordId, {
      ...data,
      current_bid_price: value > 999999 ? 999999 : value,
    }); //데이터 변경
    setIsChange(true); //변경사항 저장
    setBidDataMap(newBidData); //반영
  };

  //maximum_bid_price 값을 변경하는 함수
  const handleUpdateMaximumBidPrice = (nccKeywordId: string, value: number) => {
    const newBidData = new Map(bidDataMap); //불변성을 유지하기 위한 값 복사
    const data = newBidData.get(nccKeywordId)!; //기존 값 출력
    newBidData.set(nccKeywordId, {
      ...data,
      maximum_bid_price: value > 999999 ? 999999 : value,
    }); //데이터 변경
    setIsChange(true); //변경사항 저장
    setBidDataMap(newBidData); //반영
  };

  //target_ranking 값을 변경하는 함수
  const handleUpdateTargetRanking = (nccKeywordId: string, value: number) => {
    const newBidData = new Map(bidDataMap); //불변성을 유지하기 위한 값 복사
    const data = newBidData.get(nccKeywordId)!; //기존 값 출력
    newBidData.set(nccKeywordId, {
      ...data,
      target_ranking: value > 15 ? 15 : value,
    }); //데이터 변경
    setIsChange(true); //변경사항 저장
    setBidDataMap(newBidData); //반영
  };

  //adjustment_price 값을 변경하는 함수
  const handleUpdateAdjustmentPrice = (ncckeywordId: string, value: number) => {
    const newBidData = new Map(bidDataMap); //불변성을 유지하기 위한 값 복사
    const data = newBidData.get(ncckeywordId)!; //기존 값 출력
    newBidData.set(ncckeywordId, {
      ...data,
      adjustment_price: value > 5000 ? 5000 : value,
    }); //데이터 변경
    setIsChange(true); //변경사항 저장
    setBidDataMap(newBidData); //반영
  };

  const handleChangeKeywordBiddingActive = (
    ncckeywordId: string,
    isBiddingActive: boolean,
  ) => {
    const newBidData = new Map(bidDataMap); //불변성을 유지하기 위한 값 복사
    const data = newBidData.get(ncckeywordId)!; //기존 값 출력 !는 값이 무조건 있다는 표시
    newBidData.set(ncckeywordId, {
      ...data,
      is_bidding_active: !data.is_bidding_active,
    }); //데이터 변경
    setBidDataMap(newBidData); //반영
    updateKeyword({
      keywordId: ncckeywordId,
      isCheck: !isBiddingActive ? 'Y' : 'N', // 서로 반대로 넘겨주어야한다. 정지중일때는 시작 시작일때는 정지로
    });
  };

  //정렬 함수
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof bidData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    setBidDataMap((prev) => {
      //[]로 변경해서 정렬하고 다시 map 에 넣기 위함
      const newBidData = Array.from(prev.values());
      return new Map(
        stableSort(
          newBidData,
          getComparator(isAsc ? 'asc' : 'desc', property),
        ).map((item) => [item.nccKeywordId, item]),
      );
    });
  };

  const rows = useMemo(() => {
    return Array.from(bidDataMap.values());
  }, [bidDataMap]);

  //전체 클릭 함수
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    //전부 선택 되었을 때
    if (event.target.checked) {
      const newSelected = filteredRows(rows).map((n) => ({
        nccKeywordId: n.nccKeywordId,
        keyword: n.keyword,
      }));
      setSelected(newSelected);
      return;
    }

    //선택 되지 않는다면 전부 취소
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    nccKeywordId: string,
    keyword: string,
  ) => {
    const itemIndex = selected.findIndex(
      (item) => item.nccKeywordId === nccKeywordId && item.keyword === keyword,
    );
    let newSelected: { nccKeywordId: string; keyword: string }[];

    if (itemIndex === -1) {
      // 주어진 nccKeywordId와 keyword가 selected 배열에 없으면 추가
      newSelected = [...selected, { nccKeywordId, keyword }];
    } else {
      // 주어진 nccKeywordId와 keyword가 selected 배열에 있으면 제거
      newSelected = selected.filter(
        (item) =>
          !(item.nccKeywordId === nccKeywordId && item.keyword === keyword),
      );
    }
    setSelected(newSelected);
  };

  //페이지 증감 함수
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPageState(parseInt(event.target.value, 10)); //10진수로 변환한다.
    setPage(0);
  };

  //간편보기 설정하는 함수
  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
    if (event.target.checked) {
      setShowColumn([
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        true,
        true,
        true,
      ]);
    } else {
      setShowColumn([
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ]);
    }
  };

  //선택 되었다면 check 표시만들어둠
  const isSelected = (nccKeywordId: string, keyword: string) =>
    // selected.indexOf({ nccKeywordId, keyword }) !== -1;
    selected.some(
      (item) => item.nccKeywordId === nccKeywordId && item.keyword === keyword,
    );

  //검색 필터
  const filteredRows = useCallback(
    (rows: bidData[]): bidData[] => {
      if (!rows) {
        return [];
      }

      return rows.filter(
        (item) =>
          item.keyword.includes(searchQueryState.keyword) &&
          ((searchQueryState.bidding_start === 1) === item.is_bidding_active || // 입찰 중인지
            (searchQueryState.bidding_stop === 1) !== item.is_bidding_active) && // 입찰 정지했는지
          searchQueryState.current_ranking_min <= item.current_ranking && // 현재 순위
          searchQueryState.current_ranking_max >= item.current_ranking &&
          searchQueryState.target_ranking_min <= item.target_ranking && //목표 순위
          searchQueryState.target_ranking_max >= item.target_ranking &&
          searchQueryState.current_bid_price_min <=
            Math.abs(item.current_bid_price) && // 현재입찰가
          searchQueryState.current_bid_price_max >=
            Math.abs(item.current_bid_price) &&
          searchQueryState.maximum_bid_price_min <= item.maximum_bid_price && //목표입찰가
          searchQueryState.maximum_bid_price_max >= item.maximum_bid_price &&
          searchQueryState.adjustment_price_min <= item.adjustment_price && //가감액
          searchQueryState.adjustment_price_max >= item.adjustment_price,
      ); // 기존걸 놔두고 필터링을 진행할 수 있다.
    },
    [searchQueryState, bidDataMap],
  );

  //랜더링을 최소화 하기 위해 변경되기 전에는 보여지는 부분의 랜더링을 캐싱한다.
  const visibleRows = useMemo(() => {
    //키워드에 따라 걸러진 값
    const newRows: bidData[] = filteredRows(rows);
    //걸러진 값들의 길이를 저장하는 변수,  페이지의 갯수를 나타내기 위함
    setShowDataCount(newRows.length);

    return newRows.slice(
      page * rowsPerPageState,
      page * rowsPerPageState + rowsPerPageState,
    );
  }, [order, orderBy, page, rowsPerPageState, rows, filteredRows]);

  return (
    <>
      <LoadingDialog open={isPending} />

      <Card
        sx={{
          mb: 4,
        }}
      >
        {' '}
        {/* 외부 테두리 설정 가능  */}
        <EnhancedTableToolbar
          keywordIdList={selected.map((item) => item.nccKeywordId)}
          handleKeywordRefetch={handleKeywordRefetch}
          handleChangeDense={handleChangeDense}
          updateTime={!!rows[0] ? rows[0].keyword_sync_time : '없음'}
          dense={dense}
          groupInfo={data}
          numSelected={selected.length}
          onOpen={handleKeywordGroupSetting}
        />
        <TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={showDataCount}
            rowsPerPage={rowsPerPageState}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={'div'} //액션 버튼 지우기
            labelRowsPerPage="페이지 당 개수"
            labelDisplayedRows={({ from, to, count }) =>
              `총 키워드 개수 ${count}`
            } // 변경하려는 문구로 설정
          />
          <Table
            sx={{
              p: 0,
              borderSpacing: '0px 0px',
              // borderCollapse: 'separate',
            }}
            aria-labelledby="tableTitle"
            // size={dense ? 'small' : 'medium'}
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={showDataCount}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(
                  row.nccKeywordId,
                  row.keyword,
                );
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <EnhancedTableRow
                    key={row.nccKeywordId}
                    index={index}
                    labelId={labelId}
                    handleChangeKeywordBiddingActive={
                      handleChangeKeywordBiddingActive
                    } //입찰중인지 여부 변경
                    handleClick={handleClick}
                    handleKeywordDetail={handleKeywordDetail} // 디테일 오픈
                    handleKeywordInfo={handleKeywordInfo} //키워드 정보 담기
                    handleKeywordSetting={handleKeywordSetting} // 키워드 셋팅 및 키워드 스케줄링 변경 오픈
                    handleUpdateAdjustmentPrice={handleUpdateAdjustmentPrice} //가감액 변경
                    handleUpdateBidInfo={handleUpdateBidInfo} //입찰정보 변경
                    handleUpdateCurrentBidPrice={handleUpdateCurrentBidPrice} //  현재 입찰가 변경
                    handleUpdateMaximumBidPrice={handleUpdateMaximumBidPrice} //최대 입찰가 변경
                    handleUpdateTargetRanking={handleUpdateTargetRanking} //목표 순위 변경
                    isBiddingActive={isBiddingActive} //입찰중인지
                    isItemSelected={isItemSelected} //선택된 아이템인지 확인
                    row={row} //키워드 정보
                    showColumn={showColumn} //보여지는 컬럼
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          component={'div'}
          sx={{
            pt: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Pagination
            shape="rounded"
            count={Math.ceil(showDataCount / rowsPerPageState)}
            onChange={(e, page) => {
              setPage(page - 1);
            }}
            page={page + 1}
          />
        </Box>
      </Card>
      {/* 키워드 설정등을 클릭했을 때 나타나는 Drawer 컨텐츠 모음  */}
      <KeywordDetail
        groupInfo={data}
        open={keywordDetailOpen}
        onClose={handleKeywordDetail}
        keywordInfo={keywordInfo}
      />
      <KeywordSetting
        groupInfo={data}
        open={keywordSettingOpen}
        onClose={handleKeywordSetting}
        keywordId={keywordInfo?.nccKeywordId ? keywordInfo.nccKeywordId : ''}
      />
      <KeywordGroupSetting
        groupInfo={data}
        open={keywordGroupSetting}
        onClose={handleKeywordGroupSetting}
        keywordGroupInfo={selected}
        handleChangeRows={handleChangeRows}
      />
    </>
  );
};

export default BidTable;

export type BidThreadResponseType = {
  ADD_SUBTRACT_PRICE: number;
  CURRENT_RANK?: number;
  CURRENT_PRICE: number;
  DISPLAY_URL?: string;
  GRP_ID?: string;
  KEYWORD_ID: string;
  KEYWORD_NAME: string;
  MAX_BID_PRICE: string;
  MAX_EXPOSURE_COUNT?: number;
  STEP1: string;
  STEP2?: string;
  STEP3?: string;
  STEP3_INSPECT_STATUS?: string;
  STEP3_STATUS?: string;
  STEP3_USERLOCK?: boolean;
  TARGET_RANK: number;
  ORIGIN_CURRENT_PRICE?: number;
  KEYWORD_ACTIVE: string;
  KEYWORD_BID_COMPLETE_TIME: string | null;
  ERROR_HTTP_MESSAGE: string;

  GROUP_STATUS: string;
  GROUP_STATUS_REASON: string;
  GROUP_USER_LOCK: boolean;

  KEYWORD_INSPECT_STATUS: string;
  KEYWORD_STATUS: string;
  KEYWORD_STATUS_REASON: string;
  KEYWORD_USER_LOCK: boolean;

  BID_COMPLETE_INSPECT_STATUS: string;
  BID_COMPLETE_STATUS: string;
  BID_COMPLETE_USERLOCK: boolean;
};
