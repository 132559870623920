import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  List,
  Collapse,
  Box,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material';

import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Settings from '@mui/icons-material/Settings';
import { useState } from 'react';
import GroupItem from './GroupItem';
import { GroupType } from '../../../service/group/type';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
interface CampaignItemProps {
  campainTitle: string;
  groupList: Map<string, GroupType>;
}

const CampainItem: React.FC<CampaignItemProps> = ({
  campainTitle,
  groupList,
}) => {
  const [listOpen, setListOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleListOpen = () => {
    setListOpen(!listOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();

  return (
    <>
      <List sx={{ p: 0 }}>
        <ListItem
          component="div"
          sx={{
            backgroundColor: 'background.paper',
            color: theme.palette.grey[800],
            p: 0,
            pl: 1,
            '&:hover': {
              backgroundColor: theme.palette.grey[200],
              cursor: 'pointer',
            },
          }}
        >
          <Box sx={{ mr: 2, display: 'flex', alignContent: 'center' }}>
            {listOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </Box>
          <ListItemText onClick={handleListOpen}>
            <Typography
              variant="subtitle2"
              fontSize={'bold'}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                py: 1,
              }}
            >
              {campainTitle}
            </Typography>
          </ListItemText>

          {/* 햄버거 버튼 */}
          {/* <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton> */}

          {/* 그룹 목록 */}
        </ListItem>
        <Collapse in={listOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {groupList &&
              Array.from(groupList.values()).map(
                (item: GroupType, i: number) => (
                  <GroupItem data={item} key={i} />
                ),
              )}
          </List>
        </Collapse>
      </List>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PlayCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          전체 입찰 시작
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <StopCircleIcon fontSize="small" />
          </ListItemIcon>
          전체 입찰 중지
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          캠페인 설정
        </MenuItem>
      </Menu>
    </>
  );
};

export default CampainItem;
