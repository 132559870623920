import axios from 'axios';
import { useEffect } from 'react';

// 컴포넌트 내에서 사용할 함수
const getAccessTokenFromUrl = () => {
  const hash = window.location.hash.substring(1); // '#' 제거
  const params = new URLSearchParams(hash);
  return params.get('access_token');
};

const Callback: React.FC = () => {
  useEffect(() => {
    const accessToken = getAccessTokenFromUrl();
    // console.log({ accessToken });
    axios
      .post('https://localhost:8000/naver-login', { accessToken })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  });

  return <>loading...</>;
};

export default Callback;
