import {  AtomEffect, atom } from 'recoil';
import LOCALSTORAGE_KEY from './localStageKey';

// localStorage 와 동기화 하는 함수
const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (!!savedValue) {
      setSelf(JSON.parse(savedValue));
    }
    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

//값을 초기화 하는 함수
export type SelectedUserInfoStateType = {
  ID: string;
  CUSTOMER_NAME: string;
  TYPE:'PARENT' | 'CHILDREN';
};

export const SelectedUserInfoState = atom<SelectedUserInfoStateType>({
  key: 'SelectedUserInfoState',
  default: {
    ID: '',
    CUSTOMER_NAME: '',
    TYPE:'PARENT'
  
  },
  effects: [localStorageEffect(LOCALSTORAGE_KEY.SELECTED_USER_INFO)],
});
