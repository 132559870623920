import { AtomEffect, atom } from 'recoil';
import LOCALSTORAGE_KEY from './localStageKey';


/**
 * 역할 : 로그인 여부 판단 
 * (로그인 여부란 자동로그인 or 직접 로그인을 통해 접속함을 의미)
 * sessionStorage로 관리하기 때문에 브라우저를 끄면 자동으로 false로 초기화됨 
 */

//localStorage 와 동기화 하는 함수
const SessionStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? sessionStorage.removeItem(key)
        : sessionStorage.setItem(key, JSON.stringify(newValue));
    });
  };

  
export const IsLoginState = atom<boolean>({
  key: 'IsLoginState',
  default: false,
  effects: [SessionStorageEffect(LOCALSTORAGE_KEY.IS_LOGIN)],
});
