import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Card,
  Pagination,
  Grid,
  SelectChangeEvent,
  OutlinedInput,
  Stack,
} from '@mui/material';
import * as React from 'react';
import { visuallyHidden } from '@mui/utils';
import { useGetSocketList } from '../../../service/admin/adminHooks';
import SearchIcon from '@mui/icons-material/Search';

type Data = {
  ID: string;
  CUSTOMER_NAME: string;
  NAME: string;
  PHONE_NUMBER: string;
  EMAIL: string;
  EXPIRATION_DATE: string;
  PAY_YN: string;
  CMP_NAME: string;
  GRP_ID: string;
  GROUP_STATUS: string;
  GRP_NAME: string;
  DEVICE_NM: string;
  IS_CHECK: string;
  REGION1: string;
  REGION2: string;
  REGION3: string;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              p: 1,
              pr: 0,
              whiteSpace: 'nowrap',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{
                color: orderBy === headCell.id ? 'primary' : 'black',
                fontWeight: orderBy === headCell.id ? 'bold' : 'normal',
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

//검색량 테이블 ----------------------------------------------------------------------------------------------------------------------------------------------------------------
const SocketTable: React.FC = () => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('CUSTOMER_NAME');
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const { data: rows ,refetch} = useGetSocketList();


  const handleChangeSearchQuery = (e:React.ChangeEvent<HTMLInputElement>) =>{
    const {value} = e.target;
    setSearchQuery(value);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const cellStyle = (index: number) => {
    return {
      whiteSpace: 'nowrap',
      backgroundColor: index % 2 === 0 ? '#fafafa' : 'white',
      p: 1,
      mr: 2,
    };
  };

  //실제로 보이는 줄
  const visibleRows = React.useMemo(() => {
    return !!rows
      ? stableSort(rows, getComparator(order, orderBy))
          .filter((item) => item.CUSTOMER_NAME.includes(searchQuery))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];
  }, [order, orderBy, page, rowsPerPage, searchQuery, rows]);

  return (
    <Stack gap={2} sx={{ width: '1400px', p: 3 , margin:"0 auto"}}>
      <Typography variant="h5">소켓 사용 현황 </Typography>
      <Stack direction={'row'} gap={1} justifyContent={"flex-end"}>
        <OutlinedInput
          endAdornment={<SearchIcon />}
          placeholder="검색어를 입력해주세요"
          name="searchQuery"
          size="small"
          value={searchQuery}
          sx={{ backgroundColor: 'background.paper' }}
          onChange={handleChangeSearchQuery}
        />
        <Button
          variant="outlined"
          sx={{ height: '36.7px', backgroundColor: 'background.paper' }}
          onClick={()=>{
            refetch()
          }}
        >
          새로고침
        </Button>
      </Stack>
      {!!rows && (
        <Grid container sx={{ width: '100%' }} rowSpacing={1}>
          <Grid item xs={12} sx={{ width: '100%', mt: 2 }}>
            <Paper>
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {!!rows &&
                      visibleRows.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell
                              sx={{ ...cellStyle(index), whiteSpace: 'nowrap' }}
                              component="th"
                              id={labelId}
                              scope="row"
                            >
                              {row.CUSTOMER_NAME}
                            </TableCell>
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.ID}
                            </TableCell>
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.PHONE_NUMBER}
                            </TableCell>
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.EMAIL}
                            </TableCell>
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.EXPIRATION_DATE}
                            </TableCell>
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.CMP_NAME}
                            </TableCell>
                            {/* <TableCell sx={cellStyle(index)} align="left">
                              {row.GRP_ID}
                            </TableCell> */}
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.GRP_NAME}
                            </TableCell>
                            {/* <TableCell sx={cellStyle(index)} align="left">
                              {row.GROUP_STATUS}
                            </TableCell> */}
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.DEVICE_NM}
                            </TableCell>
                            {/* <TableCell sx={cellStyle(index)} align="left">
                              {row.IS_CHECK}
                            </TableCell> */}
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.REGION1}
                            </TableCell>
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.REGION2}
                            </TableCell>
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.REGION3}
                            </TableCell>
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.NAME}
                            </TableCell>
                            <TableCell sx={cellStyle(index)} align="left">
                              {row.PAY_YN}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* 페이지 네이션 */}
              <Box
                sx={{
                  p: 4,
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <Typography variant="body2">
                  전체 {visibleRows.length}개 중 {1 + page * rowsPerPage}~
                  {Math.min(
                    rows
                    .filter((row) => row.CUSTOMER_NAME.includes(searchQuery))
                    .length,
                    (page + 1) * rowsPerPage,
                  )}
                </Typography>
                <Pagination
                  count={Math.ceil(
                    rows
                    .filter((item) => item.CUSTOMER_NAME.includes(searchQuery))
                    .length / rowsPerPage,
                  )}
                  onChange={handleChangePage}
                  color="primary"
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default SocketTable;

const headCells: readonly HeadCell[] = [
  {
    id: 'CUSTOMER_NAME',
    label: '이름',
  },
  {
    id: 'ID',
    label: '아이디',
  },
  {
    id: 'PHONE_NUMBER',
    label: '전화번호',
  },
  {
    id: 'EMAIL',
    label: '이메일',
  },
  {
    id: 'EXPIRATION_DATE',
    label: '만료일',
  },
  {
    id: 'CMP_NAME',
    label: '캠페인 이름',
  },
  // {
  //   id: 'GRP_ID',
  //   label: '그룹 아이디',
  // },
  // {
  //   id: 'GROUP_STATUS',
  //   label: '그룹상태',
  // },
  {
    id: 'GRP_NAME',
    label: '그룹이름',
  },
  {
    id: 'DEVICE_NM',
    label: '광고매체',
  },
  // {
  //   id: 'IS_CHECK',
  //   label: '상태',
  // },
  {
    id: 'REGION1',
    label: 'REGION1',
  },
  {
    id: 'REGION2',
    label: 'REGION2',
  },
  {
    id: 'REGION3',
    label: 'REGION3',
  },
  {
    id: 'NAME',
    label: '요금제',
  },
  {
    id: 'PAY_YN',
    label: '요금지불 여부',
  },
];
