import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

interface LoadingProps {
  open: boolean;
  title?: string;
}
const LoadingDialog: React.FC<LoadingProps> = ({ open, title = '' }) => {
  return (
    <React.Fragment>
      <Dialog
        sx={{
          p: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            textAlign={'center'}
            sx={{ mt: 8, mb: 3 }}
          >
            <CircularProgress />
          </DialogContentText>
          <DialogTitle id="alert-dialog-title" align="center">
            {title ? title : '데이터를 동기화 하는중입니다.'}
          </DialogTitle>
          <DialogContentText id="alert-dialog-description" textAlign={'center'}>
            <Typography variant="caption">
              서버에서 데이터를 불러오는 중 입니다.
              <br /> 잠시만 기다려주세요
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default LoadingDialog;
