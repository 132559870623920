

const LOCALSTORAGE_KEY = {
    USER_INFO:"USER_INFO",
    SELECTED_USER_INFO:"SELECTED_USER_INFO",
    SHOW_COLUMN:"SHOW_COLUMN",
    REMEMBER_ME:"REMEMBER_ME",
    IS_LOGIN:"IS_LOGIN",
    PATH:"path",
    GROUP_INFO:"GROUP_INFO",
    ROWSPERPAGE:"ROWSPERPAGE",
}


export default LOCALSTORAGE_KEY;