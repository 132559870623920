import { userAPI } from './userAxios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { customToast } from '../../utils/customToast';
import queryClient from '../../config/react-query/react-query-config';
import {
  UserInfoState,
  RememberMeState,
  IsLoginState,
} from '../../store/index';
import { QUERY_KEY } from '../queryKey';
import {
  signUpDateType,
  UpdateUserInfoType,
  NaverApiType,
  DaliyBidCountType,
  KeywordUsageType,
  SubUserListType,
  BookmarkType,
  ChildrenBidCountType,
} from './type';
import { SelectedUserInfoState } from '../../store/selectedUserInfoState';

export const useTestQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEY.login],
    queryFn: () => userAPI.getData(),
    select: (data) => data + 'select 로 보정된 데이터',
    retry: false,
  });
};

//로그인 함수
export const useLoginMutation = (id: string, pw: string) => {
  //로그인 성공시 페이지 이동
  const navigate = useNavigate();
  //로그인 성공시 userinfo 불러와서 저장
  const setUserInfoState = useSetRecoilState(UserInfoState);
  const setIsLoginState = useSetRecoilState(IsLoginState);
  const setSelectedUserInfoState = useSetRecoilState(SelectedUserInfoState);

  return useMutation({
    mutationFn: () => userAPI.login(id, pw),
    onSuccess: async () => {
      //사용자 정보 불러와서 userInfoData에 세팅
      const userInfoData = await userAPI.getInfo(); // 로그인이 성공했기 때문에 이게 실패할 일이 없음
      setIsLoginState(true); //로그인 했는지 여부 판별하는 전역 변수(sessionStorage에 저장됨)
      setUserInfoState(userInfoData);

      // console.log('login() 함수의 onSuccess >>> ', userInfoData);
      //mypage에서 보여질 정보 저장
      setSelectedUserInfoState({
        ID: userInfoData.ID,
        CUSTOMER_NAME: userInfoData.CUSTOMER_NAME,
        TYPE: 'PARENT',
      });

      navigate('/mypage'); //메인페이지로 이동
    },
    onError: (err) => {
      // console.log('login() 함수의 onError >>> ', err);
      customToast('error', '아이디 또는 비밀번호가 잘못되었습니다.');
    },
  });
};

//로그아웃 함수
export const useLogoutMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setSelectedUserInfoState = useSetRecoilState(SelectedUserInfoState);
  const setIsLoginState = useSetRecoilState(IsLoginState);
  const setRememberMeState = useSetRecoilState(RememberMeState);

  const logout = async () => {
    try {
      // 1. cookie 제거
      await userAPI.logout();
    } finally {
      // 3. 로그인 상태 변경
      queryClient.clear(); // 캐싱된 데이터 모두 제거
      setSelectedUserInfoState({
        ID: '',
        CUSTOMER_NAME: '',
        TYPE: 'PARENT',
      });
      setIsLoginState(false);
      setRememberMeState(false);
      // 4. login 페이지로 이동
      navigate('/login', { replace: true });
    }
  };

  return logout; // 로그아웃 함수를 반환
};

//자동로그인 함수 ( 이함수는 remember_me가 true일때만 동작합니다. )
export const useAutoLoginMutation = () => {
  const [, setUserInfoState] = useRecoilState(UserInfoState);
  const [isLoginState, setIsLoginState] = useRecoilState(IsLoginState);
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogoutMutation();
  const rememberMeState = useRecoilValue(RememberMeState);

  const autoLogin = async () => {
    try {
      //로그인 정보를 기억한다면
      if (rememberMeState || isLoginState) {
        //새로운 사용자 정보 가져오기
        const newValue = await userAPI.getInfo();
        setUserInfoState(newValue);
        setIsLoginState(true);
        //만약 현재 페이지 경로확인
        if (location.pathname === '/login' || location.pathname === '/') {
          //자동로그인 후 페이지 이동
          navigate('/mypage', { replace: true });
        }
      } else {
        //로그인 정보를 기억하지 않는다면 logout을 실행해 모든정보 삭제
        logout();
      }
    } catch {
      //사용자 정보를 가져오지 못했다면 로그아웃 => 이경우는 refreshToken이 만료된것
      logout();
    }
  };
  return autoLogin;
};

//회원가입 함수
export const useSignUpMutation = (data: signUpDateType) => {
  //회원가입 성공시 페이지 이동
  const navigate = useNavigate();

  return useMutation({
    mutationFn: () => userAPI.signUp(data),
    onSuccess: () => {
      customToast('success', '회원가입에 성공했습니다.');
      navigate('/login');
    },
    onError: () => {
      customToast(
        'error',
        '회원가입 중에 문제가 발생했습니다. 잠시 후 다시 시도해주세요.',
      );
    },
  });
};

//회원정보 수정 함수
export const useUserInfoUpdateMutation = (data: UpdateUserInfoType) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: () => userAPI.updateInfo(data),
    onSuccess: () => {
      // customToast('success', '정보를 변경했습니다. ');
      navigate('/mypage');
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.campaigns, QUERY_KEY.groups], // 캐싱데이터 제거
        exact: false, //확실히 똑같은 key만지울건지 물어봄 난 query를 통해 그와 관련된걸 다지울것이기 때문에 false로 만듬
        refetchType: 'none', //다시 가져오는것은 반려 => 사용자가 알림을 봐야하기 때문
      });
    },
    onError: () => {
      customToast(
        'error',
        '정보를 변경하는 중에 문제가 발생했습니다. 잠시 후 다시 시도해주세요.',
      );
    },
  });
};

//회원정보 수정 함수
export const useUpdateNaverApi = () => {
  return useMutation({
    mutationFn: (data: NaverApiType) => userAPI.updateNaverAPI(data),
    onSuccess: () => {
      customToast('success', '정보를 변경했습니다. ');
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.campaigns], // 캐싱데이터 제거
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.groups], // 캐싱데이터 제거
      });
    },
    onError: () => {
      customToast(
        'error',
        '정보를 변경하는 중에 문제가 발생했습니다. 잠시 후 다시 시도해주세요.',
      );
    },
  });
};

//일일 입찰수
export const useDailyBidCount = () => {
  return useQuery<DaliyBidCountType, Error>({
    queryKey: [QUERY_KEY.bidCount],
    queryFn: () => userAPI.getDailyBidCount(),
    select: (data) => {
      return data;
    },
  });
};

// 키워드 사용량
export const useKeywordUsage = () => {
  return useQuery<KeywordUsageType, Error>({
    queryKey: [QUERY_KEY.keywordUsage],
    queryFn: () => userAPI.getKeywordUsage(),
    select: (data) => {
      return data;
    },
  });
};

//서브유저리스트 가져오기
export const useGetSubUserList = () => {
  return useQuery<SubUserListType, Error>({
    queryKey: [QUERY_KEY.subUserList],
    queryFn: () => userAPI.getSubUserList(),
    select: (data) => {
      return data;
    },
  });
};

//부계정 북마크 변경함수
export const useUpdateBookmark = () => {
  return useMutation({
    mutationFn: (data: BookmarkType) => userAPI.updateBookmark(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.subUserList], // 캐싱데이터 제거
      });
    },
    onError: () => {
      customToast(
        'error',
        '북마크정보를 변경하는데 문제가 발생했습니다. 잠시 후 다시 시도해주세요.',
      );
    },
  });
};

// 자식계정 일일 입찰수 조회
export const useGetChildrenBidCountList = () => {
  return useQuery<ChildrenBidCountType[], Error>({
    queryKey: [QUERY_KEY.childrenBidCountList],
    queryFn: () => userAPI.getChildrenBidCountList(),
    select: (data) => {
      return data;
    },
    placeholderData: [], // 기본값 설정
    initialData: [], // 초기값 설정
  });
};
