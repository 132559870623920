import {
  Drawer,
  Container,
  Box,
  Typography,
  IconButton,
  FormControl,
  Radio,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  OutlinedInput,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  useGetKeywordByKeywordId,
  useUpdateKeywordState,
} from '../../../service/keyword/keywordHooks';
import { customToast } from '../../../utils/customToast';
import { QUERY_KEY } from '../../../service/queryKey';
import { useQueryClient } from '@tanstack/react-query';
import { GroupType } from '../../../service/group/type';
import { KeywordDetailInfo } from '../../../service/keyword/type';
import BidTimeDetailSetting from './BidTimeDetailSetting';

const LAVEL_WIDTH = '100px';

interface KeywordSettingProps {
  groupInfo: GroupType;
  open: boolean;
  onClose: () => void;
  keywordId: string;
}

const KeywordSetting: React.FC<KeywordSettingProps> = ({
  open,
  onClose,
  keywordId,
  groupInfo,
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateKeywordState();
  const [tempKeywordInfo, setTempKeywordInfo] = useState<KeywordDetailInfo>({
    keywordId: '',
    keywordName: '',
    targetRank: 0,
    currentPrice: 0,
    maxBidPrice: 0,
    addSubtractPrice: 0,
    currentRank: 0,
    maxexposure: 0,
    isCheck: 'N',
    scheduleYN: 'N',
    schedule: null,
  });

  const { data: keywordInfo, refetch } = useGetKeywordByKeywordId(keywordId);


  useEffect(() => {
    if (keywordInfo) {
      setTempKeywordInfo(keywordInfo);
    }
  }, [keywordInfo?.keywordId]);

  //키워드 상태 변경
  const handleSubmit = async () => {
    //키워드 상태 변경하기
    //로직 : rows를 먼저 변경하고 변경이 적용됐다면 bidData를 불러온다. 그렇지 않다면 bidData저장되어있는걸 가져온다.
    //로직 => mutate로 값변경 => queryClient.invalidate key => bidDataMap 변경 => rows 변경
    try {
      await mutateAsync({
        keywordId: tempKeywordInfo.keywordId,
        target_ranking: tempKeywordInfo.targetRank,
        maximum_bid_price: tempKeywordInfo.maxBidPrice,
        adjustment_price: tempKeywordInfo.addSubtractPrice,
        isBiddingActive: tempKeywordInfo.isCheck,
        current_bid_price: tempKeywordInfo.currentPrice,
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.keywords],
      });
    } catch {
      customToast(
        'error',
        '키워드 정보 변경이 실패하였습니다. 잠시후 다시 시도해주세요',
      );
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;

    //boolean or string일경우
    if (name === 'isCheck') {
      setTempKeywordInfo((pre) => {
        return {
          ...pre,
          [name]: value === 'true' ? 'Y' : 'N',
        };
      });
    } else {
      // 나머지는 숫자로 변환
      const num = !!value
        ? (parseInt(value.replace(/\D/g, ''), 10) as number)
        : 0;
      setTempKeywordInfo((pre) => {
        return {
          ...pre,
          [name]: num,
        };
      });
    }
  };

  const handleOnClose = () =>{
    handleSubmit()
    onClose()
  }

  return (
    <>
      <Drawer
        open={open}
        anchor={'right'}
        onClose={handleOnClose}
        sx={{ overflowY: 'auto' }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '600px',
            p: 4,
          }}
        >
          {/* 닫기버튼  */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography variant="subtitle2" color="primary">
              {`${groupInfo.campaignName} > ${groupInfo.groupName} > ${tempKeywordInfo.keywordName}`}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h5" sx={{ p: 3, fontWeight: 'bold' }}>
            {tempKeywordInfo && tempKeywordInfo.keywordName}
          </Typography>

          {/* form  */}
          <Stack sx={{ p: 3, width: '100%' }} gap={1}>
            {/* 상태 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                상태
              </FormLabel>
              <RadioGroup
                sx={{ display: 'flex', flexDirection: 'row' }}
                onChange={handleInputChange}
                name="isCheck"
                value={tempKeywordInfo.isCheck === 'Y' ? true : false}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="입찰 시작"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="입찰 중지"
                />
              </RadioGroup>
            </FormControl>

            {/* 현재 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                현재순위
              </FormLabel>
              <OutlinedInput
                size="small"
                type="text"
                sx={{ width: '300px' }}
                value={tempKeywordInfo.currentRank.toLocaleString()}
                disabled
                name="currentRank"
              ></OutlinedInput>
            </FormControl>

            {/* 목표순위 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                목표순위
              </FormLabel>
              <OutlinedInput
                onChange={handleInputChange}
                size="small"
                type="text"
                sx={{ width: '300px' }}
                name="targetRank"
                value={tempKeywordInfo.targetRank.toLocaleString()}
              ></OutlinedInput>
            </FormControl>
            {/* 현재입찰가 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                현재입찰가
              </FormLabel>
              <OutlinedInput
                onChange={handleInputChange}
                size="small"
                type="text"
                sx={{ width: '300px' }}
                name="currentPrice"
                value={tempKeywordInfo.currentPrice.toLocaleString()}
              ></OutlinedInput>
            </FormControl>
            {/* 최대입찰가 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                최대입찰가
              </FormLabel>
              <OutlinedInput
                onChange={handleInputChange}
                size="small"
                type="text"
                name="maxBidPrice"
                value={tempKeywordInfo.maxBidPrice.toLocaleString()}
                sx={{ width: '300px' }}
              ></OutlinedInput>
            </FormControl>
            {/* 가감액 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                가감액
              </FormLabel>
              <OutlinedInput
                value={tempKeywordInfo.addSubtractPrice.toLocaleString()}
                onChange={handleInputChange}
                size="small"
                type="text"
                sx={{ width: '300px' }}
                name="addSubtractPrice"
              ></OutlinedInput>
            </FormControl>
            <BidTimeDetailSetting
              keywordId={tempKeywordInfo.keywordId}
              schedule={tempKeywordInfo.schedule}
              scheduleYN={tempKeywordInfo.scheduleYN}
            />
          </Stack>
        </Container>
      </Drawer>
    </>
  );
};

export default KeywordSetting;
