import { Box } from '@mui/material';
import { Resizable } from 're-resizable';
import React, { useEffect } from 'react';
import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation } from 'react-router-dom';
interface ResizableSidebarProps {
  children: React.ReactNode;
}

const ResizableSidebar: React.FC<ResizableSidebarProps> = ({ children }) => {
  // 크기 조절 콜백 함수
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const [showHandleWidth, setShowHandleWidth] = useState<boolean>(false); // 손잡이
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.split('/')[2]) {
      // 그룹을 아직 선택하지 않았다면 메뉴를 꺼내야함
      setShowMenu(true);
    }
  });

  const handleChangeShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <Box
      onMouseEnter={() => {
        setShowHandleWidth(true);
      }}
      onMouseLeave={() => {
        setShowHandleWidth(false);
      }}
    >
      {/* {showMenu && ( */}
      <Resizable
        size={{
          width: showMenu ? '' : '0px',
          height: '100%',
        }}
        defaultSize={{
          width: `500px`,
          height: '100%',
        }}
        style={{ backgroundColor: 'white', position: 'relative' }}
        // maxWidth={'60%'}
        minWidth={showMenu ? '350px' : '0px'}
        maxWidth={'600px'}
        enable={{
          //손잡이가 달려있는 방향rhdi
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        // 손잡이
        handleStyles={{
          right: {
            transition: 'all 0.1s ease',
            width: `${showHandleWidth ? 7 : 1}px`,
            height: '100%',
            right: 0,
            background: '#c1c1c1',
          },
        }}
      >
        {/* 여닫는 버튼 */}
        {!!location.pathname.split('/')[2] && (
          <button
            style={{
              position: 'absolute',
              right: '-50px',
              zIndex: '1000',
              top: '50%',
              height: '100px',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(0, 0, 255, 0)',
              border: '0px solid #c1c1c1',
              borderLeft: 'white',
              width: '40px',
              cursor: 'pointer',
            }}
            onClick={handleChangeShowMenu}
          >
            {showMenu ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
          </button>
        )}

        <Box
          sx={{
            overflowY: 'auto',
          }}
        >
          {children}
        </Box>
      </Resizable>
      {/* )} */}
    </Box>
  );
};

export default ResizableSidebar;
