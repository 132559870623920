import {
  Drawer,
  Container,
  Box,
  Typography,
  IconButton,
  FormControl,
  Chip,
  Button,
  Radio,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  OutlinedInput,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from 'react';
import { GroupType } from '../../../service/group/type';
const LAVEL_WIDTH = '100px';

export interface SimpleBidData {
  target_ranking: number; // 목표순위
  current_bid_price: number; //현재입찰금액
  maximum_bid_price: number; // 최대입찰금액
  adjustment_price: number; //가감액
  is_bidding_active: boolean; //입찰중 여부
}

interface KeywordGroupSettingProps {
  groupInfo: GroupType;
  open: boolean;
  onClose: () => void;
  keywordGroupInfo: { nccKeywordId: string; keyword: string }[];
  handleChangeRows: (
    data: SimpleBidData,
    keyword: { nccKeywordId: string; keyword: string }[],
  ) => void;
}

//---------------------------------------------------------------------------------------------------
const KeywordGroupSetting: React.FC<KeywordGroupSettingProps> = ({
  open,
  onClose,
  keywordGroupInfo,
  handleChangeRows,
  groupInfo,
}) => {
  const [keywords, setKeywords] = useState<
    { nccKeywordId: string; keyword: string }[]
  >([]);
  const [tempKeywordGroupInfo, setTempKeywordGroupInfo] =
    useState<SimpleBidData>({
      target_ranking: 0, // 목표순위
      current_bid_price: 0, //현재입찰금액
      maximum_bid_price: 0, // 최대입찰금액
      adjustment_price: 0, //가감액
      is_bidding_active: false, //입찰중 여부
    });

  useEffect(() => {
    setKeywords(keywordGroupInfo);
    setTempKeywordGroupInfo({
      target_ranking: 0, // 목표순위
      current_bid_price: 0, //현재입찰금액
      maximum_bid_price: 0, // 최대입찰금액
      adjustment_price: 0, //가감액
      is_bidding_active: false, //입찰중 여부
    });
  }, [keywordGroupInfo]);

  //하나지우기
  const handleKeywordDelete = (query: string) => {
    const newData = keywords.filter((item) => item.keyword !== query);
    setKeywords(newData);
  };

  //입찰 여부 선택
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData: SimpleBidData = {
      ...tempKeywordGroupInfo,
      is_bidding_active:
        (event.target as HTMLInputElement).value === '입찰시작' ? true : false,
    };
    setTempKeywordGroupInfo(newData);
  };

  //목표순위 업데이트
  const handleUpdateTargetRanking = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e.target as HTMLInputElement).value.replace(/\D/g, '');
    const num = !!value ? parseInt(value, 10) : 0;
    setTempKeywordGroupInfo((pre) => {
      return { ...pre, target_ranking: num };
    });
  };

  //현재 입찰가
  const handleUpdateCurrentBidPrice = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e.target as HTMLInputElement).value.replace(/\D/g, '');
    const num = !!value ? parseInt(value, 10) : 0;
    setTempKeywordGroupInfo((pre) => {
      return { ...pre, current_bid_price: num };
    });
  };

  //최대 입찰가
  const handleUpdateMaximumBidPrice = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e.target as HTMLInputElement).value.replace(/\D/g, '');
    const num = !!value ? parseInt(value, 10) : 0;
    setTempKeywordGroupInfo((pre) => {
      return { ...pre, maximum_bid_price: num };
    });
  };

  //가감액
  const handleUpdateAdjustmentPrice = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e.target as HTMLInputElement).value.replace(/\D/g, '');
    const num = !!value ? parseInt(value, 10) : 0;
    setTempKeywordGroupInfo((pre) => {
      return { ...pre, adjustment_price: num };
    });
  };

  const handleSubmit = () => {
    handleChangeRows(tempKeywordGroupInfo, keywordGroupInfo);
    // customToast('info', `업데이트 되었습니다.`);
    onClose();
    // 창 닫기 전에 row는 업데이트 해야함
  };

  return (
    <>
      <Drawer
        open={open}
        anchor={'right'}
        onClose={onClose}
        sx={{ overflowY: 'auto' }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '600px',
            p: 4,
          }}
        >
          {/* 닫기버튼  */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography variant="subtitle2" color="primary">
              {`${groupInfo.campaignName} > ${groupInfo.groupName}`}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* form  */}
          <Stack sx={{ p: 3, width: '100%' }} gap={1}>
            {/* 상태 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                상태
              </FormLabel>
              <RadioGroup
                sx={{ display: 'flex', flexDirection: 'row' }}
                onChange={handleChange}
                value={
                  tempKeywordGroupInfo.is_bidding_active
                    ? '입찰시작'
                    : '입찰중지'
                }
              >
                <FormControlLabel
                  value={'입찰시작'}
                  control={<Radio />}
                  label="입찰 시작"
                />
                <FormControlLabel
                  value={'입찰중지'}
                  control={<Radio />}
                  label="입찰 중지"
                />
              </RadioGroup>
            </FormControl>

            {/* 목표순위 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                목표순위
              </FormLabel>
              <OutlinedInput
                onChange={handleUpdateTargetRanking}
                size="small"
                type="text"
                sx={{ width: '300px' }}
                value={tempKeywordGroupInfo.target_ranking.toLocaleString()}
              ></OutlinedInput>
            </FormControl>
            {/* 현재입찰가 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                현재입찰가
              </FormLabel>
              <OutlinedInput
                onChange={handleUpdateCurrentBidPrice}
                size="small"
                type="text"
                sx={{ width: '300px' }}
                value={tempKeywordGroupInfo.current_bid_price.toLocaleString()}
              ></OutlinedInput>
            </FormControl>
            {/* 최대입찰가 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                최대입찰가
              </FormLabel>
              <OutlinedInput
                onChange={handleUpdateMaximumBidPrice}
                size="small"
                type="text"
                value={tempKeywordGroupInfo.maximum_bid_price.toLocaleString()}
                sx={{ width: '300px' }}
              ></OutlinedInput>
            </FormControl>
            {/* 가감액 */}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={{ textAlign: 'right', width: LAVEL_WIDTH, mr: 2 }}>
                가감액
              </FormLabel>
              <OutlinedInput
                value={tempKeywordGroupInfo.adjustment_price.toLocaleString()}
                onChange={handleUpdateAdjustmentPrice}
                size="small"
                type="text"
                sx={{ width: '300px' }}
              ></OutlinedInput>
            </FormControl>
            <Box sx={{ width: '100%', textAlign: 'right', mt: 5 }}>
              <Button
                variant="contained"
                sx={{ width: '150px' }}
                onClick={handleSubmit}
              >
                등록
              </Button>
            </Box>
            <Stack sx={{ mt: 4 }} direction="row" gap={1} flexWrap={'wrap'}>
              {keywords.map((item, i) => (
                <Chip
                  label={item.keyword}
                  key={i}
                  variant="outlined"
                  onDelete={() => {
                    handleKeywordDelete(item.keyword);
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Container>
      </Drawer>
    </>
  );
};

export default KeywordGroupSetting;
