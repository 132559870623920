import {
  TableRow,
  TableCell,
  Checkbox,
  TextField,
  Button,
  IconButton,
  Typography,
  Chip,
  Tooltip,
} from '@mui/material';
import theme from '../../../config/theme/MuiTheme';
import StopIcon from '@mui/icons-material/Stop';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PauseIcon from '@mui/icons-material/Pause';
import RemoveIcon from '@mui/icons-material/Remove';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { bidData } from './BidTable';
import { useEffect, useState } from 'react';

interface EnhancedTableRowProps {
  index: number;
  isBiddingActive: boolean;
  isItemSelected: boolean;
  row: bidData;
  labelId: string;
  handleClick: (
    event: React.MouseEvent<unknown>,
    nccKeywordId: string,
    keyword: string,
  ) => void;
  handleKeywordInfo: (nccKeywordId: string) => void;
  handleKeywordDetail: () => void;
  showColumn: boolean[];
  handleUpdateBidInfo: (nccKeywordId: string) => void;
  handleUpdateTargetRanking: (
    nccKeywordId: string,
    targetRanking: number,
  ) => void;
  handleUpdateCurrentBidPrice: (
    nccKeywordId: string,
    currentBidPrice: number,
  ) => void;
  handleUpdateMaximumBidPrice: (
    nccKeywordId: string,
    maximumBidPrice: number,
  ) => void;
  handleUpdateAdjustmentPrice: (
    nccKeywordId: string,
    adjustmentPrice: number,
  ) => void;
  handleChangeKeywordBiddingActive: (
    nccKeywordId: string,
    isBiddingActive: boolean,
  ) => void;
  handleKeywordSetting: () => void;
}

const EnhancedTableRow: React.FC<EnhancedTableRowProps> = ({
  // index,
  isBiddingActive,
  isItemSelected,
  row,
  handleChangeKeywordBiddingActive,
  handleClick,
  handleKeywordDetail,
  handleKeywordInfo,
  handleKeywordSetting,
  handleUpdateAdjustmentPrice,
  handleUpdateBidInfo,
  handleUpdateCurrentBidPrice,
  handleUpdateMaximumBidPrice,
  handleUpdateTargetRanking,
  labelId,
  showColumn,
}) => {
  // console.table({ title: row.keyword, isItemSelected: isItemSelected });
  const [previouseBidPrice, setPreviouseBidPrice] = useState<number>(
    row.current_bid_price,
  );
  const [bgColor, setBgColor] = useState<string>('white');

  useEffect(() => {
    //입찰중이고, 키워드가 활성화 되어있고, 노출순위가 0
    if (previouseBidPrice !== row.current_bid_price) {
      if (previouseBidPrice < row.current_bid_price) {
        setBgColor('rgba(255, 0, 0, 0.1)');
      }
      if (previouseBidPrice > row.current_bid_price) {
        setBgColor('rgba(0, 0, 255, 0.1)');
      }
    } else {
      setBgColor('white');
    }

    setPreviouseBidPrice(row.current_bid_price);

    setTimeout(() => {
      setBgColor('white');
    }, 100 * 6);
  }, [row]);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.keyword}
        selected={isItemSelected}
        sx={{
          //홀, 짝 행 색 변경
          // ...(index % 2
          //   ? { backgroundColor: theme.palette.background.paper }
          //   : { backgroundColor: '#f1f1f1' }),

          ...(row.is_bidding_active ? { color: '' } : { color: '#f1f1f1' }),
        }}
      >
        {/* 체크박스 */}
        {true && (
          <TableCell padding="checkbox" sx={{ ...tableCellStyle }}>
            <Checkbox
              onClick={(event) =>
                handleClick(event, row.nccKeywordId, row.keyword)
              }
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
        )}
        {/* 키워드 */}
        <TableCell
          component="td"
          id={labelId}
          scope="row"
          padding="none"
          sx={{
            ...tableCellStyle,
            color: theme.palette.primary.main,
            ...biddingActiveStyle(row),
            pl: 1,
            minWidth: '150px',
            cursor: 'pointer',
            textDecoration: '',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
              // color: theme.palette.secondary.main,
              // fontSize: '1.2rem',
            },
          }}
          onClick={() => {
            //키워드 셋팅
            handleKeywordInfo(row.nccKeywordId);
            //값 열기
            handleKeywordDetail();
          }}
        >
          {row.keyword}
        </TableCell>
        {/* 현재순위 */}
        {showColumn[1] && (
          <TableCell
            align="center"
            sx={{
              minWidth: '100px',
              ...tableCellStyle,
              ...biddingActiveStyle(row),
            }}
            padding="none"
          >
            {!!row.current_ranking ? (
              row.current_ranking.toLocaleString()
            ) : (
              <RemoveIcon fontSize="small" sx={{ width: '12px', mr: '5px' }} />
            )}
          </TableCell>
        )}
        {/* 최대노출 */}
        {showColumn[2] && (
          <TableCell
            align="center"
            sx={{
              minWidth: '80px',
              ...tableCellStyle,
              ...biddingActiveStyle(row),
            }}
            padding="none"
          >
            {!!row.max_exposure ? (
              row.max_exposure
            ) : (
              <RemoveIcon fontSize="small" sx={{ width: '12px', mr: '5px' }} />
            )}
          </TableCell>
        )}
        {/* 목표순위 */}
        {showColumn[3] && (
          <TableCell align="center" sx={tableCellStyle}>
            <TextField
              size="small"
              onBlur={() => {
                handleUpdateBidInfo(row.nccKeywordId);
              }}
              onChange={(e) => {
                const value = e.currentTarget.value.replace(/\D/g, ''); // 숫자만 입력되게 하기
                handleUpdateTargetRanking(
                  row.nccKeywordId,
                  !!value ? parseInt(value, 10) : 0, //다 지워지면 0임
                );
              }}
              sx={{
                ...textFieldStyle,
                width: '90px',
                border: '0px solid grey',
              }}
              value={row.target_ranking.toLocaleString()} //점찍기
            />
          </TableCell>
        )}
        {/* 현재입찰금액 */}
        {showColumn[4] && (
          <TableCell align="center" sx={tableCellStyle}>
            <TextField
              size="small"
              onBlur={() => {
                handleUpdateBidInfo(row.nccKeywordId);
              }}
              onChange={(e) => {
                const value = e.currentTarget.value.replace(/\D/g, ''); // 숫자만 입력되게 하기
                handleUpdateCurrentBidPrice(
                  row.nccKeywordId,
                  !!value ? parseInt(value, 10) : 0, //다 지워지면 0임
                );
              }}
              sx={{
                ...textFieldStyle,
                backgroundColor: bgColor,
                width: '110px',
                ' .MuiOutlinedInput-root': {
                  // 색상
                  color:
                    isBiddingActive &&
                    row.is_bidding_active &&
                    !!row.current_ranking
                      ? row.current_bid_price === row.maximum_bid_price ||
                        row.current_bid_price <= 70 ||
                        row.target_ranking === row.current_ranking
                        ? ''
                        : row.target_ranking < row.current_ranking
                        ? 'error.main'
                        : 'primary.main'
                      : null,
                },
              }}
              value={Math.abs(row.current_bid_price).toLocaleString()} //점찍기
              InputProps={{
                // 위아래 화살표
                endAdornment:
                  isBiddingActive &&
                  row.is_bidding_active &&
                  !!row.current_ranking ? (
                    row.current_bid_price === row.maximum_bid_price ||
                    row.current_bid_price <= 70 ||
                    row.target_ranking === row.current_ranking ? (
                      <RemoveIcon
                        fontSize="small"
                        sx={{ width: '12px', mr: '5px' }}
                      />
                    ) : row.target_ranking < row.current_ranking ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )
                  ) : null,
              }}
            />
          </TableCell>
        )}
        {/* 최대입찰금액 */}
        {showColumn[5] && (
          <TableCell align="center" sx={tableCellStyle}>
            <TextField
              size="small"
              onBlur={() => {
                handleUpdateBidInfo(row.nccKeywordId);
              }}
              onChange={(e) => {
                const value = e.currentTarget.value.replace(/\D/g, ''); // 숫자만 입력되게 하기
                handleUpdateMaximumBidPrice(
                  row.nccKeywordId,
                  !!value ? parseInt(value, 10) : 0, //다 지워지면 0임
                );
              }}
              sx={{ ...textFieldStyle, width: '110px' }}
              value={row.maximum_bid_price.toLocaleString()} //점찍기
            />
          </TableCell>
        )}
        {/* 가감액 */}
        {showColumn[6] && (
          <TableCell align="center" sx={tableCellStyle}>
            <TextField
              size="small"
              onBlur={() => {
                handleUpdateBidInfo(row.nccKeywordId);
              }}
              onChange={(e) => {
                const value = e.currentTarget.value.replace(/\D/g, ''); // 숫자만 입력되게 하기
                handleUpdateAdjustmentPrice(
                  row.nccKeywordId,
                  !!value ? parseInt(value, 10) : 0, //다 지워지면 0임
                );
              }}
              sx={{ ...textFieldStyle, width: '80px' }}
              value={row.adjustment_price.toLocaleString()} //점찍기
            />
          </TableCell>
        )}
        {/* 상태 버튼 */}
        <TableCell align="center" sx={tableCellStyle}>
          {row.is_bidding_active ? (
            <Button
              sx={{
                width: '100px',
                height: '40px',
              }}
              startIcon={isBiddingActive ? <PlayArrowIcon /> : <PauseIcon />}
              variant="contained"
              onClick={async () => {
                handleChangeKeywordBiddingActive(
                  row.nccKeywordId,
                  row.is_bidding_active,
                );
              }}
            >
              입찰
            </Button>
          ) : (
            <Button
              sx={{
                width: '100px',
                height: '40px',
                color: 'grey',
                border: 'none',
                backgroundColor: '#e2e2e2',
              }}
              startIcon={<StopIcon />}
              variant="outlined"
              onClick={async () => {
                handleChangeKeywordBiddingActive(
                  row.nccKeywordId,
                  row.is_bidding_active,
                );
              }}
            >
              정지
            </Button>
          )}
        </TableCell>
        {/* 결과 */}

        <TableCell
          sx={{
            cursor: 'pointer',
            textAlign: 'center',
            minWidth: '110px',
            ...tableCellStyle,
          }}
        >
          {/* 성공적으로 입찰했을 경우 */}
          {row.keyword_active.split('*')[0] === 'success' && (
            <Typography sx={{ textAlign: 'center' }} variant="body2">
              {row.keyword_active.split('*')[1].split(' ')[0]} <br />
              {row.keyword_active.split('*')[1].split(' ')[1]}
            </Typography>
          )}

          {/* 입찰이 실패해 ON 일 경우 */}
          {row.keyword_active.split('*')[0] === 'ON' && (
            <Tooltip
              title={row.keyword_active.split('*')[2]}
              placement="top"
              arrow
            >
              <Chip
                label={row.keyword_active.split('*')[1]}
                variant="outlined"
                color="success"
              />
            </Tooltip>
          )}
          {/* 입찰이 실패해 OFF 일 경우 */}
          {row.keyword_active.split('*')[0] === 'OFF' && (
            <Tooltip
              title={row.keyword_active.split('*')[2]}
              placement="top"
              arrow
            >
              <Chip
                label={row.keyword_active.split('*')[1]}
                variant="outlined"
                color="error"
              />
            </Tooltip>
          )}
        </TableCell>

        {/* 설정 */}
        {/* 이 설정이 테이블 한줄의 padding을 전부 담당하고 있음 */}
        <TableCell
          sx={{
            textAlign: 'center',
            ...tableCellStyle,
          }}
        >
          <Tooltip
            title={'키워드 값 변경 및 입찰 세부시간이 가능합니다.'}
            arrow
            placement="top"
          >
            <IconButton
              onClick={() => {
                handleKeywordInfo(row.nccKeywordId);
                handleKeywordSetting();
              }}
            >
              <SettingsOutlinedIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EnhancedTableRow;

const tableCellStyle = {
  px: '2px',
  // borderBottom: '0px solid grey',
};

const biddingActiveStyle = (row: bidData) => ({
  ...(row.is_bidding_active
    ? {}
    : {
        color: 'grey',
        // textDecoration: 'line-through'
      }),
});

const textFieldStyle = {
  backgroundColor: 'white',
};
