import { customAxios, URL } from '../../config/axios/axios-config';
import axios from 'axios';
import { signUpDateType, UpdateUserInfoType, NaverApiType, BookmarkType } from './type';

export const userAPI = {
  // 통신되는지 확인하는 api
  async getData() {
    return await customAxios.get('/').then((res) => {
      // console.log('통신잘됨');
      return res.data;
    }); // 무조건 뽑아야 한다.
  },

  //회원가입
  async signUp(data: signUpDateType) {
    return await axios.post(`${URL}/us/signup`, {
      id: data.id,
      customer_name: data.name,
      phone_number: data.phoneNumber,
      email: data.email,
      business_number: data.businessNumber,
      pw: data.password,
    });
  },



  //로그인
  async login(id: string, pw: string) {
    return await axios.post(`${URL}/us/login`, { id, pw });
  },

  //accessToken기반으로 사용자 정보 받아오기
  async getInfo() {
    return await customAxios.get(`/us/info`).then((res) => res.data[0]);
  },

  //로그아웃
  async logout() {
    return await axios.post(`${URL}/logout`);
  },

  //사용자 정보 수정
  async updateInfo(data: UpdateUserInfoType) {
    return await customAxios.put('/us', {
      id: data.id,
      new_pw: data.newPassword,
      phone_number: data.phoneNumber,
      business_number: data.businessNumber,
      email: data.email,
      access_key: data.accessKey,
      secret_key: data.secretKey,
      customer_id: data.customerId,
    });
  },
  //회원 api 정보 수정
  async updateNaverAPI(data: NaverApiType) {
    const API = '/us/api';
    const body = {
      access_key: data.accessKey,
      secret_key: data.secretKey,
      customer_id: data.customerId,
    };
    return await customAxios.put(API, body);
  },
  //키워드 사용량 조회
  async getKeywordUsage() {
    const API = '/dashboard/keyword';
    return await customAxios.get(API).then((data) => data.data);
  },
  //일일입찰수 사용량 조회
  async getDailyBidCount() {
    const API = '/dashboard/bids_per_day';
    return await customAxios.get(API).then((data) => data.data);
  },
  //서브유저 리스트 조회
  async getSubUserList(){
    const API = '/us/sub/list';
    return await customAxios.get(API).then((data) => data.data);
  },
  //사용자 쿠기 변경 (새로운 customer_id로 변경)
  async getChangeUser(customerId: string ) {
    const API=`us/sub/change/${customerId}`
    return await customAxios.get(API)
  },
  //서브유저 북마크 변경
  async updateBookmark(data:BookmarkType) {
    const body={
      customer_id:data.customerId,
      bookmark:data.bookmark,
    }
    const API = '/dashboard/client/bookmark';
    return await customAxios.put(API, body);  
  },
  //자식목록 일일 입찰 수 조회
  async getChildrenBidCountList() {
    const API = '/dashboard/bids_per_day/children';
    return await customAxios.get(API).then((data) => data.data.data);
  }
};
