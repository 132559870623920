import {
  Box,
  Stack,
  Button,
  Typography,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormLabel,
  Card,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { customToast } from '../utils/customToast';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from '../store';
import { useUserInfoUpdateMutation } from '../service/user/userHooks';
import { userAPI } from '../service/user/userAxios';
import { UpdateUserInfoType } from '../service/user/type';

const labelWidth = {
  width: '180px',
};

const UserInfoEdit: React.FC = () => {
  const userInfo = useRecoilValue(UserInfoState);

  const navigate = useNavigate();
  // const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [newUserInfo, setNewUserInfo] = useState<UpdateUserInfoType>({
    id: userInfo.ID,
    newPassword: '',
    phoneNumber: userInfo.PHONE_NUMBER,
    businessNumber: userInfo.BUSINESS_NUMBER,
    email: userInfo.EMAIL,
  });
  const { mutate: userInfoUpdate } = useUserInfoUpdateMutation(newUserInfo);
  const { newPassword, phoneNumber, businessNumber, email } = useMemo(() => {
    return newUserInfo;
  }, [newUserInfo]);
  const handleUserInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setNewUserInfo((prev) => {
      return { ...prev, [id]: value };
    });
  };

  //input값 올바른지 확인하는 정규식 모음
  const handleCheckValidation = {
    newPasswordIncludeAlpha() {
      return /[a-zA-Z]/.test(newPassword!);
    },
    newPasswordIncludeNumber() {
      return /\d/.test(newPassword!);
    },
    newPasswordIncludeSpecialCharacter() {
      return /[!@#$%^&*(),.?":{}|<>]/.test(newPassword!);
    },
    newPasswordSatisfiedLength() {
      return newPassword!.length >= 8 && newPassword!.length <= 20;
    },
    newPasswordEqualConfirm() {
      return newPassword === confirmNewPassword;
    },
    emailFormat() {
      return /\S+@\S+\.\S+/.test(email!);
    },
    businessNumberFormat() {
      return /^\d{3}-\d{2}-\d{5}$/.test(businessNumber!);
    },
    newPassowordFormat() {
      return /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
        newPassword!,
      );
    },
    phoneNumberFormat() {
      return /^01[0-9][0-9]{3,4}[0-9]{4}$/.test(phoneNumber!);
    },
  };

  //비밀번호 보기
  const handleShowPassword = () => {
    setShowPassword((pre) => !pre);
  };

  // //비밀번호 입력
  // const handlePasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
  //   setPassword(event.target.value);
  // };

  //새 비밀번호 확인 입력
  const handleConfirmPasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(event.target.value);
  };

  //비밀번호 확인 함수
  // const handleCheckPassword = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   if (password === '') {
  //     // customToast('info', '비밀번호를 입력해주세요');
  //   } else {
  //     userAPI
  //       .login(userInfo.ID, password)
  //       .then(() => {
  //         setCheckPassword(true);
  //       })
  //       .catch(() => {
  //         customToast('info', '잘못된 비밀번호 입니다. 다시 입력해주세요');
  //         setPassword('');
  //       });
  //   }
  // };

  //뒤로가기
  const handleBack = () => {
    navigate('/mypage');
  };

  //회원가입 등록 함수
  const handleFormSubmit = () => {
    if (!businessNumber || !handleCheckValidation.businessNumberFormat()) {
      customToast('error', '사업자등록번호를 확인해주세요.');
    } else if (!email || !handleCheckValidation.emailFormat()) {
      customToast('error', "이메일을 확인해주세요'");
    } else if (
      !newPassword ? false : !handleCheckValidation.newPassowordFormat()
    ) {
      customToast('error', '비밀번호를 확인해주세요');
    } else if (!handleCheckValidation.newPasswordEqualConfirm()) {
      customToast('error', '비밀번호확인을 확인해주세요');
    } else if (!phoneNumber || !handleCheckValidation.phoneNumberFormat()) {
      customToast('error', '휴대폰 번호를 확인해주세요');
    } else {
      userInfoUpdate();
    }
  };

  // // 비밀번호 확인 컴포넌트
  // if (checkPassword === false) {
  //   return (
  //     <Box
  //       sx={{
  //         backgroundColor: 'background.default',
  //         height: '120vh',
  //         pt: '10%',
  //       }}
  //     >
  //       <Stack
  //         direction="column"
  //         gap={2}
  //         alignItems={'center'}
  //         sx={{ maxWidth: '800px', margin: '0 auto' }}
  //       >
  //         <form onSubmit={handleCheckPassword}>
  //           <FormControl
  //             sx={{
  //               backgroundColor: 'background.default',
  //               p: 3,
  //               borderRadius: '8px',
  //               boxShadow: 4,
  //             }}
  //           >
  //             <Typography variant="h5" sx={{ mb: 2 }}>
  //               회원정보 확인
  //             </Typography>
  //             <Box
  //               sx={{
  //                 display: 'flex',
  //                 justifyContent: 'flex-start',
  //                 alignItems: 'center',
  //               }}
  //             >
  //               <Typography
  //                 id={'password'}
  //                 variant="subtitle1"
  //                 color="grey"
  //                 sx={{ width: '80px' }}
  //               >
  //                 {'비밀번호'}
  //               </Typography>

  //               <OutlinedInput
  //                 sx={{ width: '400px' }}
  //                 value={password}
  //                 onChange={handlePasswordInput}
  //                 type={showPassword ? 'text' : 'password'}
  //                 endAdornment={
  //                   <InputAdornment position="end">
  //                     <IconButton
  //                       aria-label="toggle password visibility"
  //                       onClick={handleShowPassword}
  //                       edge="end"
  //                     >
  //                       {showPassword ? <VisibilityOff /> : <Visibility />}
  //                     </IconButton>
  //                   </InputAdornment>
  //                 }
  //               />
  //             </Box>
  //             <Box sx={{ textAlign: 'right' }}>
  //               <Button
  //                 variant="contained"
  //                 sx={{
  //                   width: '150px',
  //                   height: '50px',
  //                   mt: 2,
  //                 }}
  //                 type="submit"
  //               >
  //                 {' '}
  //                 제출{' '}
  //               </Button>
  //             </Box>
  //           </FormControl>
  //         </form>
  //       </Stack>
  //     </Box>
  //   );
  // }

  //사용자 정보 변경 컴포넌트
  return (
    <Box sx={{ backgroundColor: 'background.default', height: '120vh', pt: 4 }}>
      <Stack
        direction="column"
        gap={2}
        alignItems={'center'}
        sx={{ width: '700px', margin: '0 auto' }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="body2" color="primary">
            이름과 아이디를 변경할 경우 관리자에게 문의해 주세요.
          </Typography>
        </Box>
        {/* 기본정보 */}
        <Card
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="h5">기본정보</Typography>
          <Stack sx={{ p: 4 }} gap={2}>
            {/* 이름 */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={labelWidth} htmlFor={'name'}>
                이름
              </FormLabel>
              <TextField
                id="name"
                disabled
                type="text"
                value={userInfo.CUSTOMER_NAME}
                sx={{ width: '400px' }}
              />
            </Box>

            {/* 전화번호 */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={labelWidth} htmlFor={'phoneNumber'}>
                전화번호
              </FormLabel>

              <TextField
                id="phoneNumber"
                error={
                  phoneNumber
                    ? !handleCheckValidation.phoneNumberFormat()
                    : true
                }
                onChange={handleUserInfoChange}
                type="text"
                sx={{ width: '400px' }}
                value={phoneNumber}
              />
            </Box>

            {/* 아이디 */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={labelWidth} htmlFor={'id'}>
                아이디
              </FormLabel>

              <TextField
                id="id"
                disabled
                type="text"
                sx={{ width: '400px' }}
                value={userInfo.ID}
              />
            </Box>

            {/* 새 비밀번호 */}
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormLabel sx={labelWidth} htmlFor={'newPassword'}>
                  새 비밀번호
                </FormLabel>

                <OutlinedInput
                  id="newPassword"
                  sx={{ width: '400px' }}
                  value={newPassword}
                  onChange={handleUserInfoChange}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              {newPassword && (
                <Stack direction={'row'} gap={2} sx={{ ml: '110px' }}>
                  <Typography
                    variant="subtitle2"
                    color={
                      newPassword
                        ? handleCheckValidation.newPasswordIncludeAlpha()
                          ? 'primary'
                          : 'error'
                        : 'grey'
                    }
                  >
                    영문 ✔
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={
                      newPassword
                        ? handleCheckValidation.newPasswordIncludeNumber()
                          ? 'primary'
                          : 'error'
                        : 'grey'
                    }
                  >
                    숫자 ✔
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={
                      newPassword
                        ? handleCheckValidation.newPasswordIncludeSpecialCharacter()
                          ? 'primary'
                          : 'error'
                        : 'grey'
                    }
                  >
                    특수문자 ✔
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={
                      newPassword
                        ? handleCheckValidation.newPasswordSatisfiedLength()
                          ? 'primary'
                          : 'error'
                        : 'grey'
                    }
                  >
                    비밀번호 길이 8이상 20이하 ✔
                  </Typography>
                </Stack>
              )}
            </Box>
            {/*새 비밀번호 확인 */}
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormLabel sx={labelWidth} htmlFor={'confirmNewPassword'}>
                  새 비밀번호 확인
                </FormLabel>

                <OutlinedInput
                  id="confirmNewPassword"
                  error={
                    confirmNewPassword
                      ? !handleCheckValidation.newPasswordEqualConfirm()
                      : false
                  }
                  sx={{ width: '400px' }}
                  value={confirmNewPassword}
                  onChange={handleConfirmPasswordInput}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <Box sx={{ ml: '110px' }}>
                {confirmNewPassword &&
                  !handleCheckValidation.newPasswordEqualConfirm() && (
                    <Typography variant="subtitle2" color="error">
                      비밀번호가 일치하지 않습니다.{' '}
                    </Typography>
                  )}
              </Box>
            </Box>
            {/* 유저정보가 있을 때는 보여지면 안됨  */}
          </Stack>
        </Card>
        {/* 기업정보 */}
        <Card
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="h5">기업정보</Typography>
          <Stack sx={{ p: 4 }} gap={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={labelWidth} htmlFor={'businessNumber'}>
                사업자 등록번호
              </FormLabel>
              <TextField
                id="businessNumber"
                error={
                  businessNumber
                    ? !handleCheckValidation.businessNumberFormat()
                    : false
                }
                placeholder="000-00-00000"
                type="text"
                sx={{ width: '400px' }}
                value={businessNumber}
                onChange={handleUserInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormLabel sx={labelWidth} htmlFor={'email'}>
                이메일
              </FormLabel>
              <TextField
                id="email"
                error={email ? !handleCheckValidation.emailFormat() : false}
                onChange={handleUserInfoChange}
                value={email}
                type="text"
                sx={{ width: '400px' }}
              />
            </Box>
          </Stack>
        </Card>

        {/* 등록 취소 버튼 */}
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          sx={{
            width: '100%',
            mb: '200px',
          }}
          gap={1}
        >
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleBack}
            sx={{ width: '110px', backgroundColor: 'background.paper' }}
          >
            뒤로가기
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ width: '110px' }}
            onClick={handleFormSubmit}
          >
            수정
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default UserInfoEdit;
